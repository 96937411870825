import React from 'react';
import { Box, Grid } from '@mui/material';

export type BaseIndicatorProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;

  // props for styles
  containerStyle?: any;
};

export const BaseIndicator = ({
  icon,
  title,
  subtitle,
  description,
  actions,
  containerStyle = {},
}: BaseIndicatorProps) => {
  if (!icon && !title && !subtitle && !description && !actions) {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        p: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        m: 'auto',
        pb: 6,
        ...containerStyle,
      }}
    >
      <Grid container spacing={1} sx={{ textAlign: 'center', flexDirection: 'column' }}>
        {icon && (
          <Grid item xs={12}>
            {icon}
          </Grid>
        )}

        {title && (
          <Grid item xs={12}>
            {title}
          </Grid>
        )}

        {subtitle && (
          <Grid item xs={12}>
            {subtitle}
          </Grid>
        )}

        {description && (
          <Grid item xs={12}>
            {description}
          </Grid>
        )}

        {actions && (
          <Grid item xs={12}>
            {actions}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
