import { useFrame } from '@react-three/fiber';

export const Turn = ({ clipless, deliveryTarget }: any) => {
  // clipless.rotation < -6.2831853
  // clipless.rotation = -0.002 * clipless.speed
  // clipless.rotation -= 0.002 * clipless.speed

  useFrame(() => {
    clipless.rotation < -6.2831853
      ? (clipless.rotation = -0.002 * clipless.speed)
      : (clipless.rotation -= 0.002 * clipless.speed);
    deliveryTarget.rotation.y = clipless.rotation;
  });
  return null;
};

export const TurnCounter = ({ clipless, deliveryTarget }: any) => {
  // clipless.rotation > 6.2831853
  // clipless.rotation = 0.002 * clipless.speed
  // clipless.rotation += 0.002 * clipless.speed

  useFrame(() => {
    clipless.rotation > 6.2831853
      ? (clipless.rotation = 0.002 * clipless.speed)
      : (clipless.rotation += 0.002 * clipless.speed);
    deliveryTarget.rotation.y = clipless.rotation;
  });
  return null;
};

export const TurnCounterHold = ({ clipless, deliveryTarget }: any) => {
  const Reset = () => {
    clipless.rotation = 0.002 * clipless.speed;
    clipless.iterator = 60 * clipless.hold;
  };

  useFrame(() => {
    clipless.rotation > 6.2831853
      ? clipless.iterator
        ? clipless.iterator--
        : Reset()
      : (clipless.rotation += 0.002 * clipless.speed);
    deliveryTarget.rotation.y = clipless.rotation;
  });

  return null;
};

export const TurnHold = ({ clipless, deliveryTarget }: any) => {
  const Reset = () => {
    clipless.rotation = -0.002 * clipless.speed;
    clipless.iterator = 60 * clipless.hold;
  };

  useFrame(() => {
    clipless.rotation < -6.2831853
      ? clipless.iterator
        ? clipless.iterator--
        : Reset()
      : (clipless.rotation -= 0.002 * clipless.speed);
    deliveryTarget.rotation.y = clipless.rotation;
  });

  return null;
};
