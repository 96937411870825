import { VItem } from './states/variants';
import { useStore } from './useStore';

type VItemDetails = VItem & {
  priceStr: any;
};

const getVItemDetails = (vItem: VItem): VItemDetails => {
  const {
    price,
    variant_title
  } = vItem;

  const priceStr = price ? `$${price}` : undefined;

  return {
    ...vItem,
    priceStr,
    subTitle: variant_title ? variant_title : undefined
  };
};

export const useVItem = () => {
  const vItem = useStore((state) => state.vItem);

  if (!vItem) {
    return undefined;
  }

  return getVItemDetails(vItem);
};
