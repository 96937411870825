import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useVItem } from 'store';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { ShoppingCart } from '@mui/icons-material';
import { useClickAddToCart } from 'hooks/useClickAddToCart';

export const ViewerCart = () => {
  const { handleClickAddToCart } = useClickAddToCart();
  const { product_title: title = '', priceStr: price = '', subTitle = '' } = useVItem() || {};
  const { downMd } = useBreakpoints();

  const textWidth = downMd ? 265 : 900

  return (
    <Box component="div" sx={{ p: downMd ? 2 : 4 }}>
      <Grid container alignItems="center" justifyContent="space-between" sx={{ maxWidth: textWidth + 100, margin: '0 auto' }}>
        <Grid item>
          <Typography gutterBottom variant="h5" sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: textWidth, overflow: 'hidden' }}>
            {title} - {price}
          </Typography>
          <Typography gutterBottom noWrap sx={{ textOverflow: 'ellipsis', maxWidth: textWidth, overflow: 'hidden' }}><b>Add To Cart</b> - {subTitle}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleClickAddToCart()} sx={{ p: 1.25, background: '#2196f370', border: 'solid 2px #2196f3' }}>
            <ShoppingCart fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};