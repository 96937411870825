// import React, { useState } from 'react';
// import { ArrowDropDown } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';

// import { DefaultIndicator } from 'components/indicators';
// import { useBreakpoints } from 'hooks/useBreakpoints';

export type Option = {
  id: number;
  label: string;
};

export type OptionSelectorProps = {
  options?: Option[];
  selectedOptionId?: Option['id'];
  onMenuItem?: (id: Option['id']) => void;
  prefix?: string;
};

export const OptionSelector = ({
  options = [],
  selectedOptionId,
  onMenuItem = () => { },
  prefix = '',
}: OptionSelectorProps) => {

  return (
    <Grid container sx={{ display: 'flex', mb: 2 }} spacing={2} justifyContent="center">
      {/* TODO: use options.length > 1 and have button or text */}

      {
        options.map(({ id, label }, index) => {
          // console.log('row', id, label)
          // console.log('selectedOptionId', selectedOptionId)

          // maybe check if color and leave or only do the split to string type size
          let type = label.split(':')[0]
          // console.log('type::', type)
          // console.log('id::', id)
          // console.log('label::', label)

          // let type = label.substring(label.lastIndexOf(":") + 1, label.length)

          // console.log('result', result)
          // let currPrefix = label ? (prefix ? `${prefix}: ` : '') : 'Choose option';
          // {/* <Grid key={index} item alignContent="center" justifyContent="center" sx={{ display: 'flex' }}></Grid> */}

          // EQUALS THE CURRENT SELECTED OPTION
          // id === selectedOptionId

          return (
            <Grid key={id} item>
              <Button
                key={id}
                // variant="contained"
                variant={id === selectedOptionId ? 'outlined' : 'text'}
                disableElevation
                // disableElevation={id !== selectedOptionId}
                onClick={() => onMenuItem(id)}
                sx={{
                  // border: '3px solid',
                  // borderColor: id === selectedOptionId ? '#424242' : ''
                }}
              >
                {type}
              </Button>
            </Grid>
          )
        })
      }
    </Grid>

  );
};


