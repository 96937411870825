import { BottomControls } from 'components/bottom-controls';
import { useStore } from 'store';
import { PanelProps } from './AppLayoutContext';

export const useGenerateBottomControlsPanel = (): {
  visibleBottomControlsPanel?: boolean;
  bottomControlsPanelProps?: PanelProps;
} => {
  const fullscreen = useStore((state) => state.params.fullscreen);

  return {
    visibleBottomControlsPanel: !fullscreen,
    bottomControlsPanelProps: {
      contentComponent: BottomControls,
    },
  };
};
