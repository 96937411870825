import { ContainerProps } from '@mui/material';

import { useStore } from 'store';

export const useGenerateContainerProps = () => {
  const fullscreen = useStore((state) => state.params.fullscreen);

  if (fullscreen) {
    return {
      containerProps: {
        maxWidth: false,
        sx: {
          p: { xs: 0 },
        },
      } as ContainerProps,
    };
  }

  return {
    containerProps: {
      maxWidth: 'xl',
      sx: {
        pt: 3,
        pb: 6,
      },
    } as ContainerProps,
  };
};
