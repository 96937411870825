import React from 'react';
import { Grid, IconButton, IconButtonProps, Typography } from '@mui/material';
import { ArrowBack, ArrowForward, Refresh } from '@mui/icons-material';

import { useBreakpoints } from 'hooks/useBreakpoints';

export type BillboardPaginationProps = {
  onPrev?: IconButtonProps['onClick'];
  onRefresh?: IconButtonProps['onClick'];
  onNext?: IconButtonProps['onClick'];
  current?: number;
  total?: number;
};

export const BillboardPagination = ({
  onPrev = () => { },
  onRefresh = () => { },
  onNext = () => { },
  current = 1,
  total = 0,
}: BillboardPaginationProps) => {
  const { downMd } = useBreakpoints();

  if (total < 1) {
    return null;
  }

  return (
    <Grid container spacing={1} sx={{ alignItems: 'center', justifyContent: downMd ? 'center' : 'flex-start' }}>
      <Grid item>
        <IconButton onClick={onPrev} disabled={current === 1}>
          <ArrowBack />
        </IconButton>
      </Grid>

      <Grid item>
        <IconButton onClick={onRefresh} disabled={current === 1}>
          <Refresh />
        </IconButton>
      </Grid>

      <Grid item>
        <IconButton onClick={onNext} disabled={current >= total}>
          <ArrowForward />
        </IconButton>
      </Grid>

      <Grid item sx={{ ml: 2 }}>
        <Typography>
          {current}&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;{total}
        </Typography>
      </Grid>
    </Grid>
  );
};
