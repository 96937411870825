import { Tab, Tooltip } from '@mui/material';
import { SizedScrollTabs } from 'components/@styled-mui';
import { useStore, useVItem, useMItem } from 'store';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { getDt } from 'utils/three_helpers';
import { useSelectedOptionValues, useSwitchVariantContext } from './SwitchVariantContext';
import { VariantItem } from './VariantItem';
import { VariantItemDropdown } from './VariantItemDropdown';

export const VariantSelector = () => {
  const { currentSubOptions = [], value = -1, subOptionsDropdownStatus } = useSelectedOptionValues();
  const { values, selectedOptionId } = useSwitchVariantContext();
  const store_id = useStore((state) => state.store_id);
  const variant_type = useStore((state) => state.params.variant_type);
  const vList = useStore((state) => state.vList) || {};
  const mList = useStore((state) => state.mList) || {}; // Model List
  const {
    model_type = '',
  } = useMItem() || {};

  const { id: variant_id = '' } = useVItem() || {};

  const { downSm } = useBreakpoints();

  const handleClickItem = async (value: number) => {
    const oldValues = values[variant_id];
    const newValues = {
      ...oldValues,
      [selectedOptionId]: value,
    };

    // console.log("newValues", newValues)

    const possibles = Object.entries(values).filter(
      ([, valuesByVariantId]) => valuesByVariantId[selectedOptionId] === newValues[selectedOptionId],
    );

    const matched = possibles.find(
      ([, valuesByVariantId]) => JSON.stringify(valuesByVariantId) === JSON.stringify(newValues),
    );

    const nextVariantId = matched ? matched[0] : possibles.length > 0 ? possibles[0][0] : undefined;

    // console.log("nextVariantId", nextVariantId)
    // console.log('model_type', model_type)
    if (nextVariantId) {

      /*
      * Where Model Switch Happens
      * (Possible problem if doing variant switching only on mList aka model)
      */
      // define model_type for variant switching
      const modelKey = (model_type !== '' && model_type) ? model_type : 'model_3d_id';

      const data: any = await getDt({
        mList,
        model_3d_id: vList[nextVariantId][modelKey as keyof typeof vList[string]],
        store_id,
        variant_type
      });

      if (data) {
        const {
          mItem,
          group,
          textures
        } = data;

        useStore.setState(() => ({
          mItem,
          model_3d_id: vList[nextVariantId][modelKey as keyof typeof vList[string]],
          // model_3d_id: vList[nextVariantId].model_3d_id,
          group,
          textures,
          vItem: { ...vList[nextVariantId], id: nextVariantId },
          // loading: {
          //   status: 'model',
          //   text: 'Launching AR...'
          // }
        }));
      }
    }
  };

  const displayCount = downSm ? 3 : 5;
  // console.log('currently active sub option', value)
  // console.log('list of all sub options:::', currentSubOptions)
  // console.log('status of whether sub options are size (needs dropdown):::', subOptionsDropdownStatus)
  // console.log('currently active main option', selectedOptionId)

  // For Size Option Field
  if (subOptionsDropdownStatus) {
    return (
      <VariantItemDropdown
        currentSubOptions={currentSubOptions}
        handleClickItem={handleClickItem}
        activeSubOption={value}
      />
    )
  } else {
    return (
      <SizedScrollTabs
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        scrolButtonSize={48}
        itemSize={currentSubOptions.length > displayCount ? 50 : 0}
        itemDistance={30}
        displayCount={currentSubOptions.length > displayCount ? displayCount : 0}
        sx={{ margin: 'auto' }}
        selectionFollowsFocus
      >
        {currentSubOptions.map(({ id, label, texture }, index) => {
          return (
            <Tooltip key={`variant-item-${index}`} title={label} arrow>
              <Tab
                disableRipple
                disableFocusRipple
                component="div"
                sx={{ cursor: 'default' }}
                label={
                  <VariantItem
                    image={texture}
                    active={id === value}
                    onItem={async () => {
                      await handleClickItem(id);
                    }}
                  />
                }
              />
            </Tooltip>
          );
        })}
      </SizedScrollTabs>
    );
  }
};
