// import { viewerMode } from 'configs';
import { ModelOption, ModelOptionValues, ModelSubOptions } from 'store/states/model';
import { VItem, VList } from 'store/states/variants';
import { bucketUrlIndex } from 'configs';

// Set Session ID
export const setSessionID = () => {
  var s: any = [];
  var hexDigits: any = '0123456789abcdef';
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  var uuid = s.join('');
  return uuid;
};

// Enter Full Screen
export function _enterFullScreen() {
  if (document.body.requestFullscreen) {
    document.body.requestFullscreen();
  } else if ((document.body as any)?.webkitRequestFullScreen) {
    (document.body as any)?.webkitRequestFullscreen();
  } else if ((document.body as any)?.mozRequestFullScreen) {
    (document.body as any)?.mozRequestFullscreen();
  } else if ((document.body as any)?.msRequestFullscreen) {
    (document.body as any)?.msRequestFullscreen();
  }
}

// Exit Full Screen
export function _exitFullScreen() {
  // document.body.exitFullscreen();
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else if ((document as any)?.webkitFullscreenElement) {
    (document as any)?.webkitExitFullscreen();
  } else if ((document as any)?.mozFullScreenElement) {
    (document as any)?.mozCancelFullScreen();
  } else if ((document as any)?.msFullscreenElement) {
    (document as any)?.msExitFullscreen();
  }
}

export const _billboard_status_controller = (billboard_list: any, viewer_type: any) => {
  // add billboard_status conditional
  // if(viewer_type === "levar_debut") return false; // levar_debut
  if (viewer_type !== 'billboard_display') return false;
  if (!billboard_list || billboard_list.length < 1) return false; // has billboard lists in payload
  return true;
};

export const _variant_switch_status_controller = (options: any, viewer_type: any) => {
  if (viewer_type !== 'variant_switch_display') return false;
  if (options.length < 2) return false; // has multiple options in payload
  return true;
};

export const _billboard_variant_switch_status_controller = (billboard_list: any, options: any, viewer_type: any) => {
  if (viewer_type !== 'billboard_variant_switch_display') return false;
  if (!billboard_list) return false; // has billboard lists in payload
  if (options.length < 2) return false; // has multiple options in payload
  return true;
};

// From Params Context Extrat Out Variant_id / Ecommerce_id / Variant_type / Model_3d_id from url
export const extractVariantId = (variant: string, ghost: string, model: string, ecomm: string) => {
  const variant_id = variant || '';

  const ecommerce_id = ecomm || '';

  const model_3d_id = model || ghost || ''; // can only be from ghost or model

  // NOTE: variant part used for old variants pls deprecate
  // variant_type: bco | cus | shopify | ghost | model
  const variant_type =
    variant?.includes('bco_') || ecomm?.includes('bco_') ? 'bco' :
      variant?.includes('cus_') || ecomm?.includes('cus_') ? 'cus' :
        ghost.length > 0 ? 'ghost' :
          model.length > 0 ? 'model' :
            'shopify';

  const param_type = ecomm ? 'ecomm' : variant ? 'variant' : model ? 'model' : 'ghost';

  return { variant_id, ecommerce_id, variant_type, model_3d_id, param_type };
};

// Default levAR Text Settings
export const default_levar_text_settings = {
  custom_text_status: false,
  default_text_font: 'Barlow',
  viewer_button: {
    button_color: '#2196F3',
    button_text_color: '#ffffff',
    button_text: 'view in my space',
    button_position: 'center',
  },
  mobile_ar_button: {
    button_color: '#f1f1f1',
    button_text_color: '#525150',
    button_text: 'See in AR',
    button_position: 'flex-start',
  },
};

export const setBooleanValue = (value?: string) => {
  if (value === undefined) {
    return false;
  } else if (value === 'true') {
    return true;
  } else {
    return false;
  }
};

// REDIRECT URL FOR ADD TO CART
type SetAddToCartUrlPayload = {
  variant_id: string;
  vItem?: VItem;
  variant_type: string;
};

const extractCharactersAfterLastUnderscore = (str: string) => {
  const regex = /.*_(.*)/;
  const match = str.match(regex);

  if (match && match.length === 2) {
    return match[1];
  } else {
    return str; // Return null if the string doesn't match the pattern
  }
}

// Bulid Add To Cart URL
export const setAddToCartUrl = ({ variant_id, vItem, variant_type }: SetAddToCartUrlPayload) => {
  if (!variant_id) return '';

  if (!vItem) return '';

  if (!variant_type) return '';

  // console.log('variant_id, vItem, variant_type', variant_id, vItem, variant_type)

  let levar_cart_url = vItem.referer;
  // console.log('levar_cart_url', levar_cart_url)

  if (variant_type === 'bco') {
    var variant_sku = vItem.sku;

    if (variant_sku) {
      // console.log('variant_sku', variant_sku)
      levar_cart_url = levar_cart_url.split('.com/')[0] + '.com/cart.php?action=add&sku=' + variant_sku;
    }
  } else if (variant_type === 'shopify') {
    const updatedVariantID = extractCharactersAfterLastUnderscore(variant_id);
    levar_cart_url = levar_cart_url.split('.com/')[0] + '.com/cart/' + updatedVariantID + ':1';
  } else if (variant_type === 'cus') {
    levar_cart_url = vItem.referer;
  } else {
    levar_cart_url = vItem.referer;
  }

  // console.log('levar_cart_url', levar_cart_url)
  return levar_cart_url;
};


// REDIRECT URL FOR ADD TO CART
type SetArBannerPayload = {
  variant_id: string;
  vItem?: VItem;
  variant_type: string;
  ar_cta_type: string;
};

export const getAfterLastUnderscore = (str: any) => {
  var lastIndex = str.lastIndexOf("_");
  if (lastIndex === -1 || lastIndex === str.length - 1) {
    // If there is no underscore or it is the last character, return empty string
    return "";
  } else {
    return str.substring(lastIndex + 1);
  }
}

// QuickLook Banner Redirect Link part of ar_cta_status
export const setArBannerUrl = ({ variant_id, vItem, variant_type, ar_cta_type }: SetArBannerPayload) => {
  if (!variant_id) return '';

  let variant_id_og = getAfterLastUnderscore(variant_id)

  if (!vItem) return '';

  if (!variant_type && !ar_cta_type) return '';

  // console.log('variant_id, vItem, variant_type ar_cta_type', variant_id, vItem, variant_type, ar_cta_type)
  // console.log('ar_cta_type', ar_cta_type)

  let levar_cart_url = vItem.referer;
  // console.log('levar_cart_url', levar_cart_url)

  if (variant_type === 'bco' && ar_cta_type === 'add_to_cart') {
    var variant_sku = vItem.sku;

    if (variant_sku) {
      levar_cart_url = levar_cart_url.split('.com/')[0] + '.com/cart.php?action=add&sku=' + variant_sku;
    }
  } else if (variant_type === 'shopify' && ar_cta_type === 'add_to_cart') {
    levar_cart_url = levar_cart_url.split('.com/')[0] + '.com/cart/' + variant_id_og + ':1';
  } else if (variant_type === 'cus') {
    levar_cart_url = vItem.referer;
  } else {
    levar_cart_url = vItem.referer;
  }

  return levar_cart_url;
};

export const buildOptions = (
  vList: VList,
  viewer_variant_switch: boolean
): {
  options: ModelOption[];
  subOptions: ModelSubOptions;
  values: ModelOptionValues;
} => {
  // console.log("viewer_variant_switch::", viewer_variant_switch)
  // if mItem doesn't have viewer_variant_switch dont run
  if (!viewer_variant_switch) {
    return {
      options: [],
      subOptions: {},
      values: {},
    };
  }

  // NEW Options look
  // "options": [
  //   {
  //     "option_value": "Color", _option[0]
  //     "option_name": "Tan", _option[1]
  //     "option_prop": "#885f32" _option[2]
  //   }
  // ],

  const { options, subOptions, values } = Object.entries(vList).reduce(
    ({ options, subOptions, values }, [variantId, vItem]) => {
      // console.log('options', options)
      // console.log('subOptions', subOptions) // accumulator
      // console.log('values', values) // map of vItem to what Option and subOption (Values)

      const { options: _options } = vItem;
      // console.log('_options', _options)
      // Make changes in this loop to match the structure of the new ModelOption
      for (const _option of _options) {
        // skip the rest of the iteration if option_prop === 'ignore'
        if (_option.option_prop === 'ignore') {
          continue;
        }
        // console.log('_option', _option)
        // console.log('_option.option_name', _option.option_name)
        // Adjust the condition to find the option based on the new structure
        const option = options.find(({ label }) => {
          // console.log('label::', label)
          // console.log('_option[option_value]::', _option['option_value'])
          return label === _option['option_name']
        });
        // console.log('option::*******', option)

        // If no option first time => create one
        if (!option) {
          const optionId = options.length;
          const subOptionId = 0;

          // Set the main model options values also if it needs a dropdown or not [2]
          options.push({
            id: optionId,
            label: _option.option_name, // Adjust the property names according to the new structure
            dropdown: _option.option_prop === 'dropdown' ? true : false, // Adjust the property value according to the new structure
            // option_name: "", // Add this property with an appropriate value
            // option_value: "", // Add this property with an appropriate value
          });

          subOptions = {
            ...subOptions,
            [optionId]: [
              {
                id: subOptionId,
                label: _option.option_value, // Adjust the property names according to the new structure
                texture: _option.option_prop,
              },
            ],
          };

          values = {
            ...values,
            [variantId]: {
              ...values[variantId],
              [optionId]: subOptionId,
            },
          };
        } else {
          const optionId = option.id;
          const subOption = subOptions[optionId].find(({ label }) => label === _option['option_value']); // Adjust the property name according to the new structure

          if (!subOption) {
            const subOptionId = subOptions[optionId].length;

            subOptions[optionId].push({
              id: subOptionId,
              label: _option.option_value, // Adjust the property name according to the new structure
              texture: _option.option_prop,
            });

            values = {
              ...values,
              [variantId]: {
                ...values[variantId],
                [optionId]: subOptionId,
              },
            };
          } else {
            const subOptionId = subOption.id;

            values = {
              ...values,
              [variantId]: {
                ...values[variantId],
                [optionId]: subOptionId,
              },
            };
          }
        }
      }

      return { options, subOptions, values };
    },
    {
      options: [] as ModelOption[],
      subOptions: {} as ModelSubOptions,
      values: {} as ModelOptionValues,
    },
  );

  // console.log('options:::', options)
  // console.log('subOptions:::', subOptions)
  // console.log('values:::', values)

  return { options, subOptions, values };
};

export const setCustomOrbitAngles = (_orbit_controls_angles: any) => {
  return [
    _orbit_controls_angles[0],
    _orbit_controls_angles[1],
    _orbit_controls_angles[2] === -1 ? Infinity : _orbit_controls_angles[2],
    _orbit_controls_angles[3] === -1 ? Infinity : _orbit_controls_angles[3]
  ];
};

type SetQRcodePayload = {
  ecommerce_session_id: any;
  levar_session_id: any;
  levar_pixel_id: any;
  variant_type: any;
  variant_id: any;
  model_3d_id: any;
  ghost_variant_id: any
  fullscreen: boolean;
  billboards: boolean;
  hank_modal: string;
  model_type: string;
  ar_activation: boolean;
};

/**
 * Helper Function to build Modal or QR code redirect url on SwitchSpace
 */
export const buildUrlQRcode = ({
  variant_type,
  variant_id,
  model_3d_id,
  ghost_variant_id,
  fullscreen,
  billboards,
  hank_modal,
  model_type,
  ar_activation,
  levar_session_id,
  levar_pixel_id,
  ecommerce_session_id
}: SetQRcodePayload) => {

  // 8thWall Lighting Display
  const interactiveModels = [
    'interactive_absolute_ground_0',
    'interactive_absolute_wall_0',
    'interactive_absolute_lighting_0'
  ];

  if (interactiveModels.includes(model_type)) {
    let threed_id = variant_type === 'ghost' ? `?ghost=${ghost_variant_id}` : `?model=${model_3d_id}`;
    let lightingURL = `https://click.8thwall.app/light-display/${threed_id}`;

    return lightingURL
  }

  let url = bucketUrlIndex;
  url += variant_type === 'ghost' ? `?ghost=${ghost_variant_id}` : variant_type === 'model' ? `?model=${model_3d_id}` : `?ecomm=${variant_id}`; // set GHOST || MODEL || VARIANT
  url += `&levar_session_id=${levar_session_id}`; // analytics
  url += levar_pixel_id ? `&levar_pixel_id=${levar_pixel_id}` : ''; // analytics
  url += ecommerce_session_id ? `&ecommerce_session_id=${ecommerce_session_id}` : ''; // analytics
  url += `&origin=external`; // origin always external (going from redirect)
  url += `&auto_ar_launch=on`; // building a QR code to redirect to mobile (want to launch AR on phone)
  url += fullscreen ? '' : '&fullscreen=off' // fullscreen defaults to on
  url += billboards ? '' : '&billboards=off' // billboards defaults to on
  url += (hank_modal || ar_activation) ? '&hank_modal=ground_offset' : ""; // hank modal 
  url += '&qr_mobile_launch=on'; // Lets us know analytics that it was from a QR code

  return url;
};

type SetRedirectPayload = {
  ecommerce_session_id: any;
  levar_session_id: any;
  levar_pixel_id: any;
  variant_type: any;
  variant_id: any;
  model_3d_id: any;
  ghost_variant_id: any
  fullscreen: boolean;
  billboards: boolean;
  hank_modal: string;
  model_type: string;
  ar_activation: boolean;
};

export const buildRedirectURL = ({
  variant_type,
  variant_id,
  model_3d_id,
  ghost_variant_id,
  fullscreen,
  billboards,
  hank_modal,
  model_type,
  ar_activation,
  levar_session_id,
  levar_pixel_id,
  ecommerce_session_id
}: SetRedirectPayload) => {

  let url = bucketUrlIndex;
  url += variant_type === 'ghost' ? `?ghost=${ghost_variant_id}` : variant_type === 'model' ? `?model=${model_3d_id}` : `?ecomm=${variant_id}`; // set GHOST || MODEL || VARIANT
  url += `&levar_session_id=${levar_session_id}`; // analytics
  url += levar_pixel_id ? `&levar_pixel_id=${levar_pixel_id}` : ''; // analytics
  url += ecommerce_session_id ? `&ecommerce_session_id=${ecommerce_session_id}` : ''; // analytics
  url += `&origin=external`; // origin always external (going from redirect)
  url += `&auto_ar_launch=on`; // building a QR code to redirect to mobile (want to launch AR on phone)
  url += fullscreen ? '' : '&fullscreen=off' // fullscreen defaults to on
  url += billboards ? '' : '&billboards=off' // billboards defaults to on
  url += (hank_modal || ar_activation) ? '&hank_modal=ground_offset' : ""; // hank modal 
  url += '&qr_mobile_launch=on'; // Lets us know analytics that it was from a QR code

  return url;
};


type SetViewerFallbackURL = {
  variant_id: string;
  model_3d_id: string;
  ghost_variant_id: string;
  fullscreen: boolean;
  variant_type: string;
  ecommerce_session_id: any;
  billboards: boolean;
  levar_session_id: any;
  levar_pixel_id: any;
  hank_modal: string;
  ar_activation: boolean;
};

/**
 * Helper Function to Fallback URL for viewer (when fails for andriod auto AR launch)
 */
export const buildViewerFallback = ({ variant_id, model_3d_id, ghost_variant_id, fullscreen, variant_type, ecommerce_session_id, levar_session_id, levar_pixel_id, billboards, hank_modal, ar_activation }: SetViewerFallbackURL) => {

  let url = bucketUrlIndex;
  url += variant_type === 'ghost' ? `?ghost=${ghost_variant_id}` : variant_type === 'model' ? `?model=${model_3d_id}` : `?ecomm=${variant_id}`; // set GHOST || MODEL || VARIANT
  url += `&levar_session_id=${levar_session_id}`; // analytics
  url += levar_pixel_id ? `&levar_pixel_id=${levar_pixel_id}` : ''; // analytics
  url += ecommerce_session_id ? `&ecommerce_session_id=${ecommerce_session_id}` : ''; // analytics
  url += `&origin=external`; // origin always external (going from redirect)
  url += fullscreen ? '' : '&fullscreen=off' // fullscreen defaults to on
  url += billboards ? '' : '&billboards=off' // billboards defaults to on
  url += (hank_modal || ar_activation) ? '&hank_modal=ground_offset;' : ";"; // hank modal 

  return url;
};


type SetExitUrlPayload = {
  ecommerce_session_id: any;
  levar_session_id: any;
  levar_pixel_id: any;
  variant_id: any;
  referer: any;
};

/**
 * Helper Function to build exit url for redirect 
 */
export const buildExitUrl = ({ referer, variant_id, levar_session_id, levar_pixel_id, ecommerce_session_id }: SetExitUrlPayload) => {
  // Only for shopify need to 
  const updatedVariantID = extractCharactersAfterLastUnderscore(variant_id)

  let url = referer;
  url += `?variant=${updatedVariantID}`;
  url += `&levar_session_id=${levar_session_id}`; // analytics
  url += levar_pixel_id ? `&levar_pixel_id=${levar_pixel_id}` : ''; // analytics
  url += ecommerce_session_id ? `&ecommerce_session_id=${ecommerce_session_id}` : ''; // analytics

  return url;
};

/**
 * Helper Function to turn string false or true to boolean 
 */
export const parseBool = (str: any) => {

  if (str == null) {
    return false;
  }

  if (typeof str === 'boolean') {
    return (str === true);
  }

  if (typeof str === 'string') {
    if (str === "") return false;

    str = str.replace(/^\s+|\s+$/g, '');
    if (str.toLowerCase() === 'true' || str.toLowerCase() === 'yes')
      return true;

    str = str.replace(/,/g, '.');
    str = str.replace(/^\s*\-\s*/g, '-');
  }

  if (!isNaN(str)) return (parseFloat(str) !== 0);

  return false;
}