import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useQueryValues = (
  params: string[],
): {
  [key: string]: string;
} => {
  const query = useQuery();

  return params
    .map((key) => [key, query.get(key)] as [string, string | null])
    .reduce((prev, curr) => (curr[1] ? { ...prev, [curr[0]]: curr[1] } : prev), {});
};
