import React from 'react';
import { ButtonProps, CircularProgress } from '@mui/material';
import { CheckCircle, CircleNotifications, Error, PersonSearch, Warning } from '@mui/icons-material';

import { DefaultIndicator, DefaultIndicatorProps } from './DefaultIndicator';

export type StatusIndicatorProps = DefaultIndicatorProps & {
  status?: 'success' | 'error' | 'warning' | 'info' | 'loading' | 'empty';
  action?: ButtonProps & {
    label: string;
  };
};

export const StatusIndicator = ({
  status = 'error',
  icon: _icon,
  actions: _actions = [],
  action,
  ...rest
}: StatusIndicatorProps) => {
  const color = 'primary';
  const fontSize = 32;

  const icon = _icon ? (
    _icon
  ) : status === 'success' ? (
    <CheckCircle color={color} sx={{ fontSize }} />
  ) : status === 'error' ? (
    <Error color={color} sx={{ fontSize }} />
  ) : status === 'warning' ? (
    <Warning color={color} sx={{ fontSize }} />
  ) : status === 'info' ? (
    <CircleNotifications color={color} sx={{ fontSize }} />
  ) : status === 'loading' ? (
    <CircularProgress color={color} size={fontSize} />
  ) : (
    <PersonSearch color={color} sx={{ fontSize }} />
  );

  const actions = action ? [action, ..._actions] : _actions;

  return <DefaultIndicator icon={icon} actions={actions} {...rest} />;
};

export const SuccessIndicator = ({ status = 'success', ...rest }: StatusIndicatorProps) => (
  <StatusIndicator status={status} {...rest} />
);

export const ErrorIndicator = ({ status = 'error', ...rest }: StatusIndicatorProps) => (
  <StatusIndicator status={status} {...rest} />
);

export const WarningIndicator = ({ status = 'warning', ...rest }: StatusIndicatorProps) => (
  <StatusIndicator status={status} {...rest} />
);

export const InfoIndicator = ({ status = 'info', ...rest }: StatusIndicatorProps) => (
  <StatusIndicator status={status} {...rest} />
);

export const LoadingIndicator = ({ status = 'loading', ...rest }: StatusIndicatorProps) => (
  <StatusIndicator status={status} {...rest} />
);

export const EmptyIndicator = ({ status = 'empty', ...rest }: StatusIndicatorProps) => (
  <StatusIndicator status={status} {...rest} />
);
