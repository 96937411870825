import React, { useEffect, useRef } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { useBounds } from '@react-three/drei';
import { Box3, Vector3, Vector2, DoubleSide, BackSide, FrontSide, Color } from 'three';

import { useStore } from 'store';
import { CreateMissingSpawn } from 'utils/math';
import { buildBounds } from 'utils/three_helpers';
import { BaseColor } from './BaseColor';
import { Metalness } from './Metalness';
import { Normal } from './Normal';
import { Roughness } from './Roughness';
import { AmbientOcclussion } from './AmbientOcclussion';
import { Opacity } from './Opacity';
import { Emissive } from './Emissive';
import { Transmission } from './Transmission';
import { useDTManagerStatic } from './useDTManagerStatic';

export const DTManagerStatic = () => {
  // console.log('***********DTManagerStatic GEO_TYPE: 0***********')
  const {
    billboards_enabled, // if model has billboard_list and billboards_param === on
    camera_position, // custom_spawn stored on mItem
    deliveryTarget, // stored on group in zustand loaded first from ModelContext
    materials, // mItem details about what the material is supposed to be
    model_3d_id, // model id stored on mItem
    preload_viewer, // from vItem for pdp page auto load
    textures, // stored texture files in zustand loaded first from ModelContext
    turn, // clipless_animations?
  } = useDTManagerStatic();

  const gl = useThree((state) => state.gl);
  const camera = useThree((state) => state.camera);
  const binder = useBounds();
  const group = useRef<any>(null);
  const current_model_3d_id = useRef<string>(model_3d_id);
  const current_turn = useRef<number>(turn);

  useEffect(() => {
    current_model_3d_id.current = model_3d_id;
  }, [model_3d_id]);

  useEffect(() => {
    current_turn.current = turn;
  }, [turn]);

  useEffect(() => {
    let boundingBox = new Box3().setFromObject(group.current);
    const size = boundingBox.getSize(new Vector3());
    const delta = size.y / -2;
    const minYDelta = Math.abs((boundingBox.min.y / delta) * 100);
    const maxYDelta = Math.abs((boundingBox.max.y / delta) * 100);
    const screenSpace = gl.getSize(new Vector2());
    const aspectNormalized = Math.max(screenSpace.x, screenSpace.y) / Math.min(screenSpace.x, screenSpace.y);
    let bounds: Vector3[] = [];

    if (minYDelta < 85 || minYDelta > 115 || maxYDelta < 85 || maxYDelta > 115) {
      group.current.position.set(0, delta, 0);
      boundingBox = new Box3().setFromObject(group.current);
    }

    if (billboards_enabled) {
      bounds = buildBounds(boundingBox);
    } else {
      if (camera_position) {
        camera.position.set(...camera_position);
      } else {
        const { x, y, z } = CreateMissingSpawn({ modelSize: size });
        camera.position.set(x, y, z);
      }

      binder.fit();
    }

    useStore.setState({
      model_loaded: true,
      model: { size, bounds, lengthSum: size.x + size.y + size.z },
      screen: { aspectNormalized },
    });

    if (preload_viewer) window.parent.postMessage('levar_viewer_initialized', '*');
    // eslint-disable-next-line
  }, [binder, billboards_enabled, gl]);

  useFrame(() => {
    if (current_turn.current === 1) group.current.rotation.y += 0.008; // rotate right
    if (current_turn.current === 2) group.current.rotation.y -= 0.008; // rotate left
  });

  const meshes = deliveryTarget.children.map((mesh, index) => {

    const maxAnis = gl.capabilities.getMaxAnisotropy();

    const material_information = {
      attach: 'material',
      color: new Color(materials[index].channels.base_color),
      metalness: materials[index].channels.metalness < 0 ? 1 : materials[index].channels.metalness,
      roughness: materials[index].channels.roughness < 0 ? 1 : materials[index].channels.roughness,
      opacity: materials[index].channels.opacity < 0 ? 1 : materials[index].channels.opacity,
      ior: materials[index].channels.ior,
      thickness: materials[index].channels.thickness,
      transmission: materials[index].channels.transmission < 0 ? 1 : materials[index].channels.transmission,
      emissive: materials[index].channels.emissive,
      emissiveIntensity: materials[index].channels.emissive_intensity,
      transparent: materials[index].transparent,
      depthWrite: materials[index].depth_write,
      side: materials[index].side === 'Front' ? FrontSide : materials[index].side === 'Back' ? BackSide : DoubleSide,
    };

    (mesh as any).geometry.attributes.uv2 = (mesh as any).geometry.attributes.uv;

    if (materials[index].material_type === 'MeshStandardMaterial') {
      return (
        <mesh geometry={(mesh as any).geometry} key={mesh.uuid} name={mesh.name}>
          <meshStandardMaterial {...material_information}>
            {textures[index].baseColor && <BaseColor texture={textures[index].baseColor} maxAnisotropy={maxAnis} />}
            {textures[index].metalness && <Metalness texture={textures[index].metalness} />}
            {textures[index].normal && <Normal texture={textures[index].normal} maxAnisotropy={maxAnis} />}
            {textures[index].roughness && <Roughness texture={textures[index].roughness} />}
            {textures[index].ambientOcclussion && <AmbientOcclussion texture={textures[index].ambientOcclussion} />}
            {textures[index].emissive && <Emissive texture={textures[index].emissive} />}
            {textures[index].opacity && <Opacity texture={textures[index].opacity} />}
          </meshStandardMaterial>
        </mesh>
      );
    }

    return (
      <mesh geometry={(mesh as any).geometry} key={mesh.uuid} name={mesh.name}>
        <meshPhysicalMaterial {...material_information}>
          {textures[index].baseColor && <BaseColor texture={textures[index].baseColor} maxAnisotropy={maxAnis} />}
          {textures[index].metalness && <Metalness texture={textures[index].metalness} />}
          {textures[index].normal && <Normal texture={textures[index].normal} maxAnisotropy={maxAnis} />}
          {textures[index].roughness && <Roughness texture={textures[index].roughness} />}
          {textures[index].ambientOcclussion && <AmbientOcclussion texture={textures[index].ambientOcclussion} />}
          {textures[index].emissive && <Emissive texture={textures[index].emissive} />}
          {textures[index].opacity && <Opacity texture={textures[index].opacity} />}
          {textures[index].transmission && <Transmission texture={textures[index].transmission} />}
        </meshPhysicalMaterial>
      </mesh>
    );
  });

  return (
    <group ref={group} name="levar_fbx" scale={0.01}>
      {meshes}
    </group>
  );
};