import React, { useEffect, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import { HalfFloatType, PMREMGenerator } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

import default_envhdr from 'assets/hdri/photo_studio_01_1k.hdr';
import { useMItem } from 'store';

export const Environment = React.memo(() => {
  const gl = useThree((state) => state.gl);
  const scene = useThree((state) => state.scene);
  const { asset_environment = '' } = useMItem() || {};
  // console.log('environment', environment)

  const generateEnvironment = useCallback(() => {
    const pmremGeneratorEnv = new PMREMGenerator(gl);
    pmremGeneratorEnv.compileEquirectangularShader();

    const loader = new RGBELoader();
    loader.setDataType(HalfFloatType);

    let environment_to_use = default_envhdr;

    // console.log('Check asset_environment::', asset_environment)
    switch (asset_environment) {
      case '1':
        // Use default_envhdr
        break;
      case '2':
        environment_to_use = 'https://hdris.s3.amazonaws.com/autoShop.hdr';
        break;
      case '3':
        environment_to_use = 'https://hdris.s3.amazonaws.com/dramaGold.hdr';
        break;
      case '4':
        environment_to_use = 'https://hdris.s3.amazonaws.com/dramaWhite.hdr';
        break;
      case '5':
        environment_to_use = 'https://hdris.s3.amazonaws.com/paint.hdr';
        break;
      case '6':
        environment_to_use = 'https://hdris.s3.amazonaws.com/photoStudio180.hdr';
        break;
      case '7':
        environment_to_use = 'https://hdris.s3.amazonaws.com/snow.hdr';
        break;
      case '8':
        environment_to_use = 'https://hdris.s3.amazonaws.com/twoRing.hdr';
        break;
      case '9':
        environment_to_use = 'https://hdris.s3.amazonaws.com/aliveTime.hdr';
        break;
      default:
        // Use default_envhdr
        break;
    }

    loader.load(environment_to_use, (texture) => {
      const textureData = pmremGeneratorEnv.fromEquirectangular(texture).texture;
      scene.environment = textureData;
      texture.dispose();
      pmremGeneratorEnv.dispose();
    });
  }, [scene, gl, asset_environment]);

  useEffect(() => {
    generateEnvironment();
  }, [generateEnvironment]);

  return null;
});
