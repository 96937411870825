import React from 'react';
import { Box, Divider, IconButton, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import rotateIcon from 'assets/icons/help_rotate.svg';
import scrollIcon from 'assets/icons/help_scroll.svg';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useStore } from 'store';
import { HelpItem } from './HelpItem';

type HelpProps = {
  onClose?: () => void;
};

export const Help = ({ onClose = () => {} }: HelpProps) => {
  const theme = useTheme();
  const { downSm } = useBreakpoints();
  const fullscreen = useStore((state) => state.params.fullscreen);

  const helpItems = [
    { icon: rotateIcon, text: 'Left click and drag to rotate' },
    { icon: scrollIcon, text: 'Scroll to zoom in or out' },
  ];

  const itemWrapperSx = {
    width: `${100 / helpItems.length}%`,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  };

  return (
    <Box
      component="div"
      sx={{
        px: downSm ? 0 : 1,
        pt: downSm ? 2 : 1,
        pb: fullscreen && downSm ? 6 : 1,
        position: 'relative',
        borderRadius: downSm ? 0 : 1,
        overflow: 'hidden',
      }}
    >
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: theme.palette.common.black,
          opacity: 0.6,
        }}
      />

      <Box
        component="div"
        sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {helpItems.map(({ icon, text }, index) => (
          <React.Fragment key={`help-section-${index}`}>
            <Box component="div" sx={itemWrapperSx}>
              <HelpItem icon={icon} text={text} />
            </Box>

            {index < helpItems.length - 1 && (
              <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: theme.palette.grey[300] }} />
            )}
          </React.Fragment>
        ))}
      </Box>

      <IconButton size="small" sx={{ position: 'absolute', top: 4, right: 4 }} onClick={() => onClose()}>
        <Close sx={{ fontSize: 18, color: theme.palette.common.white }} />
      </IconButton>
    </Box>
  );
};
