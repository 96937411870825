import React from 'react';
import { Box } from '@mui/material';

import { Help } from 'components/help';
import { Drawer } from './Drawer';
import { useViewerLayoutContext } from './ViewerLayoutContext';
import { HelpContainer } from './HelpContainer';

type ViewerLayoutProps = {
  children?: any;
};

export const ViewerLayout = ({ children = null }: ViewerLayoutProps) => {
  const {
    containerProps,
    visibleTopDrawer,
    visibleLeftDrawer,
    visibleBottomDrawer,
    topDrawerProps: _topDrawerProps,
    leftDrawerProps: _leftDrawerProps,
    bottomDrawerProps: _bottomDrawerProps,
    updateViewerLayout,
  } = useViewerLayoutContext();

  const { containerId = 'threed-viewer-layout', sx: containerSx, ...restSx } = containerProps;

  const ModalProps = { container: () => document.getElementById(containerId) };

  const topDrawerProps = { ..._topDrawerProps, ModalProps };
  const leftDrawerProps = { ..._leftDrawerProps, ModalProps };
  const bottomDrawerProps = { ..._bottomDrawerProps, ModalProps };

  /**
  * Fullscreen ON Viewer
  */
  // console.log('bottomDrawerProps', bottomDrawerProps)
  return (
    <Box
      {...restSx}
      id={containerId}
      component="div"
      sx={{
        ...containerSx,
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {/* 3D Viewer */}
      <Box component="div" sx={{ width: '100%', height: '100%' }}>
        {children}
      </Box>

      {/* Viewer Space Switch */}
      <Drawer anchor="top" open={visibleTopDrawer} {...topDrawerProps} />

      {/* Left Toggle */}
      <Drawer anchor="left" open={visibleLeftDrawer} {...leftDrawerProps} />

      {/* Bottom Toggle */}
      <Drawer anchor="bottom" open={visibleBottomDrawer} {...bottomDrawerProps} />

      <HelpContainer>
        <Help onClose={() => updateViewerLayout({ visibleHelp: false })} />
      </HelpContainer>
    </Box>
  );
};
