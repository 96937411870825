import { useCallback } from 'react';

import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { setAddToCartUrl } from 'utils/helpers';
import { sendAnalyticsData } from 'utils/handleAnalytics';
import { useStore, useVItem } from 'store';

export const useClickAddToCart = () => {
  const variant_type = useStore((state) => state.params.variant_type);
  const origin = useStore((state) => state.params.origin);
  const { id: variant_id = '' } = useVItem() || {};
  const vItem = useVItem();
  const viewerPageCartAnalytics = useAnalyticsData({ event_type: 'viewer_add_to_cart' });

  const handleClickAddToCart = useCallback(async () => {
    const levar_cart_redirect = setAddToCartUrl({ variant_id, vItem, variant_type });

    if (levar_cart_redirect && (origin !== 'shopify' && origin !== 'bigc')) {
      // ANALYTICS TODO
      await sendAnalyticsData(viewerPageCartAnalytics);
      window.location.assign(levar_cart_redirect);
    } else {
      // Only FOR ON PDP ADD_TO_CART
      window.parent.postMessage({
        type: 'addToCartPost',
        variant_id: variant_id
      }, '*');
    }
    // eslint-disable-next-line
  }, [variant_id, variant_type]);

  return { handleClickAddToCart };
};
