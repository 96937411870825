import { createContext, useContext, useEffect, useState } from 'react';

import { useStore, useVItem } from 'store';
import { ModelOption, ModelOptionValues, ModelSubOption, ModelSubOptions } from 'store/states/model';

type SwitchVariantContextType = {
  options: ModelOption[];
  subOptions: ModelSubOptions;
  values: ModelOptionValues;
  selectedOptionId: number;
  updateSwitchVariantContext: (value: Omit<Partial<SwitchVariantContextType>, 'updateSwitchVariantContext'>) => void;
};

const defaultSwitchVariantContextValue: SwitchVariantContextType = {
  options: [],
  subOptions: {},
  values: {},
  selectedOptionId: -1,
  updateSwitchVariantContext: () => { }
};

export const SwitchVariantContext = createContext<SwitchVariantContextType>(defaultSwitchVariantContextValue);

type SwitchVariantContextProviderProps = {
  children?: any;
};

export const SwitchVariantContextProvider = ({ children }: SwitchVariantContextProviderProps) => {
  const [contextValue, setContextValue] = useState<SwitchVariantContextType>(defaultSwitchVariantContextValue);

  const updateSwitchVariantContext = (value: Omit<Partial<SwitchVariantContextType>, 'updateSwitchVariantContext'>) =>
    setContextValue((prev) => ({ ...prev, ...value }));

  const options = useStore((state) => state.model_options);
  const subOptions = useStore((state) => state.model_sub_options);
  const values = useStore((state) => state.model_option_values);

  useEffect(() => {
    updateSwitchVariantContext({
      options,
      subOptions,
      values,
      selectedOptionId:
        contextValue.selectedOptionId > -1 ? contextValue.selectedOptionId : options.length > 0 ? options[0].id : -1
    });
    // eslint-disable-next-line
  }, [JSON.stringify(options), JSON.stringify(subOptions), JSON.stringify(values)]);

  return (
    <SwitchVariantContext.Provider value={{ ...contextValue, updateSwitchVariantContext }}>
      {children}
    </SwitchVariantContext.Provider>
  );
};

export const useSwitchVariantContext = () => useContext(SwitchVariantContext);

export const useSelectedOptionValues = (): {
  currentSubOptions?: ModelSubOption[];
  value?: number;
  subOptionsDropdownStatus?: boolean;
} => {
  const { options, subOptions, values, selectedOptionId } = useSwitchVariantContext();
  // console.log('subOptions, values, selectedOptionId', subOptions, values, selectedOptionId)
  const { id: variant_id = '' } = useVItem() || {};

  if (selectedOptionId === undefined) {
    return {};
  }

  const currentSubOptions = subOptions[selectedOptionId];

  if (!currentSubOptions) {
    return {};
  }

  if (!variant_id) {
    return {};
  }

  const value = values[variant_id][selectedOptionId];

  if (value === undefined) {
    return { currentSubOptions };
  }

  // dropdown value based on if it is SIZE as an option name
  const subOptionsDropdownStatus = options[selectedOptionId].dropdown;

  // console.log('subOptionsDropdownStatus', subOptionsDropdownStatus)
  // TODO LOOKING for random added on Label
  // {id: 2, label: 'Color: -'}

  return { currentSubOptions, value, subOptionsDropdownStatus };
};
