import React from 'react';
import { LinearFilter, NearestMipmapLinearFilter, SRGBColorSpace, Texture } from 'three';

export const BaseColor = ({ texture, maxAnisotropy }: { texture?: Texture; maxAnisotropy: any }) => {
  if (!texture) return null;

  // colorscience
  return (
    <texture
      attach="map"
      {...texture}
      colorSpace={SRGBColorSpace}
      anisotropy={maxAnisotropy}
      minFilter={NearestMipmapLinearFilter}
      magFilter={LinearFilter}
      name="updated_levar_map"
    />
  );
};
