import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import loadingImage from 'assets/logos/levAR_logo.svg';

const defaultErrorMessage = 'Error!';

type VariantErrorProps = {
  text?: string;
};

export const VariantError = ({ text = defaultErrorMessage }: VariantErrorProps) => {
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        background: `linear-gradient(45deg, ${theme.palette.grey[900]} 0%, ${theme.palette.grey[800]} 50%, ${theme.palette.grey[900]} 100%)`,
      }}
    >
      <Box component="div" style={{ textAlign: 'center' }}>
        <img src={loadingImage} alt="levAR" style={{ height: '25vmin' }} />

        <Typography
          variant="h4"
          sx={{ color: theme.palette.common.white, fontSize: '2em', letterSpacing: '.03em', fontWeight: 300, my: 3 }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
