import React from 'react';
import { Container, Grid, Typography, IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';

import { VariantSlider } from 'components/variant-slider';
import { useStore, useMItem } from 'store';

export const ViewerBillboard = () => {
  const active_board = useStore((state) => state.scene_controls.active_board);
  const activeIndex = useStore((state) => state.scene_controls.active_board) || 0;
  const { billboard_list = [] } = useMItem() || {};

  const billboardInfo = billboard_list[activeIndex] || {};
  const { h1: { text: title = '' } = {}, p: { text: description = '' } = {} } = billboardInfo.info || {};

  const images = billboard_list.map(({ info: { image_1: { src: imageUrl = '' } = {} } = {} }, index) => imageUrl);

  const onItem = ({ pointIndex: active_board }: { pointIndex: number }) => {
    useStore.setState((state) => ({
      scene_controls: {
        ...state.scene_controls,
        active_board,
      },
      billboard_settings: {
        ...state.billboard_settings,
        billboard_listener_click: true,
      },
    }));
  }

  const onRefresh = () => {
    // TODO Ask MONTY can change to onRefresh() Also bring in disabled current
    useStore.setState((state) => ({
      scene_controls: {
        ...state.scene_controls,
        active_board: 0,
      },
      billboard_settings: {
        ...state.billboard_settings,
        billboard_listener_click: true,
      },
    }));
  }

  return (
    <Container maxWidth="sm" sx={{ py: 2, textAlign: { xs: 'left', sm: 'center' } }}>
      <Grid container spacing={3} justifyContent="center">
        {/* TODO: Better way to position this reset button */}
        <div style={{ position: 'absolute', right: '1em', top: '5.2em' }}>
          <IconButton onClick={onRefresh} sx={{ ml: 1 }} disabled={active_board === 0}>
            <Refresh fontSize='medium' />
          </IconButton>
        </div>

        {title && (
          <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" align="center">{title}</Typography>
          </Grid>
        )}

        {description && (
          <Grid item xs={12}>
            <Typography align="center">{description}</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <VariantSlider images={images} activeIndex={activeIndex} onItem={onItem} orientation="horizontal" />
        </Grid>
      </Grid>
    </Container>
  );
};
