// Dev Mode
export const viewerMode = process.env.REACT_APP_VIEWER_MODE || 'development';

// Main Viewer URL (viewer.levardev.com || viewer.levarstaging.com || viewer.levar.io)
export const bucketUrlIndex = process.env.REACT_APP_BUCKET_URL_INDEX || '';

// levar_ecommerce_stores api
export const levar_ecommerce_stores = process.env.REACT_APP_ECOMMERCE_STORES || ''

// levar_ecommerce_variants api
export const levar_api_2023 = process.env.REACT_APP_LEVAR_API_2023 || '';

// Ghost Art S3 Bucket
export const ghostUrlAr = process.env.REACT_APP_GHOST_BUCKET || '';

// Model Art S3 Bucket
export const modelUrlAr = process.env.REACT_APP_MODEL_BUCKET || '';

// Model Art S3 Bucket
export const hankUrlAr = process.env.REACT_APP_HANK_BUCKET || '';
