import React, { useState } from 'react';
import { Button, Container, Drawer, Grid, IconButton } from '@mui/material';
import { Close, Palette } from '@mui/icons-material';

import qrIcon from 'assets/icons/qrCode-white.svg';
import { SwitchSpace } from 'components/switch-space';
import { SwitchVariant } from 'components/switch-variant';
import { useMItem } from 'store';
import { useBreakpoints } from 'hooks/useBreakpoints';

export const BottomControls = () => {
  const [visibleBottomDrawer, setVisibleBottomDrawer] = useState<boolean>(false);
  const { viewer_variant_switch = '' } = useMItem() || {};
  const { downSm } = useBreakpoints();

  return (
    <>
      <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
        <Grid item>
          <SwitchSpace
            contentComponent={Button}
            contentComponentProps={{
              variant: 'contained',
              endIcon: <img src={qrIcon} alt="View in my space" style={{ width: 20, height: 20 }} />,
              children: downSm ? 'See in AR' : 'View in my space',
            }}
          />
        </Grid>

        {viewer_variant_switch && (
          <Grid item>
            <Button variant="contained" onClick={() => setVisibleBottomDrawer(true)} startIcon={<Palette />}>
              {downSm ? 'Custom' : 'Custom Variant'}
            </Button>
          </Grid>
        )}
      </Grid>

      <Drawer
        variant="persistent"
        open={visibleBottomDrawer}
        anchor="bottom"
        hideBackdrop
        PaperProps={{
          sx: {
            boxShadow: `0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)`,
            pb: 3,
          },
        }}
      >
        <Container maxWidth="xl" sx={{ py: 3, position: 'relative' }}>
          <SwitchVariant />

          <IconButton onClick={() => setVisibleBottomDrawer(false)} sx={{ position: 'absolute', top: 16, right: 16 }}>
            <Close />
          </IconButton>
        </Container>
      </Drawer>
    </>
  );
};
