import React from 'react';
import {
  styled,
  svgIconClasses,
  tabClasses,
  Tabs,
  tabsClasses,
  tabScrollButtonClasses,
  TabsProps,
} from '@mui/material';

const defaultScrolButtonSize = 30;
const defaultItemDistance = 16;
const defaultDisplayCount = 5;

type SizedScrollTabsProps = TabsProps & {
  scrolButtonSize?: number;
  itemSize?: number;
  itemDistance?: number;
  displayCount?: number;
};

export const SizedScrollTabs = styled(
  ({ scrolButtonSize, ...rest }: SizedScrollTabsProps) => (
    <Tabs
      {...rest}
      TabIndicatorProps={{
        sx: { height: 0, width: 0 },
      }}
      TabScrollButtonProps={{
        sx: { display: 'inline-flex !important' },
      }}
      scrollButtons="auto"
    />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'scrolButtonSize' && prop !== 'itemSize' && prop !== 'itemDistance' && prop !== 'displayCount',
  },
)(
  ({
    scrolButtonSize = defaultScrolButtonSize,
    itemSize,
    itemDistance = defaultItemDistance,
    displayCount = defaultDisplayCount,
    orientation,
  }) => {
    const size = itemSize ? itemSize * displayCount + scrolButtonSize * 2 + itemDistance * displayCount : 0;
    const sizeStyle = size ? (orientation === 'vertical' ? { height: size } : { width: size }) : {};

    const paddingStyle =
      orientation === 'vertical'
        ? {
            paddingTop: itemDistance / 2,
            paddingBottom: itemDistance / 2,
            paddingLeft: 0,
            paddingRight: 0,
          }
        : {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: itemDistance / 2,
            paddingRight: itemDistance / 2,
          };

    return {
      alignItems: 'center',
      minHeight: 0,
      maxHeight: '100%',
      minWidth: 0,
      maxWidth: '100%',
      ...sizeStyle,
      [`& .${tabsClasses.scrollButtons}`]: {
        width: scrolButtonSize,
        height: scrolButtonSize,
        borderRadius: scrolButtonSize,
        marginLeft: 0,
        marginRight: 0,
        '&.Mui-disabled': { opacity: 0.3 },
      },
      [`& .${tabClasses.root}`]: {
        ...paddingStyle,
        minWidth: 0,
        minHeight: 0,
        maxWidth: 'unset',
        maxHeight: 'unset',
      },
      [`& .${tabScrollButtonClasses.root}`]: {
        [`& .${svgIconClasses.root}`]: {
          fontSize: scrolButtonSize,
        },
      },
    };
  },
);
