import React, { useState } from 'react';
import { LineBasicMaterial, BufferGeometry, Color } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei';

import { getRulerText } from 'utils/conversion';
import { Midpoint3D, Distance3D, PointOnALine3D } from 'utils/math';

type LineRulerProps = {
  ruler: any;
  size: any;
  visible: any;
};

export const LineRuler = ({ ruler, size, visible }: LineRulerProps) => {
  const camera = useThree((state) => state.camera);

  const [line0, setLine0] = useState<any>({});
  const [line1, setLine1] = useState<any>({});

  const [frameCount, setCount] = useState<number>(0);
  const [justOnce, setJustOnce] = useState<boolean>(false);

  const textRef = React.useRef<any>(null);

  const { unit, precision, text_size, text_color, text_stroke_color, text_stroke_size, color, custom_font } = ruler[2];

  const midpoint = Midpoint3D({ p0: ruler[0], p1: ruler[1] });

  const lineLength = Distance3D({ p0: ruler[0], p1: ruler[1] });

  const fontSize = ((size.x + size.y + size.z) / 50) * ((2 + lineLength / Math.max(size.x, size.y, size.z)) / 3);

  const lineMat = new LineBasicMaterial({ color: new Color(color) });
  // console.log('lineMat', lineMat)

  // lineMat.clipShadows = true;

  useFrame(() => {
    textRef.current.lookAt(camera.position.x, camera.position.y, camera.position.z);

    if (frameCount < 10) {
      setCount(frameCount + 1);
    } else if (!justOnce) {
      setJustOnce(true);
      setLine0(
        new BufferGeometry().setFromPoints([
          ruler[0],
          PointOnALine3D({
            p0: ruler[0],
            p1: midpoint,
            d: lineLength / 2 - textRef.current.geometry.boundingBox.max.x * 1.5,
          }),
        ]),
      );
      setLine1(
        new BufferGeometry().setFromPoints([
          ruler[1],
          PointOnALine3D({
            p0: ruler[1],
            p1: midpoint,
            d: lineLength / 2 - textRef.current.geometry.boundingBox.max.x * 1.5,
          }),
        ]),
      );
    }
  });

  const line0Props: any = { geometry: line0, material: lineMat };
  const line1Props: any = { geometry: line1, material: lineMat };

  return (
    <group castShadow={false} visible={true} name="ruler_levar">
      <Text
        ref={textRef}
        font={custom_font ? custom_font : 'https://levar-viewer-settings.s3.amazonaws.com/fonts/Barlow-Medium.woff'}
        color={text_color}
        outlineWidth={(fontSize / 20) * text_stroke_size}
        outlineOpacity={1}
        outlineColor={text_stroke_color}
        anchorX="center"
        anchorY="middle"
        fontSize={fontSize * text_size}
        position={midpoint}
        visible={justOnce && visible}
        matrixWorldAutoUpdate={true}
      >
        {getRulerText(lineLength, unit, precision, ruler[3])}
      </Text>

      {justOnce && (
        <>
          <line {...line0Props} />
          <line {...line1Props} />
        </>
      )}
    </group>
  );
};
