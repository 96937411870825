import { Vector3 } from 'three';

export type Model = {
  size: Vector3;
  bounds: Vector3[];
  lengthSum: number;
};

export type ModelAnimations = {
  model_animation_status: boolean;
  bones_default: boolean;
};

export type ModelOption = {
  // option_name: any;
  // option_value: any;
  id: number;
  label: string;
  dropdown: boolean;
};

export type ModelSubOption = {
  id: number;
  label: string;
  texture: string;
};

export type ModelSubOptions = {
  [optionId: number]: ModelSubOption[];
};

export type ModelOptionValues = {
  [variantId: string]: {
    [optionId: number]: ModelSubOption['id'];
  };
};

export type CliplessAnimations = {
  turn: number;
  hover: number;
};

export type ModelControls = {
  clipless_animations: CliplessAnimations;
  clockCount: number;
  deltas: any[];
  fps: number;
  poiPulse: number;
  poiPulseUp: boolean;
};

export type ModelState = ModelControls & {
  model_loaded: boolean;
  model: Model;
  model_animations: ModelAnimations;
  model_options: ModelOption[];
  model_sub_options: ModelSubOptions;
  model_option_values: ModelOptionValues;
  auto_rotation: number;
};

export const modelState: ModelState = {
  model_loaded: false,
  model: {
    size: new Vector3(0, 0, 0), // used for shadows
    bounds: [],
    lengthSum: 0,
  },
  model_animations: {
    model_animation_status: false,
    bones_default: false,
  },
  model_options: [],
  model_sub_options: {},
  model_option_values: {},
  clipless_animations: {
    turn: 0,
    hover: 0,
  },
  clockCount: 0,
  deltas: [],
  fps: 60,
  poiPulse: 1,
  poiPulseUp: false,
  auto_rotation: 0,
};
