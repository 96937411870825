import React from 'react';
import { Box, BoxProps } from '@mui/material';

import { BaseDrawer, BaseDrawerProps } from 'components/@styled-mui/Drawer';

export type DrawerProps = Omit<BaseDrawerProps, 'children'> & {
  contentComponent?: any;
  contentComponentProps?: any;
  containerProps?: BoxProps;
};

export const Drawer = ({
  contentComponent: ContentComponent,
  contentComponentProps,
  containerProps = {},
  ...baseDrawerProps
}: DrawerProps) => {
  return ContentComponent ? (
    <BaseDrawer {...baseDrawerProps}>
      <Box {...containerProps} component="div">
        <ContentComponent {...contentComponentProps} />
      </Box>
    </BaseDrawer>
  ) : null;
};
