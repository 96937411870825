import { useEffect, useState } from "react";
import { useThree } from "@react-three/fiber";
import { LinearToneMapping, SRGBColorSpace } from "three";

const PreDT = () => {
  const gl = useThree((state) => state.gl);
  const [justOnce, setJustOnce] = useState(false);

  useEffect(() => {
    if (!justOnce) {
      gl.toneMapping = LinearToneMapping
      gl.outputColorSpace = SRGBColorSpace;
      gl.antialias = true
      setJustOnce(true);
    }
  }, [justOnce, gl])

  return null
};

export default PreDT;