import create from 'zustand';

import { state, State } from './states';

type Actions = {
  pulsePoi: () => void;
  countClock: (delta: number) => void;
};

type Store = State & {
  actions: Actions;
};

export const useStore = create<Store>((set, get) => ({
  ...state,
  actions: {
    pulsePoi: () => {
      if (get().poiPulseUp) {
        if (get().poiPulse < 1.3) {
          set((state) => ({ poiPulse: state.poiPulse + 0.0025 }));
        } else {
          set({ poiPulseUp: false });
        }
      } else {
        if (get().poiPulse > 1) {
          set((state) => ({ poiPulse: state.poiPulse - 0.0025 }));
        } else {
          set({ poiPulseUp: true });
        }
      }
    },
    countClock: (delta) => {
      //new
      if (get().clockCount < 3) {
        const deltas = get().deltas;

        if (deltas.length === 120) {
          set({
            fps: 1 / (deltas.reduce((a, b) => a + b, 0) / deltas.length),
            deltas: [delta],
            clockCount: get().clockCount + 1,
          });
        } else {
          deltas.push(delta);
          set({ deltas: deltas });
        }
      }
    },
  },
}));
