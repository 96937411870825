import { StoreSettings } from 'store/states/store-settings'
import { levar_ecommerce_stores } from 'configs';


const getStoreSettingsDetails = (data: any = {}) => {
  const {
    ar_experience: _ar_experience,
    cLogo = '',
    custom_banner_url = '',
    dynamic_text_settings = undefined,
    ga_id = '',
    klaviyo_id = '',
    ruler_settings = undefined,
    yotpo_id = '',
    store_domain = '',
  } = data;

  const ar_experience = _ar_experience ?? {
    ar_cta_status: false,
    ar_cta_type: 'undefined',
  };

  const result: StoreSettings = {
    ...data,
    ar_experience,
    cLogo,
    custom_banner_url,
    dynamic_text_settings,
    ga_id,
    klaviyo_id,
    ruler_settings,
    yotpo_id,
    store_ruler_settings: ruler_settings, // override field for better readability store vs variant
    store_domain,
  };

  return result;
};

export const fetchStoreSettings = async (variant_type: string, store_id: string) => {
  try {
    const url = `${levar_ecommerce_stores}/public/store?store_id=${store_id}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Fetch Call Cannot Be Made`);
    }

    const { result: data } = await response.json();

    // console.log('STORE DATA::', data)

    const result = getStoreSettingsDetails(data);

    return result;
  } catch (error) {
    console.error('fetchStoreSettings error', error);

    const result: StoreSettings = {
      ar_experience: {
        ar_cta_status: false,
        ar_cta_type: 'undefined',
      },
      cLogo: '',
      custom_banner_url: '',
      dynamic_text_settings: undefined,
      ga_id: '',
      klaviyo_id: '',
      ruler_settings: undefined,
      yotpo_id: '',
      store_ruler_settings: undefined,
      store_domain: ''
    };

    return result;
  }
};
