import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Facebook, Instagram, Twitter, IosShare } from '@mui/icons-material';


export const ViewerSocial = () => {

  const shareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: '3D Viewer',
        text: 'Check out this model',
        url: `${window.location.href}`,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  return (
    <Box component="div" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={2} sx={{ width: 'fit-content' }} alignContent="center">

        <Grid item xs={12}>
          <Typography align="center" variant="subtitle2">Social Share Links</Typography>
        </Grid>

        <Grid item xs={3}>
          <IconButton aria-label="facebook" color="primary" size="large" disabled>
            <Facebook fontSize="medium" />
          </IconButton>
        </Grid>

        <Grid item xs={3}>
          <IconButton aria-label="Instagram" color="primary" size="large" disabled>
            <Instagram fontSize="medium" />
          </IconButton>
        </Grid>

        <Grid item xs={3}>
          <IconButton aria-label="Twitter" color="primary" size="large" disabled>
            <Twitter fontSize="medium" />
          </IconButton>
        </Grid>

        <Grid item xs={3}>
          <IconButton disabled aria-label="Custom" color="primary" size="large" onClick={shareLink}>
            <IosShare fontSize="medium" />
          </IconButton>
        </Grid>

      </Grid>
    </Box>
  );
};