import React from 'react';
import { styled, Drawer, DrawerProps } from '@mui/material';

export type BaseDrawerProps = DrawerProps;

export const BaseDrawer = styled(
  (props: BaseDrawerProps) => (
    <Drawer
      variant="persistent" // TODO: Monty causes bottom scroll out of bounds to happen if not there (temp works)
      hideBackdrop
      PaperProps={{
        sx: {
          bgcolor: 'transparent',
          boxShadow: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none',
          borderBottom: 'none',
          position: 'absolute',
          transform: 'inherit !important' // FIXES: Monty causes bottom scroll out of bounds to happen if not there (temp works)
        },
      }}
      {...props}
    />
  ),
  {},
)();
