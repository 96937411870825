import React from 'react';
import { Grid, Typography } from '@mui/material';

export type BillboardHeaderProps = {
  title?: string;
  price?: string;
};

export const BillboardHeader = ({ title = '', price = '' }: BillboardHeaderProps) => {
  if (!title && !price) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
      )}

      {price && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="primary">
            {price}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
