import React from 'react';
import { CssBaseline } from '@mui/material';

import { LoadingVariant, VariantError } from 'components/indicators';
import {
  AppLayoutContextProvider,
  HrxContextProvider,
  ModelContextProvider,
  ParamsContextProvider,
  StoreSettingsContextProvider,
  ThemeContextProvider,
  VariantsContextProvider,
  ViewerLayoutContextProvider,
} from 'contexts';
import { useQueryValues } from 'hooks/useQueryValues';
import { useStore } from 'store';
import { VariantViewer } from 'variant-viewer';

export type Params = {
  origin: string;
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;

  /**
  * Model Location
  */
  variant: string;
  ghost: string;
  model: string;
  ecomm: string;

  /**
  * Analytics
  */
  levar_pixel_id: string;
  viewer_session_id: string;
  ecommerce_session_id: string;
  levar_session_id: string;
  qr_mobile_launch: 'on' | 'off'; // knows if it came from QR code

  /**
   * Use to config layout
   */
  add_to_cart: 'on' | 'off';
  billboards: 'on' | 'off';
  fullscreen: 'on' | 'off';
  header: 'on' | 'off';
  left_slider: 'on' | 'off';
  right_header: 'on' | 'off';
  top_border: 'on' | 'off';
  bottom_drawer: 'on' | 'off';
  view_in_space_off: 'on' | 'off';
  social_share: 'on' | 'off';
  auto_ar_launch: 'on' | 'off';
  // see_ar_btn: 'on' | 'off';
  autorotation: string;

  /**
   * Activation config
   */
  hank_modal: string;

  /**
   * PDP config
   */
  viewer_type: string;

  /**
   * TODO: confirm to remove
   */
  ar_activation: string;
};

export const App = () => {
  const params: Partial<Params> = useQueryValues([
    'origin',
    'utm_campaign',
    'utm_medium',
    'utm_source',

    /**
    * Model Location
    */
    'variant',
    'ghost',
    'model',
    'ecomm',

    /**
    * Analytics
    */
    'levar_pixel_id',
    'viewer_session_id',
    'ecommerce_session_id',
    'levar_session_id',
    'qr_mobile_launch',

    /**
     * Use to config layout
     */
    'add_to_cart',
    'billboards',
    'fullscreen',
    'header',
    'left_slider',
    'right_header',
    'top_border',
    'bottom_drawer',
    'view_in_space_off',
    'social_share',
    // 'see_ar_btn',
    'autorotation',

    /**
     * Activation config
     */
    'hank_modal',
    'auto_ar_launch',

    /**
     * PDP config
     */
    'viewer_type',

    /**
     * TODO: confirm to remove deprecatino for LSA using old Hank
     */
    'ar_activation'
  ]);

  const status = useStore((state) => state.loading.status);
  const text = useStore((state) => state.loading.text);

  return (
    <ThemeContextProvider>
      <CssBaseline />

      <ParamsContextProvider {...params}>
        <HrxContextProvider>
          <VariantsContextProvider>
            <StoreSettingsContextProvider>
              <ModelContextProvider>
                <AppLayoutContextProvider>
                  <ViewerLayoutContextProvider>
                    <VariantViewer />
                  </ViewerLayoutContextProvider>
                </AppLayoutContextProvider>
              </ModelContextProvider>
            </StoreSettingsContextProvider>
          </VariantsContextProvider>
        </HrxContextProvider>
      </ParamsContextProvider>

      {status === 'error' ? (
        <VariantError text={text} />
      ) : (
        <LoadingVariant
          loading={
            status === 'start' ||
            status === 'params' ||
            status === 'hrx' ||
            status === 'variant' ||
            status === 'store-settings' ||
            status === 'app-layout'
          }
          text={text}
        />
      )}
    </ThemeContextProvider>
  );
};
