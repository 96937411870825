import {
  Vector3,
  CubicBezierCurve3,
  QuadraticBezierCurve3,
  Box3,
  TextureLoader,
  WebGLRenderer
} from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import {
  ghostUrlAr,
  modelUrlAr
} from 'configs';
import { Model } from 'store/states/model';
import {
  MItem,
  MList
} from 'store/states/variants';
import { WeightedGraph } from './Dijkstra';
import { Distance3D, indexOfSmallest, PointsOnALine3D, Midpoint3D } from './math';

export const build_dijkstra = (
  lattice_graph: any,
  lattice: any,
  clipAnchors: any,
  orbit_controls_target: any,
  cameraPosition: any,
  locatorPosition: any,
  density: any,
  point_index: any,
) => {
  const anchorPosition = clipAnchors[point_index];

  //Make a new empty Dijkstra Graph
  let graph = new WeightedGraph();
  //Add vertices, 8 for lattice vertices, 6 for lattice faces, and 2 for camera/clipAnchor points
  for (let i = 0; i < 16; i++) {
    graph.addVertex(`${i}`);
  }

  //Map the 36 known edges between the lattice vetices and faces
  for (let i = 0; i < 36; i++) {
    graph.addEdge(lattice_graph[i][0], lattice_graph[i][1], lattice_graph[i][2]);
  }

  //create distance arrays for the clipAnchor and camera's relationship to lattice points
  const cameraList = [];
  const anchorList = [];

  for (let i = 0; i < 14; i++) {
    anchorList.push(Distance3D({ p0: anchorPosition, p1: lattice[i] }));
    cameraList.push(Distance3D({ p0: cameraPosition, p1: lattice[i] }));
  }

  //indentify the lattice points closest to the camera and clipAnchor
  const smolCamera = indexOfSmallest({ a: cameraList });
  const smolAnchor = indexOfSmallest({ a: anchorList });

  //Map the edges: Camera, clipAnchor to the nearest lattice points
  graph.addEdge('14', `${smolCamera}`, cameraList[smolCamera]);
  graph.addEdge('15', `${smolAnchor}`, anchorList[smolAnchor]);

  const targetPositions = PointsOnALine3D({ p0: orbit_controls_target, p1: locatorPosition, count: density });

  let points: Vector3[] = [];

  const dijkstra = graph.Dijkstra('14', '15');

  //Sort results by the # of lattice points between camera and clipAnchor
  switch (dijkstra.length - 2) {
    case 1:
      //only one control point, single quadractic curve works
      const curve1 = new QuadraticBezierCurve3(cameraPosition, lattice[Number(dijkstra[1])], anchorPosition);

      points = curve1.getPoints(density);
      break;
    case 2:
      //two control points, single cubic curve
      const curve2 = new CubicBezierCurve3(
        cameraPosition,
        lattice[Number(dijkstra[1])],
        lattice[Number(dijkstra[2])],
        anchorPosition,
      );

      points = curve2.getPoints(density);
      break;
    case 3:
      //three control points - two cubic curves made, sharing influence. Curves are averaged
      const curve30 = new CubicBezierCurve3(
        cameraPosition,
        lattice[Number(dijkstra[1])],
        lattice[Number(dijkstra[2])],
        anchorPosition,
      );

      const curve31 = new CubicBezierCurve3(
        cameraPosition,
        lattice[Number(dijkstra[2])],
        lattice[Number(dijkstra[3])],
        anchorPosition,
      );

      const points30 = curve30.getPoints(density);
      const points31 = curve31.getPoints(density);

      for (let i = 0; i < density; i++) {
        points.push(
          new Vector3(
            (points30[i].x + points31[i].x) / 2,
            (points30[i].y + points31[i].y) / 2,
            (points30[i].z + points31[i].z) / 2,
          ),
        );
      }
      break;
    case 4:
      //four control points - three cubic curves made, sharing influence. Curves are averaged
      const curve40 = new CubicBezierCurve3(
        cameraPosition,
        lattice[Number(dijkstra[1])],
        lattice[Number(dijkstra[2])],
        anchorPosition,
      );

      const curve41 = new CubicBezierCurve3(
        cameraPosition,
        lattice[Number(dijkstra[2])],
        lattice[Number(dijkstra[3])],
        anchorPosition,
      );

      const curve42 = new CubicBezierCurve3(
        cameraPosition,
        lattice[Number(dijkstra[3])],
        lattice[Number(dijkstra[4])],
        anchorPosition,
      );

      const points40 = curve40.getPoints(density);
      const points41 = curve41.getPoints(density);
      const points42 = curve42.getPoints(density);

      for (let i = 0; i < density; i++) {
        points.push(
          new Vector3(
            (points40[i].x + points41[i].x + points42[i].x) / 3,
            (points40[i].y + points41[i].y + points42[i].y) / 3,
            (points40[i].z + points41[i].z + points42[i].z) / 3,
          ),
        );
      }
      break;
    default:
      console.error('Dijkstra Error');
      break;
  }

  return { points, targetPositions };
};

export const creatGraphTemplate = (latticeLocal: any) => {
  return [
    ['0', '1', Distance3D({ p0: latticeLocal[0], p1: latticeLocal[1] })],
    ['0', '3', Distance3D({ p0: latticeLocal[0], p1: latticeLocal[3] })],
    ['0', '4', Distance3D({ p0: latticeLocal[0], p1: latticeLocal[4] })],
    ['0', '8', Distance3D({ p0: latticeLocal[0], p1: latticeLocal[8] })],
    ['0', '11', Distance3D({ p0: latticeLocal[0], p1: latticeLocal[11] })],
    ['0', '12', Distance3D({ p0: latticeLocal[0], p1: latticeLocal[12] })],
    ['1', '2', Distance3D({ p0: latticeLocal[1], p1: latticeLocal[2] })],
    ['1', '5', Distance3D({ p0: latticeLocal[1], p1: latticeLocal[5] })],
    ['1', '8', Distance3D({ p0: latticeLocal[1], p1: latticeLocal[8] })],
    ['1', '9', Distance3D({ p0: latticeLocal[1], p1: latticeLocal[9] })],
    ['1', '12', Distance3D({ p0: latticeLocal[1], p1: latticeLocal[12] })],
    ['2', '3', Distance3D({ p0: latticeLocal[2], p1: latticeLocal[3] })],
    ['2', '6', Distance3D({ p0: latticeLocal[2], p1: latticeLocal[6] })],
    ['2', '9', Distance3D({ p0: latticeLocal[2], p1: latticeLocal[9] })],
    ['2', '10', Distance3D({ p0: latticeLocal[2], p1: latticeLocal[10] })],
    ['2', '12', Distance3D({ p0: latticeLocal[2], p1: latticeLocal[12] })],
    ['3', '7', Distance3D({ p0: latticeLocal[3], p1: latticeLocal[7] })],
    ['3', '10', Distance3D({ p0: latticeLocal[3], p1: latticeLocal[10] })],
    ['3', '11', Distance3D({ p0: latticeLocal[3], p1: latticeLocal[11] })],
    ['3', '12', Distance3D({ p0: latticeLocal[3], p1: latticeLocal[12] })],
    ['4', '5', Distance3D({ p0: latticeLocal[4], p1: latticeLocal[5] })],
    ['4', '7', Distance3D({ p0: latticeLocal[4], p1: latticeLocal[7] })],
    ['4', '8', Distance3D({ p0: latticeLocal[4], p1: latticeLocal[8] })],
    ['4', '11', Distance3D({ p0: latticeLocal[4], p1: latticeLocal[11] })],
    ['4', '13', Distance3D({ p0: latticeLocal[4], p1: latticeLocal[13] })],
    ['5', '6', Distance3D({ p0: latticeLocal[5], p1: latticeLocal[6] })],
    ['5', '8', Distance3D({ p0: latticeLocal[5], p1: latticeLocal[8] })],
    ['5', '9', Distance3D({ p0: latticeLocal[5], p1: latticeLocal[9] })],
    ['5', '13', Distance3D({ p0: latticeLocal[5], p1: latticeLocal[13] })],
    ['6', '7', Distance3D({ p0: latticeLocal[6], p1: latticeLocal[7] })],
    ['6', '9', Distance3D({ p0: latticeLocal[6], p1: latticeLocal[9] })],
    ['6', '10', Distance3D({ p0: latticeLocal[6], p1: latticeLocal[10] })],
    ['6', '13', Distance3D({ p0: latticeLocal[6], p1: latticeLocal[13] })],
    ['7', '10', Distance3D({ p0: latticeLocal[7], p1: latticeLocal[10] })],
    ['7', '11', Distance3D({ p0: latticeLocal[7], p1: latticeLocal[11] })],
    ['7', '13', Distance3D({ p0: latticeLocal[7], p1: latticeLocal[13] })],
  ];
};

export const buildBounds = (boundingBox: Box3) => {
  let bounds = [
    new Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z),
    new Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z),
    new Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z),
    new Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z),
    new Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z),
    new Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z),
    new Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z),
    new Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z),
  ];

  bounds.push(
    Midpoint3D({
      p0: Midpoint3D({ p0: bounds[0], p1: bounds[1] }),
      p1: Midpoint3D({ p0: bounds[4], p1: bounds[5] }),
    }),
  );

  bounds.push(
    Midpoint3D({
      p0: Midpoint3D({ p0: bounds[1], p1: bounds[2] }),
      p1: Midpoint3D({ p0: bounds[5], p1: bounds[6] }),
    }),
  );

  bounds.push(
    Midpoint3D({
      p0: Midpoint3D({ p0: bounds[2], p1: bounds[3] }),
      p1: Midpoint3D({ p0: bounds[6], p1: bounds[7] }),
    }),
  );

  bounds.push(
    Midpoint3D({
      p0: Midpoint3D({ p0: bounds[0], p1: bounds[3] }),
      p1: Midpoint3D({ p0: bounds[4], p1: bounds[7] }),
    }),
  );

  bounds.push(
    Midpoint3D({
      p0: Midpoint3D({ p0: bounds[0], p1: bounds[3] }),
      p1: Midpoint3D({ p0: bounds[1], p1: bounds[2] }),
    }),
  );

  bounds.push(
    Midpoint3D({
      p0: Midpoint3D({ p0: bounds[4], p1: bounds[7] }),
      p1: Midpoint3D({ p0: bounds[5], p1: bounds[6] }),
    }),
  );

  return bounds;
};

export const buildRulers = (rulers_data: any, model: Model) => {
  let rulers = [];

  if (!rulers_data) return;

  const style_config = rulers_data.store?.style_config; // use store styles first

  const halfWidth = model.size.x / 2;
  const halfHeight = model.size.y / 2;
  const halfLength = model.size.z / 2;

  // if variant has style config overwrite style_config store settings with variant settings
  if (rulers_data.variant?.style_config) {
    const variant_array = Object.keys(rulers_data.variant?.style_config);
    variant_array.map((x) => (style_config[x] = rulers_data.variant?.style_config[x]));
  }

  // console.log('rulers_data:::', rulers_data)

  let auto_status = rulers_data.store?.auto_status;

  if (rulers_data.variant?.auto_status) auto_status = rulers_data.variant?.auto_status;

  let auto_status_text_override = rulers_data.store?.auto_status_text_override || [];

  if (rulers_data.variant?.auto_status_text_override)
    auto_status_text_override = rulers_data.variant?.auto_status_text_override;


  // width (x)
  if (auto_status[0] !== null) {
    const middle = new Vector3(
      auto_status[0][0] * halfWidth,
      auto_status[0][1] * halfHeight,
      auto_status[0][2] * halfLength,
    );

    const a = new Vector3(middle.x - halfWidth, middle.y, middle.z);
    const b = new Vector3(middle.x + halfWidth, middle.y, middle.z);

    if (auto_status_text_override[0] !== null) {
      rulers.push([a, b, style_config, auto_status_text_override[0]]);
    } else {
      rulers.push([a, b, style_config]);
    }
  }

  // height (y)
  if (auto_status[1] !== null) {
    const middle = new Vector3(
      auto_status[1][0] * halfWidth,
      auto_status[1][1] * halfHeight,
      auto_status[1][2] * halfLength,
    );

    const a = new Vector3(middle.x, middle.y - halfHeight, middle.z);
    const b = new Vector3(middle.x, middle.y + halfHeight, middle.z);

    if (auto_status_text_override[1] !== null) {
      rulers.push([a, b, style_config, auto_status_text_override[1]]);
    } else {
      rulers.push([a, b, style_config]);
    }
  }

  // depth (z)
  if (auto_status[2] !== null) {
    const middle = new Vector3(
      auto_status[2][0] * halfWidth,
      auto_status[2][1] * halfHeight,
      auto_status[2][2] * halfLength,
    );

    const a = new Vector3(middle.x, middle.y, middle.z - halfLength);
    const b = new Vector3(middle.x, middle.y, middle.z + halfLength);

    if (auto_status_text_override[2] !== null) {
      rulers.push([a, b, style_config, auto_status_text_override[2]]);
    } else {
      rulers.push([a, b, style_config]);
    }
  }


  const variant_custom_rulers = rulers_data.variant?.custom_rulers ? rulers_data.variant?.custom_rulers : null;

  if (variant_custom_rulers !== null) {
    variant_custom_rulers.forEach((variant_custom_ruler: any) => {
      const a = new Vector3(variant_custom_ruler.a[0], variant_custom_ruler.a[1], variant_custom_ruler.a[2]);
      const b = new Vector3(variant_custom_ruler.b[0], variant_custom_ruler.b[1], variant_custom_ruler.b[2]);

      const custom_style_config = { ...style_config };

      if (variant_custom_ruler?.style_config) {
        const ruler_array = Object.keys(variant_custom_ruler?.style_config);
        ruler_array.map((x) => (custom_style_config[x] = variant_custom_ruler?.style_config[x]));
      }

      if (custom_style_config.text_override) {
        rulers.push([a, b, custom_style_config, custom_style_config.text_override]);
      } else {
        rulers.push([a, b, custom_style_config]);
      }
    });
  }

  return rulers;
};

/*
* Get Delivery Target Information and Load in Textures / Geo
*/
export const getDt = async (payload: {
  mList?: MList;
  model_3d_id?: string;
  store_id?: string;
  variant_type?: string;
}) => {

  const {
    mList,
    model_3d_id,
    store_id,
    variant_type
  } = payload;

  if (!store_id) return;

  if (!mList) return;

  if (!model_3d_id) return;

  const mItem = mList[model_3d_id];

  if (!mItem) return;

  const { geo_type = 0 } = mItem;

  // NOTES: DETERMINE LOADER TYPE (geo_type)
  const getDtFunc = (geo_type === 1) ? getDtAnimation :
    (geo_type === 2) ? getDtGlbStatic :
      (geo_type === 5) ? getDtDraco :
        getDtStatic;

  const { group, textures } = await getDtFunc({
    mItem,
    store_id,
    variant_type
  });

  return {
    mItem: { ...mItem, id: model_3d_id },
    group,
    textures
  };
};

// Satic FBX Loader (geo_type === 0)
const getDtStatic = async (payload: {
  mItem: MItem;
  store_id: string,
  variant_type?: string;
}) => {
  const { mItem, store_id, variant_type } = payload;

  const {
    bundle = '',
    geo = '',
    materials = []
  } = mItem;

  let BUCKET_URL: string;

  if (variant_type === 'ghost') {
    BUCKET_URL = ghostUrlAr;
  } else {
    BUCKET_URL = modelUrlAr;
  }

  const fbxLoader = new FBXLoader();

  const group = await fbxLoader.loadAsync(`${BUCKET_URL}/${store_id}/${bundle}/${geo}.fbx`);

  const textures = await Promise.all(
    materials.map(async (mesh) => {

      const currMaterial = mesh;
      const currChannel = currMaterial['channels'];

      const {
        baseColorTextureUrl,
        metalnessTextureUrl,
        normalTextureUrl,
        roughnessTextureUrl,
        ambientOcclusionTextureUrl,
        emissiveTextureUrl,
        opacityTextureUrl,
        transmissionTextureUrl
      } = buildMaterialTextureUrl(BUCKET_URL, store_id, bundle, currMaterial, currChannel);

      const textureLoader = new TextureLoader();

      const [
        baseColor,
        metalness,
        normal,
        roughness,
        ambientOcclussion,
        emissive,
        opacity,
        transmission
      ] = await Promise.all([
        baseColorTextureUrl !== 'NONE' ? textureLoader.loadAsync(baseColorTextureUrl) : undefined,
        metalnessTextureUrl !== 'NONE' ? textureLoader.loadAsync(metalnessTextureUrl) : undefined,
        normalTextureUrl !== 'NONE' ? textureLoader.loadAsync(normalTextureUrl) : undefined,
        roughnessTextureUrl !== 'NONE' ? textureLoader.loadAsync(roughnessTextureUrl) : undefined,
        ambientOcclusionTextureUrl !== 'NONE' ? textureLoader.loadAsync(ambientOcclusionTextureUrl) : undefined,
        emissiveTextureUrl !== 'NONE' ? textureLoader.loadAsync(emissiveTextureUrl) : undefined,
        opacityTextureUrl !== 'NONE' ? textureLoader.loadAsync(opacityTextureUrl) : undefined,
        transmissionTextureUrl !== 'NONE' ? textureLoader.loadAsync(transmissionTextureUrl) : undefined
      ]);

      return { baseColor, metalness, normal, roughness, ambientOcclussion, emissive, opacity, transmission };

    })
  );

  return { group, textures };
};

// Packed Animation GLB Loader (geo_type === 1)
const getDtAnimation = async (payload: {
  store_id: string,
  // mItem: MItem;
  // variant_type?: string;
}) => {

  // const { mItem, store_id, variant_type } = payload;

  // const {
  //   bundle = '',
  //   geo = '',
  //   materials = []
  // } = mItem;

  // let BUCKET_URL: string;

  // if (variant_type === 'ghost') {
  //   BUCKET_URL = ghostUrlAr;
  // } else {
  //   BUCKET_URL = modelUrlAr;
  // }

  // const glbLoader = new GLTFLoader();

  // const group = await glbLoader.loadAsync(`${BUCKET_URL}/${store_id}/${bundle}/${geo}.glb`);

  // let mixer = new AnimationMixer(group.scene);
  // console.log('mixer', mixer)

  // Extract Animations and Glb Model
  // Store in Zustand Load in useAnimation()
  // Store Textures in Array
  // Tie Animations to POIs (Extract list from group.animations) each index name tied to POI

  // console.log('group', group)

  return { group: undefined, textures: [] };
};

// Satic GLB Loader (geo_type === 2)
const getDtGlbStatic = async (payload: {
  store_id: string
}) => {

  return { group: undefined, textures: [] };
};

// Satic Draco KTX2 Loader (geo_type === 5)
export const getDtDraco = async (payload: {
  mItem?: MItem;
  model_3d_id?: string;
  store_id?: string;
  variant_type?: string;
}) => {

  const { mItem, store_id, variant_type } = payload;

  // const {
  //   bundle = '',
  //   geo = '',
  //   materials = []
  // } = mItem;

  let BUCKET_URL: string;

  if (variant_type === 'ghost') {
    BUCKET_URL = ghostUrlAr;
  } else {
    BUCKET_URL = modelUrlAr;
  }

  const gltfDracoLoader = new GLTFLoader()
  const dracoLoader = new DRACOLoader()
  dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
  dracoLoader.preload();
  gltfDracoLoader.setDRACOLoader(dracoLoader);

  const initGroup = await gltfDracoLoader.loadAsync(`${BUCKET_URL}/${store_id}/${mItem?.bundle}/${mItem?.geo}.glb`);

  const group = initGroup.scene.children[0].children[0]

  const renderer = new WebGLRenderer();

  const ktx2Loader = new KTX2Loader()
    // teehee, needs to be adjusted with the current three version 
    .setTranscoderPath(`https://unpkg.com/three@0.165.x/examples/jsm/libs/basis/`).detectSupport(renderer);

  const textures = await Promise.all(
    mItem?.materials?.map(async (mesh) => {

      const currMaterial = mesh;
      const currChannel = currMaterial['channels'];

      const {
        baseColorTextureUrl,
        metalnessTextureUrl,
        normalTextureUrl,
        roughnessTextureUrl,
        ambientOcclusionTextureUrl,
        emissiveTextureUrl,
        opacityTextureUrl,
        transmissionTextureUrl
      } = buildMaterialTextureUrl(BUCKET_URL, store_id, mItem?.bundle, currMaterial, currChannel);

      const [
        baseColor,
        metalness,
        normal,
        roughness,
        ambientOcclussion,
        emissive,
        opacity,
        transmission
      ] = await Promise.all([
        baseColorTextureUrl !== 'NONE' ? ktx2Loader.loadAsync(baseColorTextureUrl) : undefined,
        metalnessTextureUrl !== 'NONE' ? ktx2Loader.loadAsync(metalnessTextureUrl) : undefined,
        normalTextureUrl !== 'NONE' ? ktx2Loader.loadAsync(normalTextureUrl) : undefined,
        roughnessTextureUrl !== 'NONE' ? ktx2Loader.loadAsync(roughnessTextureUrl) : undefined,
        ambientOcclusionTextureUrl !== 'NONE' ? ktx2Loader.loadAsync(ambientOcclusionTextureUrl) : undefined,
        emissiveTextureUrl !== 'NONE' ? ktx2Loader.loadAsync(emissiveTextureUrl) : undefined,
        opacityTextureUrl !== 'NONE' ? ktx2Loader.loadAsync(opacityTextureUrl) : undefined,
        transmissionTextureUrl !== 'NONE' ? ktx2Loader.loadAsync(transmissionTextureUrl) : undefined
      ]);

      return { baseColor, metalness, normal, roughness, ambientOcclussion, emissive, opacity, transmission };

    }) ?? []
  );

  return { group, textures };
}


const buildMaterialTextureUrl = (BUCKET_URL: string, store_id: any, bundle: any, currMaterial: any, currChannel: any) => {
  // (GUIDE)
  // -1 === jpg
  // -2 === png
  // -3 === ktx2
  // 0 - 5 === values aka VBC

  let baseColorTextureUrl; let metalnessTextureUrl; let normalTextureUrl;
  let roughnessTextureUrl; let ambientOcclusionTextureUrl;
  let emissiveTextureUrl; let opacityTextureUrl; let transmissionTextureUrl;

  if (currChannel.base_color_map < 0) {
    baseColorTextureUrl = `${BUCKET_URL}/${store_id}/${bundle}/${currMaterial.name}_color_${currChannel.base_color_name}.${currChannel.base_color_map === -1 ? 'jpg' : currChannel.base_color_map === -3 ? 'ktx2' : 'png'}`;
  } else {
    baseColorTextureUrl = 'NONE'; // No Map Exists
  }

  // Metalness Texture URL
  if (currChannel.metalness < 0) {
    metalnessTextureUrl = `${BUCKET_URL}/${store_id}/${bundle}/${currMaterial.name}_metal.${currChannel.metalness === -1 ? 'jpg' : currChannel.metalness === -3 ? 'ktx2' : 'png'}`;
  } else {
    metalnessTextureUrl = 'NONE'; // No Map Exists
  }

  // Normal Texture URL
  if (currChannel.normal < 0) {
    normalTextureUrl = `${BUCKET_URL}/${store_id}/${bundle}/${currMaterial.name}_normal.${currChannel.normal === -1 ? 'jpg' : currChannel.normal === -3 ? 'ktx2' : 'png'}`;
  } else {
    normalTextureUrl = 'NONE'; // No Map Exists
  }

  // Metalness Texture URL
  if (currChannel.roughness < 0) {
    roughnessTextureUrl = `${BUCKET_URL}/${store_id}/${bundle}/${currMaterial.name}_rough.${currChannel.roughness === -1 ? 'jpg' : currChannel.roughness === -3 ? 'ktx2' : 'png'}`;
  } else {
    roughnessTextureUrl = 'NONE'; // No Map Exists
  }

  // Ambient Occlusion Texture URL
  if (currChannel.ambient_occlusion < 0) {
    ambientOcclusionTextureUrl = `${BUCKET_URL}/${store_id}/${bundle}/${currMaterial.name}_ao.${currChannel.ambient_occlusion === -1 ? 'jpg' : currChannel.ambient_occlusion === -3 ? 'ktx2' : 'png'}`;
  } else {
    ambientOcclusionTextureUrl = 'NONE'; // No Map Exists
  }

  // Emissive Texture URL
  if (currChannel.emissive_map < 0) {
    emissiveTextureUrl = `${BUCKET_URL}/${store_id}/${bundle}/${currMaterial.name}_emissive.${currChannel.emissive_map === -1 ? 'jpg' : currChannel.emissive_map === -3 ? 'ktx2' : 'png'}`;
  } else {
    emissiveTextureUrl = 'NONE'; // No Map Exists
  }

  // Opacity Texture URL
  if (currChannel.opacity < 0) {
    opacityTextureUrl = `${BUCKET_URL}/${store_id}/${bundle}/${currMaterial.name}_opacity.${currChannel.opacity === -1 ? 'jpg' : currChannel.opacity === -3 ? 'ktx2' : 'png'}`;
  } else {
    opacityTextureUrl = 'NONE'; // No Map Exists
  }

  // Transmission Texture URL
  if (currChannel.transmission < 0) {
    transmissionTextureUrl = `${BUCKET_URL}/${store_id}/${bundle}/${currMaterial.name}_transmission.${currChannel.transmission === -1 ? 'jpg' : currChannel.transmission === -3 ? 'ktx2' : 'png'}`;
  } else {
    transmissionTextureUrl = 'NONE'; // No Map Exists
  }

  return {
    baseColorTextureUrl,
    metalnessTextureUrl,
    normalTextureUrl,
    roughnessTextureUrl,
    ambientOcclusionTextureUrl,
    emissiveTextureUrl,
    opacityTextureUrl,
    transmissionTextureUrl
  }
};