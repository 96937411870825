import { ExitToApp, Help, Straighten, KeyboardBackspace } from '@mui/icons-material';

import { Action, HelpControls } from 'components/help-controls';
import { useStore, useVItem, useMItem } from 'store';
import { DrawerProps } from './Drawer';
import { ViewerLayoutContextType } from './ViewerLayoutContext';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { sendAnalyticsData } from 'utils/handleAnalytics';
import { buildExitUrl } from 'utils/helpers';

type UseGenerateLeftDrawerProps = {
  viewerLayoutContext?: ViewerLayoutContextType;
};

export const useGenerateLeftDrawer = ({
  viewerLayoutContext,
}: UseGenerateLeftDrawerProps): {
  visibleLeftDrawer?: boolean;
  leftDrawerProps?: DrawerProps;
} => {

  // Params
  const origin = useStore((state) => state.params.origin);
  const ecommerce_session_id = useStore((state) => state.params.ecommerce_session_id);
  const billboards_enabled = useStore((state) => state.scene_controls.billboards_enabled);
  const levar_session_id = useStore((state) => state.params.levar_session_id);
  const levar_pixel_id = useStore((state) => state.params.levar_pixel_id);

  const {
    levar_exit_url = '', referer = '', id: variant_id = '',
  } = useVItem() || {};

  const {
    variant_ruler_settings: { rulers_enabled = false } = {},
  } = useMItem() || {};

  const viewerPageExitAnalytics = useAnalyticsData({ event_type: 'viewer_page_exit' });

  const actions: Action[] = [];

  const { visibleHelp = false, updateViewerLayout = () => { } } = viewerLayoutContext || {};

  // console.log("billboards_enabled", billboards_enabled)

  /**
   * Help Button
   */
  actions.push({
    icon: <Help />,
    tooltipTitle: 'Help',
    onClick: () => {
      updateViewerLayout({ visibleHelp: !visibleHelp });
    },
  });


  /**
   * Ruler button
   */
  if (rulers_enabled) {
    actions.push({
      icon: <Straighten sx={{ transform: 'rotate(-45deg)' }} />,
      tooltipTitle: 'Toggle ruler',
      onClick: () => {
        useStore.setState((state) => ({
          scene_controls: {
            ...state.scene_controls,
            rulers_enabled: !(useStore.getState().scene_controls.rulers_enabled || false),
          },
        }));
      },
    });
  }


  /**
   * Spotlight position reset
   */
  if (billboards_enabled) {
    actions.push({
      icon: <KeyboardBackspace />,
      tooltipTitle: 'Refresh Spotlight',
      onClick: () => {
        // TODO Ask MONTY can change to onRefresh()
        useStore.setState((state) => ({
          scene_controls: {
            ...state.scene_controls,
            active_board: 0,
          },
          billboard_settings: {
            ...state.billboard_settings,
            billboard_listener_click: true,
          },
        }));
      },
    });
  }


  /**
   * Exit button
   */
  if (origin && origin !== 'static' && referer) {

    const _handleViewerClose = async () => {

      // Custom Exit URL (levar_exit_url)
      if (levar_exit_url && levar_exit_url.length > 0) {
        await sendAnalyticsData(viewerPageExitAnalytics);
        window.location.assign(levar_exit_url);

      } else if (origin === 'external') {
        // External normal referrer redirect
        const exitURL = buildExitUrl({ referer, variant_id, levar_session_id, levar_pixel_id, ecommerce_session_id });
        await sendAnalyticsData(viewerPageExitAnalytics);
        window.location.assign(exitURL);

      } else if (origin === 'shopify' || origin === 'bigc') {
        // Exit button when viewer is open on PDP page
        window.parent.postMessage('levarViewerClose', '*');
      }
    };

    actions.push({
      icon: origin === 'external' ? <ExitToApp /> : <ExitToApp />,
      tooltipTitle: origin === 'external' ? 'To Product Store' : 'Exit',
      onClick: _handleViewerClose,
    });
  }

  return {
    visibleLeftDrawer: true,
    leftDrawerProps: {
      containerProps: { sx: { pl: 2, pt: 2 } },
      contentComponent: HelpControls,
      contentComponentProps: {
        actions,
      },
    },
  };
};
