import React from 'react';

import { useVItem } from 'store';
import { OptionSelector } from './OptionSelector';
import { useSwitchVariantContext } from './SwitchVariantContext';

export const VariantOptionSelector = () => {
  const {
    options: _options,
    selectedOptionId,
    updateSwitchVariantContext,
    subOptions,
    values,
  } = useSwitchVariantContext();
  const { id: variant_id = '' } = useVItem() || {};

  const options = _options.map(({ id: optionId, label }) => {
    const activeValues = values[variant_id];

    const activeSubOption = (subOptions[optionId] || []).find(({ id: subOptionId }) => {
      return (
        subOptionId !== undefined && activeValues[optionId] !== undefined && subOptionId === activeValues[optionId]
      );
    });

    return { id: optionId, label: `${label}: ${activeSubOption ? activeSubOption.label : '-'}` };
  });

  return (
    <OptionSelector
      options={options}
      selectedOptionId={selectedOptionId}
      onMenuItem={(selectedOptionId) => {
        updateSwitchVariantContext({ selectedOptionId });
      }}
    />
  );
};
