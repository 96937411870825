import React from 'react';
import { Backdrop, BackdropProps, Icon, Typography, useTheme } from '@mui/material';
import { PuffLoader } from 'react-spinners';

import { ReactComponent as ArLogoSvg } from 'assets/icons/ar_logo.svg';

type LoadingVariantProps = Omit<BackdropProps, 'open'> & {
  loading?: boolean;
  text?: string;
};

export const LoadingVariant = ({ loading = false, text = '', sx, ...restSx }: LoadingVariantProps) => {
  const theme = useTheme();

  const centerPositionSx: any = { position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' };

  return (
    <Backdrop
      {...restSx}
      open={loading}
      transitionDuration={{ appear: 0, enter: 0, exit: 400 }}
      sx={{
        color: theme.palette.primary.main,
        bgcolor: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 10,
        flexDirection: 'column',
        position: 'absolute',
        ...sx,
      }}
    >
      <Icon sx={{ textAlign: 'center', height: 112, width: 112, position: 'relative' }}>
        <ArLogoSvg style={{ ...centerPositionSx, width: 30, zIndex: 9, fill: theme.palette.primary.main }} />
        <PuffLoader color={theme.palette.primary.main} size={112} />
      </Icon>

      <Typography sx={{ mt: 2 }} color="textSecondary">
        {text}
      </Typography>
    </Backdrop>
  );
};
