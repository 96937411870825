import { DrawerProps } from './Drawer';

export const useGenerateRightDrawer = (): {
  visibleRightDrawer?: boolean;
  rightDrawerProps?: DrawerProps;
} => {
  const visibleRightDrawer = false;
  const rightDrawerProps: DrawerProps = {};

  return { visibleRightDrawer, rightDrawerProps };
};
