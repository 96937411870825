import React, { useEffect, useState } from 'react';
import { Box, Collapse, SpeedDialIcon } from '@mui/material';
import { Menu, Close } from '@mui/icons-material';
import { TransitionGroup } from 'react-transition-group';
import {
  SizedSpeedDial,
  SizedSpeedDialAction,
  SizedSpeedDialActionProps,
  SizedSpeedDialProps,
} from 'components/@styled-mui';
import { useStore, useMItem } from 'store';

export type Control = Omit<SizedSpeedDialActionProps, 'id' | 'onClick'> & {
  id: string;
  onClick?: () => void;
  contentComponent?: any;
  contentComponentProps?: any;
};

export type ViewerBottomControlsProps = {
  speedDialProps?: Omit<SizedSpeedDialProps, 'ariaLabel'>;
  controls?: Control[];
};

export const ViewerBottomControls = ({ speedDialProps, controls = [] }: ViewerBottomControlsProps) => {
  const [{ open, activeId, preventOpen = false }, setState] = useState<{
    open: boolean;
    activeId: string | null;
    preventOpen?: boolean;
  }>({
    open: false,
    activeId: null,
  });
  const { viewer_variant_switch = '' } = useMItem() || {};
  const billboards_enabled = useStore((state) => state.scene_controls.billboards_enabled);
  const billboards_drawer_enabled = useStore((state) => state.scene_controls.billboards_drawer_enabled);
  const active_board = useStore((state) => state.scene_controls.active_board);
  const active_board_changed = useStore((state) => state.scene_controls.active_board_changed);

  useEffect(() => {
    // should open DRAWER if poi clicked and drawer closed
    if (active_board !== undefined && billboards_enabled && active_board_changed && billboards_drawer_enabled) {
      setState({ open: !preventOpen, activeId: 'spotlight' });
    }
    // eslint-disable-next-line
  }, [active_board, billboards_enabled, active_board_changed]);

  useEffect(() => {
    useStore.setState((state) => ({ isOffset: activeId === 'video' ? false : open }));
  }, [open, activeId]);

  useEffect(() => {
    // OPENS DRAWER if billboards enabled on load
    // if (billboards_enabled) {
    if (billboards_enabled && billboards_drawer_enabled) {
      setState({ open: true, activeId: 'spotlight' });
    } else if (viewer_variant_switch) {
      setState({ open: true, activeId: 'palette' });
    }
  }, [billboards_enabled, viewer_variant_switch, billboards_drawer_enabled]);


  const onBillboardReset = () => {
    if (!open || active_board === 0) return;

    useStore.setState((state) => ({
      scene_controls: {
        ...state.scene_controls,
        active_board: 0,
      },
      billboard_settings: {
        ...state.billboard_settings,
        billboard_listener_click: true,
      },
    }));
    setState((prev) => ({ ...prev, preventOpen: true }));
  };


  return (
    <Box component="div">
      <Box component="div" sx={{ p: 2 }}>
        <SizedSpeedDial
          ariaLabel="Model controls"
          icon={<SpeedDialIcon icon={<Menu />} openIcon={<Close />} />}
          direction="right"
          open={open}
          onClick={() => {
            setState((prev) => ({
              ...prev,
              open: !open,
              activeId: prev.activeId ? prev.activeId : controls.length > 0 ? controls[0].id : null,
            }));
            onBillboardReset();
          }}
          {...speedDialProps}
        >
          {controls.map(({ id, onClick = () => { }, contentComponent, contentComponentProps, size, ...rest }, index) => (
            <SizedSpeedDialAction
              key={`model-control-${index}`}
              active={id === activeId}
              onClick={(e) => {
                e.stopPropagation();
                setState((prev) => ({ ...prev, activeId: id }));
                onClick();
              }}
              size={size ? size : speedDialProps?.size}
              direction="right"
              {...rest}
            />
          ))}
        </SizedSpeedDial>
      </Box>

      <Box component="div">
        <TransitionGroup>
          {controls.map(({ id, contentComponent: ContentComponent, contentComponentProps = {} }, index) => {
            return open && ContentComponent && activeId === id ? (
              <Collapse key={`model-control-panel-${index}`}>
                <Box
                  component="div"
                  sx={{
                    borderTop: (theme: any) => `1px solid ${theme.palette.divider}`,
                    bgcolor: (theme: any) => theme.palette.common.white,
                  }}
                >
                  <ContentComponent {...contentComponentProps} />
                </Box>
              </Collapse>
            ) : null;
          })}
        </TransitionGroup>
      </Box>
    </Box>
  );
};
