import React from 'react';
import { NoColorSpace, LinearFilter, NearestMipmapLinearFilter, Texture } from 'three';

export const Normal = ({ texture, maxAnisotropy }: { texture?: Texture; maxAnisotropy: any }) => {
  if (!texture) return null;

  // colorscience
  return (
    <texture
      attach="normalMap"
      {...texture}
      colorSpace={NoColorSpace}
      anisotropy={maxAnisotropy}
      minFilter={NearestMipmapLinearFilter}
      magFilter={LinearFilter}
      name="updated_levar_normal"
    />
  );
};
