import React, { useState } from 'react';
import { Grid, GridProps, SwitchProps, Typography, TypographyProps, Button } from '@mui/material';

import { IOSSwitch } from 'components/@styled-mui';
import { ArActivationDialog, QRCodePromptDialog, RedirectPromptDialog } from '../../dialogs';
import {
  setArBannerUrl,
  buildViewerFallback
} from 'utils/helpers';
import { modelUrlAr, ghostUrlAr } from 'configs';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { sendAnalyticsData } from 'utils/handleAnalytics';
import { useStore, useVItem, useMItem, useCheckCanAr } from 'store';

import ar_logo from 'assets/icons/ar_logo_white.svg';

export type SwitchSpaceProps = {
  gridContainerProps?: Omit<GridProps, 'container' | 'item'>;
  gridItemProps?: Omit<GridProps, 'container' | 'item'>;
  switchProps?: SwitchProps;
  typographyProps?: TypographyProps;
  contentComponent?: any;
  contentComponentProps?: any;
};

export const SwitchSpace = ({
  gridContainerProps = {},
  gridItemProps = {},
  switchProps = {},
  typographyProps = {},
  contentComponent: _contentComponent,
  contentComponentProps: _contentComponentProps,
}: SwitchSpaceProps) => {
  const [check, setCheck] = useState<boolean>(false);
  const [visibleQRCodePromptDialog, setVisibleQRCodePromptDialog] = useState<boolean>(false);
  const [visibleRedirectPromptDialog, setVisibleRedirectPromptDialog] = useState<boolean>(false);
  const [visibleArActivationDialog, setVisibleArActivationDialog] = useState<boolean>(false);
  const intent = useStore((state) => state.hrx.intent);
  const target = useStore((state) => state.hrx.target);

  const viewerButtonRef = React.useRef(null)

  /**
   * Query Parameters
   */
  const variant_type = useStore((state) => state.params.variant_type); //
  const view_in_space_off = useStore((state) => state.params.view_in_space_off); // Hide view in space / AR (Desktop && Mobile)
  const fullscreen = useStore((state) => state.params.fullscreen); // Fullscreen
  const hank_modal = useStore((state) => state.params.hank_modal); // Hank Activation
  const ar_activation = useStore((state) => state.params.ar_activation); // Hank Activation
  const billboards = useStore((state) => state.params.billboards); // Hank Activation
  const auto_ar_launch = useStore((state) => state.params.auto_ar_launch); // AR auto launch
  const param_type = useStore((state) => state.params.param_type); // first part of url ?=
  const ecommerce_session_id = useStore((state) => state.params.ecommerce_session_id);
  const levar_session_id = useStore((state) => state.params.levar_session_id);
  const levar_pixel_id = useStore((state) => state.params.levar_pixel_id);

  const status = useStore((state) => state.loading.status); // Context Loading Status
  const store_id = useStore((state) => state.store_id);
  const ar_cta_status = useStore((state) => state.store_settings.ar_experience.ar_cta_status);
  const ar_cta_type = useStore((state) => state.store_settings.ar_experience.ar_cta_type);
  const canAr = useCheckCanAr();

  const { id: variant_id = '' } = useVItem() || {};
  const vItem = useVItem(); // Variant Info
  const {
    ardt = '',
    bundle = '',
    model_3d_id = '',
    id: ghost_variant_id = '',
    model_type = '',
  } = useMItem() || {};
  const analyticsQrModalOpen = useAnalyticsData({ event_type: 'qr_modal_open' });
  const analyticsArLaunch = useAnalyticsData({ event_type: 'view_ar_launch' });

  // DESKTOP ACTIVATION
  const showQrCodePromptDialog = (e: any) => {
    e.preventDefault();
    setCheck(true);
    setVisibleQRCodePromptDialog(true);

    sendAnalyticsData(analyticsQrModalOpen);
  };

  /**
  * Activation Augmented Reality
  */
  const switchToAr = (e: any) => {

    setCheck(true);

    // Activate Redirect Modal
    if (intent === 'canQL' || intent === 'canSV') {
      setCheck(false);

      setVisibleRedirectPromptDialog(true);

      return;
    }

    // Activate HANK Modal (also works for deprecated ar_activation lsa)
    if ((hank_modal || ar_activation) && (intent === 'willQL' || intent === 'willSV')) {
      setCheck(false);

      setVisibleArActivationDialog(true);

      return;
    }

    // const interactiveModels = [
    //   'interactive_absolute_ground_0',
    //   'interactive_absolute_wall_0',
    //   'interactive_absolute_lighting_0'
    // ];

    // 8th Wall Experience
    // if ((interactiveModels.includes(model_type)) && (intent === 'willQL' || intent === 'willSV')) {

    // }

    if (target === 'iPadQL' || target === 'iPadNQL' || target === 'iPadLiteNQL') {
      setCheck(false);

      window.parent.postMessage('iPadExit', '*');
    }

    let anchor = document.getElementById('ar-link');

    if (!anchor) {
      anchor = document.createElement('a');
      anchor.setAttribute('id', 'ar-link');
      anchor.setAttribute('rel', 'ar');
      anchor.setAttribute('style', 'position: absolute; height: 0px; bottom: 0; left: 0;');

      let anchorImage = document.createElement('img');
      anchorImage.setAttribute('src', ar_logo)
      anchor.appendChild(anchorImage);

      document.body.appendChild(anchor); // Possible to remove dont need it (maybe with ar_cta_status though)
      // console.log('ar_cta_status', ar_cta_status)
      if (ar_cta_status && intent === 'willQL') {
        anchor.addEventListener(
          'message',
          async (event) => {
            if ((event as any).data === '_apple_ar_quicklook_button_tapped') {
              // QuickLook Banner Redirect Link
              const qlBannerLink = setArBannerUrl({ variant_id, vItem, variant_type, ar_cta_type });

              window.location.assign(qlBannerLink);
            }
          },
          false,
        );
      }
    }

    const updatedUrl = handleViewerUrl();
    // console.log('updatedUrl::', updatedUrl)

    anchor.setAttribute('href', updatedUrl);

    anchor.click();

    sendAnalyticsData(analyticsArLaunch);

    setCheck(false);
  };

  const onClick = canAr ? switchToAr : showQrCodePromptDialog;

  const handleViewerUrl = () => {
    if (!vItem) return '';
    let finalUrl = '';

    if (intent === 'willQL') {
      // IOS DT URL CREATE

      const dt = `${variant_type !== 'ghost' ? modelUrlAr : ghostUrlAr}/${store_id}/${bundle}/${ardt}.usdz`;
      const scalingOn = '#allowsContentScaling=0';

      // TODO: Update Actar.levar.io api to read off new Index
      const custom_cta = ar_cta_status ? `&custom=https://actar.levar.io/index.html/?${param_type}=${variant_id}&customHeight=small` : '';

      finalUrl = `${dt}${scalingOn}${custom_cta}`;
    } else if (intent === 'willSV') {
      // SCENE VIEWER DT URL CREATE
      const levar_intent = 'ar_preferred'; // Intent

      const fallbackParams = {
        variant_id,
        model_3d_id,
        ghost_variant_id,
        fullscreen,
        variant_type,
        ecommerce_session_id,
        levar_session_id,
        levar_pixel_id,
        billboards,
        hank_modal,
        ar_activation
      };

      const fallback_url = buildViewerFallback(fallbackParams);

      const dt = `${variant_type !== 'ghost' ? modelUrlAr : ghostUrlAr}/${store_id}/${bundle}/${ardt}.glb`; // GLB URL
      const sceneViewerUrl = `#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${fallback_url}`;

      if (ar_cta_status) {
        const title = vItem.product_title;
        const qlBannerLink = setArBannerUrl({ variant_id, vItem, variant_type, ar_cta_type });
        finalUrl = `intent://arvr.google.com/scene-viewer/1.1?file=${dt}&title=${title}&link=${qlBannerLink}&mode=${levar_intent}&resizable=false${sceneViewerUrl}end;`;
      } else {
        finalUrl = `intent://arvr.google.com/scene-viewer/1.1?file=${dt}&mode=${levar_intent}&resizable=false${sceneViewerUrl}end;`; // v 1.1
      }
    }

    return finalUrl;
  };

  const ContentComponent = _contentComponent ? _contentComponent : IOSSwitch;
  const contentComponentProps = _contentComponentProps
    ? { ..._contentComponentProps, onClick }
    : { ...switchProps, sx: { height: 24, width: 40, ...switchProps.sx }, checked: check, onClick };


  /**
  * Auto Launch AR Listener
  */
  const autoLaunchAR = React.useCallback(() => {
    if (intent !== 'willSV') {
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });

      switchToAr(clickEvent);
    }
    // eslint-disable-next-line
  }, []);


  React.useEffect(() => {
    if (canAr && auto_ar_launch && status === 'end') {
      autoLaunchAR()
    }
  }, [auto_ar_launch, canAr, status, autoLaunchAR, intent]);

  return (
    <>
      {_contentComponent ? (
        <ContentComponent {...contentComponentProps} />
      ) : (

        <Grid
          container
          spacing={2}
          {...gridContainerProps}
          sx={{ alignItems: 'center', width: 'fit-content', ...gridContainerProps.sx }}
        >

          {/* Mobile Launch Button */}
          {(canAr && !view_in_space_off) && (
            <Button
              variant="contained"
              {...contentComponentProps}
              sx={{ mt: 2 }}
              endIcon={<img src={ar_logo} alt="View in my space" style={{ width: 20, height: 20 }} />}
              ref={viewerButtonRef}
            >
              See in AR
            </Button>
          )}

          {/* Desktop Switch Button */}
          {(!canAr && !view_in_space_off) && (
            <>
              <Grid item {...gridItemProps}>
                <Typography {...typographyProps}>3D</Typography>
              </Grid>

              <Grid item {...gridItemProps}>
                <ContentComponent {...contentComponentProps} />
              </Grid>

              <Grid item {...gridItemProps}>
                <Typography {...typographyProps}>My Space</Typography>
              </Grid>
            </>
          )}

        </Grid>
      )}

      <QRCodePromptDialog
        open={visibleQRCodePromptDialog}
        onClose={() => {
          setVisibleQRCodePromptDialog(false);
          setCheck(false);
        }}
        model_3d_id={model_3d_id}
        ghost_variant_id={ghost_variant_id}
        variant_id={variant_id}
        variant_type={variant_type}
        fullscreen={fullscreen}
        hank_modal={hank_modal}
        model_type={model_type}
        ar_activation={ar_activation}
        billboards={billboards}
      />

      <RedirectPromptDialog
        open={visibleRedirectPromptDialog}
        onClose={() => {
          setVisibleRedirectPromptDialog(false);
          setCheck(false);
        }}
        model_3d_id={model_3d_id}
        ghost_variant_id={ghost_variant_id}
        variant_id={variant_id}
        variant_type={variant_type}
        fullscreen={fullscreen}
        hank_modal={hank_modal}
        model_type={model_type}
        ar_activation={ar_activation}
        billboards={billboards}
      />


      <ArActivationDialog
        open={visibleArActivationDialog}
        onClose={() => {
          setVisibleArActivationDialog(false);
          setCheck(false);
        }}
        intent={intent}
      />

    </>
  );
};
