import { useStore, useMItem } from 'store';
import {
  ghostUrlAr,
  modelUrlAr
} from 'configs';

type CameraPosition = [number, number, number];

export const useDTManagerAnimation = (): {
  bundle: string;
  camera_position?: CameraPosition;
  geo: string;
  materials: any;
  model_url: string;
  store_id: string;
} => {
  const store_id = useStore((state) => state.store_id);
  const variant_type = useStore((state) => state.params.variant_type);

  let model_url: string = '';

  if (variant_type === 'ghost') {
    model_url = ghostUrlAr;
  } else {
    model_url = modelUrlAr;
  }

  const {
    bundle = '',
    custom_spawn,
    geo = '',
    materials = '',
  } = useMItem() || {};

  return {
    bundle,
    camera_position: custom_spawn ? custom_spawn : undefined,
    geo,
    materials,
    model_url,
    store_id,
  };
};
