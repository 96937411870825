import { RulerSettings } from './store-settings';

export type SceneControls = {
  active_board?: number;
  billboards_enabled?: boolean;
  billboards_drawer_enabled?: boolean;
  controls_ui?: boolean;
  orbit_controls_enabled?: boolean;
  rulers_enabled: boolean;

  // TODO: confirm type
  rulers_visible?: any;

  rulers_data_sv?: RulerSettings | any;
  active_board_changed: boolean;
  orbit_controls_angles: number[];

  // if true, bounds will re-clip the object 
  // when the browser window is resized 
  bounds_should_observe: boolean;
};

export const scene_controls: SceneControls = {
  active_board: undefined,
  billboards_enabled: undefined,
  billboards_drawer_enabled: true,
  orbit_controls_enabled: true,
  rulers_enabled: false,
  rulers_data_sv: undefined,
  active_board_changed: false,
  orbit_controls_angles: [],
  bounds_should_observe: false
};
