import { createContext, useCallback, useEffect, useState } from 'react';
import { useProgress } from '@react-three/drei';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { sendAnalyticsData } from 'utils/handleAnalytics';
import { getDt } from 'utils/three_helpers';
import { useStore, useCheckCanAr } from 'store';
import {
  MItem,
  // MList
} from 'store/states/variants';

export const ModelContext = createContext(null);

export type ModelContextProviderProps = {
  children?: any;
};

export const ModelContextProvider = ({ children = null }: ModelContextProviderProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const model_loaded = useStore((state) => state.model_loaded);
  const mList = useStore((state) => state.mList); // Model List
  const variant_type = useStore((state) => state.params.variant_type); // GHOST_NEW
  const model_3d_id = useStore((state) => state.model_3d_id);
  const store_id = useStore((state) => state.store_id);
  const auto_ar_launch = useStore((state) => state.params.auto_ar_launch); // AR auto launch
  const intent = useStore((state) => state.hrx.intent); // check for willSV
  const canAr = useCheckCanAr();
  const analyticsModelLoaded = useAnalyticsData({ event_type: 'viewer_model_load' });
  const { progress } = useProgress();


  const initModel = useCallback(async () => {
    try {
      useStore.setState({
        loading: {
          status: 'app-layout',
          text: (canAr && auto_ar_launch && intent !== 'willSV') ? 'Launching AR...' : 'Configure Layout...'
        }
      });
      setLoading(true);

      const data = await getDt({
        mList,
        model_3d_id,
        store_id,
        variant_type
      });

      if (data) {
        const {
          group,
          textures,
          mItem
        } = data as { group: any; textures: any; mItem: MItem };

        // console.log("mItem", mItem)

        // console.log('****************Model Context*********************')

        useStore.setState((state) => ({
          ...state,
          // mItem: { ...mItem, id: model_3d_id }, // causing rerender for levar_ecommmerce_variant
          // model_3d_id: model_3d_id,
          auto_rotation: mItem?.auto_rotation, // auto rotation from model
          group,
          textures,
          loading: {
            status: 'model',
            text: (canAr && auto_ar_launch && intent !== 'willSV') ? 'Launching AR...' : 'Loading Model...'
          }
        }));
      }
    } catch (err) {
      console.error('Error at loading static fbx model: ', err);
      useStore.setState(() => ({ loading: { status: 'error', text: 'Loading model data has failed' } }));
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [store_id]);

  useEffect(() => {
    initModel();
  }, [initModel]);

  useEffect(() => {
    if (model_loaded && progress === 100) {
      console.log('model loaded')
      useStore.setState({ loading: { status: 'end', text: '' } });
      sendAnalyticsData(analyticsModelLoaded);
    }
    // eslint-disable-next-line
  }, [model_loaded, progress]);

  return <ModelContext.Provider value={null}>{loading ? null : children}</ModelContext.Provider>;
};
