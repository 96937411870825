export type ArExperience = {
  ar_cta_status: boolean;
  ar_cta_type: 'add_to_cart' | 'undefined';
};

export type MobileArButton = {
  button_color: string;
  button_text_color: string;
  button_text: string;
  button_position: string;
};

export type ViewerButton = {
  button_color: string;
  button_text_color: string;
  button_text: string;
  button_position: string;
};

export type DynamicTextSettings = {
  custom_text_status: boolean;
  mobile_ar_button?: MobileArButton;
  default_text_font: string;
  viewer_button?: ViewerButton;
};

export type RulerSettings = {
  auto_status?: [number, number, number][];
  auto_status_text_override?: any[];
  style_config?: {
    unit?: string;
    color?: string;
    text_size?: number;
    precision?: number;
    text_stroke_size?: number;
    text_color?: string;
    type?: string;
    text_stroke_color?: string;
  };
};

export type StoreSettings = {
  ar_experience: ArExperience;
  cLogo: string;
  custom_banner_url: string;
  dynamic_text_settings?: DynamicTextSettings;
  ga_id: string;
  klaviyo_id: string;
  ruler_settings?: RulerSettings;
  yotpo_id: string;
  store_ruler_settings?: RulerSettings;
  store_domain: string;
};

export const store_settings: StoreSettings = {
  ar_experience: {
    ar_cta_status: false,
    ar_cta_type: 'undefined',
  },
  cLogo: '',
  custom_banner_url: '',
  dynamic_text_settings: undefined,
  ga_id: '',
  klaviyo_id: '',
  ruler_settings: undefined,
  yotpo_id: '',
  store_domain: ''
};
