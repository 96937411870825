import React from 'react';
import { useThree } from '@react-three/fiber';
import { Box3, Vector3 } from 'three';

import {
  useMItem,
  // useVItem 
} from 'store';
import { Hover } from './Hover';
import { Turn, TurnCounter, TurnHold, TurnCounterHold } from './Turn';

/**
 * Manger, determines which clipless animations are present and what optional fields are passed
 * Returns the appropriate clipless animations
 * Only this parent file contains useThree or any direct reference to the 3DCanvas, the deliveryTarget reference is passed to all children
 */

export const Clipless = () => {
  const { clipless } = useMItem() || {};

  const scene = useThree((state) => state.scene); // causes rerender on screen change

  if (!clipless) {
    return null;
  }

  const deliveryTarget = scene.getObjectByName('deliveryTarget');

  if (!deliveryTarget) {
    return null;
  }

  //BEGIN HOVER
  let hover: any = {
    // status: false,
    height: 0,
    speed: 1,
    modelHeight: 0,
    totalHeight: 0,
    iterator: 0,
    distance: 0,
    //
    up: true,
  };

  const ModHover = (param: any, value: any) => {
    const modify: any = {
      height: () => (hover.height = value),
      speed: () => (hover.speed = value),
      modelHeight: () => (hover.modelHeight = value),
      totalHeight: () => (hover.totalHeight = value),
      iterator: () => (hover.iterator = value),
      distance: () => (hover.distance = value),
    };

    return modify[param]?.(value) ?? console.info('GFYS');
  };

  const ResolveHover = () => {
    hover.status = true;
    let boundingBox = new Box3().setFromObject(deliveryTarget);
    let size = boundingBox.getSize(new Vector3());
    hover.modelHeight = size.y; // Could also grab this value from state

    // resolve the total height the asset should hover (1/2 above middle, 1/2 below)
    clipless.hover.height
      ? ModHover('height', clipless.hover.height) && ModHover('totalHeight', hover.height * hover.modelHeight * 0.1)
      : (hover.totalHeight = hover.modelHeight * 0.1);

    /**
     * create impression of speed by varying the nuber of frames the object takes to traverse the total height
     * iterator is divided by two at the end because the asset starts in the middle of the screen, so it only needs to hover 1/2 total height up on the very first oscillation
     * The oscillation in ./Hover does not contain this division by 2
     */

    clipless.hover.speed
      ? ModHover('speed', clipless.hover.speed) &&
      ModHover('iterator', 60 / hover.speed) &&
      ModHover('distance', hover.totalHeight / hover.iterator) &&
      ModHover('iterator', hover.iterator / 2)
      : ModHover('iterator', 60 / hover.speed) &&
      ModHover('distance', hover.totalHeight / hover.iterator) &&
      ModHover('iterator', hover.iterator / 2);
  };
  //END HOVER

  //BEGIN TURN
  let turn = {
    type: '',
    speed: 1,
    counterClockwise: false,
    hold: 0,
    iterator: 0,
    //
    rotation: 0,
  };

  const ModTurn = (param: any, value: any) => {
    const modify: any = {
      type: () => (turn.type = value),
      speed: () => (turn.speed = value),
      counterClockwise: () => (turn.counterClockwise = value),
      hold: () => (turn.hold = value),
      iterator: () => (turn.iterator = value),
    };

    return modify[param]?.(value) ?? console.info('GFYS');
  };

  const ResolveTurn = () => {
    //resolve speed
    clipless.turn.speed && ModTurn('speed', clipless.turn.speed);

    //resolve direction
    clipless.turn.counterClockwise && ModTurn('counterClockwise', clipless.turn.counterClockwise);

    //resolve hold
    clipless.turn.hold && ModTurn('hold', clipless.turn.hold);

    //resolve turn table type
    !turn.counterClockwise && !turn.hold && ModTurn('type', 'turn');
    turn.counterClockwise && !turn.hold && ModTurn('type', 'turnCounter');
    !turn.counterClockwise && turn.hold && ModTurn('type', 'turnHold') && ModTurn('iterator', 60 * turn.hold);
    turn.counterClockwise && turn.hold && ModTurn('type', 'turnCounterHold') && ModTurn('iterator', 60 * turn.hold);
  };

  //This logic determines which components above are resolved, based on the payload
  clipless.turn.status && ResolveTurn();
  clipless.hover.status && ResolveHover();

  /**
   * Clipless animations can be stacked
   * Note turn.status isn't two things at once, so right now stacking is a turn "type" + hover
   */

  return (
    <React.Fragment>
      {turn.type === 'turn' && <Turn clipless={turn} deliveryTarget={deliveryTarget as any} />}
      {turn.type === 'turnCounter' && <TurnCounter clipless={turn} deliveryTarget={deliveryTarget as any} />}
      {turn.type === 'turnHold' && <TurnHold clipless={turn} deliveryTarget={deliveryTarget as any} />}
      {turn.type === 'turnCounterHold' && <TurnCounterHold clipless={turn} deliveryTarget={deliveryTarget as any} />}
      {clipless.hover.status && <Hover clipless={hover} deliveryTarget={deliveryTarget as any} />}
    </React.Fragment>
  );
};
