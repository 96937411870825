import { VariantSlider } from 'components/variant-slider';
import { useStore, useMItem } from 'store';
import { PanelProps } from './AppLayoutContext';

export const useGenerateLeftControlsPanel = (): {
  visibleLeftControlsPanel?: boolean;
  leftControlsPanelProps?: PanelProps;
} => {
  const left_slider = useStore((state) => state.params.left_slider);
  const { billboard_list = [] } = useMItem() || {};
  const activeIndex = useStore((state) => state.scene_controls.active_board) || 0;

  if (!left_slider) {
    return {};
  }

  if (billboard_list.length < 2) {
    return {};
  }

  const images = billboard_list.map(({ info: { image_1: { src: imageUrl = '' } = {} } = {} }, index) => imageUrl);

  const onItem = ({ pointIndex: active_board }: { pointIndex: number }) => {
    useStore.setState((state) => ({
      scene_controls: {
        ...state.scene_controls,
        active_board,
      },
      billboard_settings: {
        ...state.billboard_settings,
        billboard_listener_click: true,
      },
    }));
  };

  return {
    visibleLeftControlsPanel: left_slider,
    leftControlsPanelProps: {
      contentComponent: VariantSlider,
      contentComponentProps: {
        images,
        activeIndex,
        onItem
      },
    },
  };
};
