import { AppHeader } from 'components/app-header';
import { useStore, useVItem } from 'store';
import { PanelProps } from './AppLayoutContext';

export const useGenerateHeaderPanel = (): {
  visibleHeaderPanel?: boolean;
  headerPanelProps?: PanelProps;
} => {
  const header = useStore((state) => state.params.header);
  const logoUrl = useStore((state) => state.store_settings.cLogo);
  const { product_title: title = '', priceStr: price = '' } = useVItem() || {};

  if (!header) {
    return {};
  }

  if (!logoUrl && !title && !price) {
    return {};
  }

  return {
    visibleHeaderPanel: true,
    headerPanelProps: {
      contentComponent: AppHeader,
      contentComponentProps: {
        logoUrl,
        title,
        price,
      },
    },
  };
};
