import React from 'react';
import { NoColorSpace, Texture } from 'three';

export const Transmission = ({ texture }: { texture?: Texture }) => {
  // console.log('texture', texture)
  if (!texture) return null;

  // colorscience
  return <texture attach="transmissionMap" {...texture} colorSpace={NoColorSpace} name="updated_levar_transmission" />;
};
