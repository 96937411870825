export type Hrx = {
  browser_version: number;
  browser: number;
  intent: string;
  offline: boolean;
  preferred_renderer: number;
  target: string;
  version: number;
};

export const hrx: Hrx = {
  browser_version: 0,
  browser: 0,
  intent: '',
  offline: false,
  preferred_renderer: 0,
  target: '',
  version: 0,
};
