import { useEffect, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useStore } from 'store';
import { Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

interface CameraAutoRotateProps {
  controls: OrbitControls | null;
}

export const useOrbitControlsAutoRotate = ({ controls }: CameraAutoRotateProps) => {
  const [touched, setTouched] = useState(false);
  const [angle, setAngle] = useState(0);
  const [currentAngle, setCurrentAngle] = useState(0);

  const { is_camera_keyframe, autorotation_speed } = useStore((state) => state.billboard_settings);

  const auto_rotation_from_param = useStore((state) => state.params.autorotation); // auto rotation on query parameter
  const auto_rotation_from_model = useStore((state) => state.auto_rotation); // auto rotation on model

  useEffect(() => {
    // Use auto_rotation_from_model if it's defined and not zero, otherwise use auto_rotation_from_param
    const value = auto_rotation_from_model || auto_rotation_from_param;
    if (!value) {
      setAngle(0);
    } else {
      setAngle((+value * Math.PI) / 180.0);
    }
  }, [auto_rotation_from_model, auto_rotation_from_param]);

  // useEffect(() => {
  //   controls?.removeEventListener('start', setTouched);
  //   controls?.addEventListener('start', setTouched);
  //   return () => controls?.removeEventListener('start', setTouched);
  // }, [controls]);

  useEffect(() => {
    const handleStart = () => setTouched(true);

    controls?.removeEventListener('start', handleStart);
    controls?.addEventListener('start', handleStart);

    return () => controls?.removeEventListener('start', handleStart);
  }, [controls]);

  useEffect(() => {
    if (!is_camera_keyframe) {
      return;
    }
    setTouched(true);
  }, [is_camera_keyframe]);

  useFrame((state) => {
    if (!(controls && angle)) {
      return;
    }

    if (!touched && currentAngle < angle) {
      controls.object.position
        .sub(controls.target)
        .applyAxisAngle(new Vector3(0, 1, 0), autorotation_speed)
        .add(controls.target);
      controls.object.lookAt(controls.target);
      setCurrentAngle(currentAngle + autorotation_speed);
    }
  });
};
