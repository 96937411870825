import { Group } from 'three';

import { useStore, useVItem, useMItem } from 'store';
import { State } from 'store/states';

type CameraPosition = [number, number, number];

export const useDTManagerStatic = (): {
  billboards_enabled?: boolean;
  camera_position?: CameraPosition;
  deliveryTarget: Group;
  materials: any[];
  model_3d_id: string;
  preload_viewer: boolean;
  textures: State['textures'];
  turn: number;
} => {
  const turn = useStore((state) => state.clipless_animations.turn);
  const billboards_enabled = useStore((state) => state.scene_controls.billboards_enabled);
  const deliveryTarget = useStore((state) => state.group) || ({ children: [] } as any);
  const textures = useStore((state) => state.textures) || [];

  const {
    custom_spawn,
    model_3d_id = '',
    materials = [],
  } = useMItem() || {};

  const {
    preload_viewer = false
  } = useVItem() || {};

  return {
    billboards_enabled,
    // camera_position: custom_spawn && custom_spawn[0] && custom_spawn[1] && custom_spawn[2] ? custom_spawn : undefined, // strict value breaking because 0
    camera_position: custom_spawn ? custom_spawn : undefined,
    deliveryTarget,
    materials,
    model_3d_id,
    preload_viewer,
    textures,
    turn,
  };
};
