import { BillboardSettings, billboard_settings } from './billboard-settings';
import { DT, dt } from './dt';
import { Hrx, hrx } from './hrx';
import { Loading, loading } from './loading';
import { ModelState, modelState } from './model';
import { SceneControls, scene_controls } from './scene-controls';
import { Screen, screen } from './screen';
import { Params, params } from './params';
import { StoreSettings, store_settings } from './store-settings';
import { VariantsState, variantsState } from './variants';

export type State = ModelState &
  VariantsState &
  DT & {
    billboard_settings: BillboardSettings;
    hrx: Hrx;
    loading: Loading;
    scene_controls: SceneControls;
    params: Params;
    store_settings: StoreSettings;
    screen: Screen;
  };

export const state: State = {
  ...modelState,
  ...variantsState,
  ...dt,
  billboard_settings,
  hrx,
  loading,
  scene_controls,
  params,
  store_settings,
  screen,
  textures: [],
};
