import React, { useEffect, useRef, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { useBounds, OrbitControls } from '@react-three/drei';
import { Vector3 } from 'three';
import { OrbitControls as OrbitControlsType } from 'three/examples/jsm/controls/OrbitControls';

import { useStore, useMItem } from 'store';
import { creatGraphTemplate } from 'utils/three_helpers';
import { useOrbitControlsAutoRotate } from 'hooks/useOrbitControlsAutoRotate';

const showReferenceGeo = false;

export const BillboardCameraControls = () => {
  const controls = useRef<OrbitControlsType>(null);
  const binder = useBounds();
  const camera = useThree((state) => state.camera);
  const scene = useThree((state) => state.scene);
  const { billboard_list = [] } = useMItem() || {};
  const model_loaded = useStore((state) => state.model_loaded);
  const bounds = useStore((state) => state.model.bounds);
  const size = useStore((state) => state.model.size);
  const orbit_controls_target = useStore((state) => state.billboard_settings.orbit_controls_target);
  const target_positions = useStore((state) => state.billboard_settings.target_positions);
  const current_camera_keyframe = useStore((state) => state.billboard_settings.current_camera_keyframe);
  const is_camera_keyframe = useStore((state) => state.billboard_settings.is_camera_keyframe);
  const camera_move_positions = useStore((state) => state.billboard_settings.camera_move_positions);
  const countClock = useStore((state) => state.actions.countClock);
  const pulsePoi = useStore((state) => state.actions.pulsePoi);

  const [orbitControlsTarget, setOrbitControlsTarget] = useState<Vector3>(new Vector3());
  // const [enableOrbit, setEnableOrbit] = React.useState(true); // this isn't changing always true

  useOrbitControlsAutoRotate({ controls: controls.current });

  useEffect(() => {
    setOrbitControlsTarget(orbit_controls_target);
  }, [orbit_controls_target]);


  useFrame((state, delta) => {
    //no point otherwise
    if (model_loaded) {
      if (!useStore.getState().billboard_settings.lattice_graph.length) {
        if (bounds.length) {
          const clipAnchorsLocal: Vector3[] = [];
          const latticeLocal: Vector3[] = [];
          //camera to each poi anchor, target locator, use binder to no-clip locator - builds the clipAnchors SNAPSHOT
          for (let i = 0; i < billboard_list.length; i++) {
            const locator = scene.getObjectByName(`locator_${i}`);

            camera.position.set(
              billboard_list[i].poi.camera_anchor.x,
              billboard_list[i].poi.camera_anchor.y,
              billboard_list[i].poi.camera_anchor.z,
            );
            // NOT BEING SET TO CAMERA POSITION PROBLEM

            binder.refresh(locator);
            binder.fit();
            clipAnchorsLocal.push(new Vector3(camera.position.x, camera.position.y, camera.position.z));
          }

          //Reference the DT and make it binder's subject
          const obj = scene.getObjectByName(`levar_fbx`);
          // console.log('obj', obj)
          binder.refresh(obj);

          //camera to each bound, target origin, use binder to no-clip DT - builds the lattice
          for (let i = 0; i < 14; i++) {
            camera.position.set(bounds[i].x, bounds[i].y, bounds[i].z);
            binder.fit();
            latticeLocal.push(new Vector3(camera.position.x, camera.position.y, camera.position.z));
          }

          //build a template of most of the dijkstra graph
          const graphTemplate = creatGraphTemplate(latticeLocal);

          //move camera to origin, no-clip DT
          let default_billboard = 0;
          const locator_default = scene.getObjectByName(`locator_${default_billboard}`);
          // const locator_default_target =  new Vector3(locator_default.position.x, locator_default.position.y, locator_default.position.z);
          binder.refresh(locator_default);

          camera.position.set(
            clipAnchorsLocal[default_billboard].x,
            clipAnchorsLocal[default_billboard].y,
            clipAnchorsLocal[default_billboard].z,
          );
          binder.fit();

          // SET Actions for default start for billboards POIS Camera move
          // //Handle state on completiom
          useStore.setState((state) => ({
            scene_controls: {
              ...state.scene_controls,
              active_board: default_billboard,
            },
            billboard_settings: {
              ...state.billboard_settings,
              lattice_graph: graphTemplate,
              clipAnchors: clipAnchorsLocal,
              lattice: latticeLocal,
            },
          }));
        }
      }

      // when camera move ? Possibly POI Click to stop
      if (is_camera_keyframe) {
        if (current_camera_keyframe < target_positions.length - 1) {
          const lookPosition = new Vector3(
            target_positions[current_camera_keyframe].x,
            target_positions[current_camera_keyframe].y,
            target_positions[current_camera_keyframe].z,
          );

          camera.position.set(
            camera_move_positions[current_camera_keyframe].x,
            camera_move_positions[current_camera_keyframe].y,
            camera_move_positions[current_camera_keyframe].z,
          );

          //this local state set could probably happen once or twice per move instead of density times
          camera.lookAt(lookPosition);
          useStore.setState((state) => ({
            billboard_settings: {
              ...state.billboard_settings,
              orbit_controls_target: lookPosition,
              current_camera_keyframe: state.billboard_settings.current_camera_keyframe + 1,
            },
          }));
        } else {
          useStore.setState((state) => ({
            billboard_settings: {
              ...state.billboard_settings,
              current_locator_target: orbitControlsTarget,
              current_camera_keyframe: 0,
              is_camera_keyframe: false,
            },
          }));
          // setEnableOrbit(true) // Not using
        }
      }

      //tracks FPS in state
      countClock(delta);
      pulsePoi();
    }
  });

  return (
    <React.Fragment>
      {billboard_list.map((board, key) => (
        <React.Fragment key={key}>
          <Locator
            position={[board.poi.locator.position.x, board.poi.locator.position.y, board.poi.locator.position.z]}
            args={[board.poi.locator.size.x, board.poi.locator.size.y, board.poi.locator.size.z]}
            id={board.id}
          />
        </React.Fragment>
      ))}

      <OrbitControls
        ref={controls as any}
        makeDefault
        target={orbitControlsTarget}
        enablePan={false}
        // enabled={enableOrbit} // not being changed
        maxDistance={(size.x + size.y + size.z) * 2}
      />
    </React.Fragment>
  );
};

type LocatorProps = {
  args: any;
  position: any;
  id: any;
};

const Locator = ({ args, position, id }: LocatorProps) => {
  return (
    <mesh visible={showReferenceGeo} position={position} name={`locator_${id}`}>
      <boxGeometry args={args} />
      <meshStandardMaterial color={'green'} transparent={true} opacity={0.5} />
    </mesh>
  );
};
