import React from 'react';
import { styled, ButtonBase, ButtonBaseProps } from '@mui/material';

export const sizedButtonBaseDefaultSize = 30;

export type SizedButtonBaseProps = ButtonBaseProps & {
  size?: number;
};

export const SizedButtonBase = styled(({ size, ...rest }: SizedButtonBaseProps) => <ButtonBase {...rest} />, {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size = sizedButtonBaseDefaultSize }) => {
  return {
    width: size,
    height: size,
    overflow: 'hidden',
  };
});

export type CircleButtonBaseProps = SizedButtonBaseProps;

export const CircleButtonBase = styled((props: SizedButtonBaseProps) => <SizedButtonBase {...props} />)(
  ({ size = sizedButtonBaseDefaultSize }) => {
    return {
      borderRadius: size,
    };
  },
);
