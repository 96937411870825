// import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Billboard, Text } from '@react-three/drei';
import { Vector3, FrontSide, Color } from 'three';

import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { useStore, useMItem } from 'store';
import { build_dijkstra } from 'utils/three_helpers';
import { sendAnalyticsData } from 'utils/handleAnalytics';
import { RoundedRectangleGeometry } from 'utils/roundedRectangle';

type POIProps = {
  board: any;
  point_index: number;
  POI_OPAC_MAP: any;
  LABEL_OPAC_MAP: any;
};

export const POI = ({ board, point_index, POI_OPAC_MAP, LABEL_OPAC_MAP }: POIProps) => {
  // This value affects the "padding" around the text
  const planeMargin = 2.25;

  // This value affects the size of the stroke
  // Bigger number for smaller stroke
  // Smaller number for bigger stroke. Decimals for extra big 
  const backgroundStrokeDivisor = 7.5;

  // Multiple of button width/height used to set the left offset for the back button
  const backButtonOffset = 0.75;

  const pulse = 1;
  // const pulse = useStore((state) => state.poiPulse);

  const emissiveColor = new Color('#63666A');

  const [justOnce, setJustOnce] = useState(false);

  const [backgroundPlaneWidth, setBackgroundPlaneWidth] = useState(0.0001);
  const [backgroundPlaneHeight, setBackgroundPlaneHeight] = useState(0.0001);

  const [strokePlaneWidth, setStrokePlaneWidth] = useState(0.0001);
  const [strokePlaneHeight, setStrokePlaneHeight] = useState(0.0001);

  const [buttonPlaneWidthHeight, setButtonPlaneWidthHeight] = useState(0.0001);
  const [buttonPlaneStrokeSize, setButtonPlaneStrokeSize] = useState(0.0001);

  const ball = useRef<any>();
  const textRef = useRef<any>();
  const buttonRef = useRef<any>();

  const camera = useThree((state) => state.camera);
  const active_board = useStore((state) => state.scene_controls.active_board);
  const billboard_listener_click = useStore((state) => state.billboard_settings.billboard_listener_click);
  const camera_density = useStore((state) => state.billboard_settings.camera_density);
  const fps = useStore((state) => state.fps);
  const model = useStore.getState().model;

  const { billboard_list = [] } = useMItem() || {};

  const analyticsData = useAnalyticsData({ event_type: `poi_click_${point_index}` });

  const density = camera_density / (60 / fps);

  const goHome = () => {
    useStore.setState((state) => ({
      scene_controls: {
        ...state.scene_controls,
        active_board: 0,
      },
      billboard_settings: {
        ...state.billboard_settings,
        billboard_listener_click: true,
      },
    }));
  };

  const clickBall = useCallback(
    async (event?: any) => {
      if (event) event.stopPropagation();

      if (point_index === 0 && event) return; // Don't allow click of center position ball 1st ball (breaks)

      const { lattice_graph, clipAnchors, lattice, orbit_controls_target } = useStore.getState().billboard_settings;
      const cameraPosition = new Vector3(camera.position.x, camera.position.y, camera.position.z);
      const locatorPosition = new Vector3(
        billboard_list[point_index].poi.locator.position.x,
        billboard_list[point_index].poi.locator.position.y,
        billboard_list[point_index].poi.locator.position.z,
      );

      const { points, targetPositions } = build_dijkstra(
        lattice_graph,
        lattice,
        clipAnchors,
        orbit_controls_target,
        cameraPosition,
        locatorPosition,
        density,
        point_index,
      );

      useStore.setState((state) => ({
        billboard_settings: {
          ...state.billboard_settings,
          current_camera_keyframe: 0,
          camera_move_positions: points,
          target_positions: targetPositions,
          is_camera_keyframe: true,
          billboard_listener_click: false,
        },
        scene_controls: {
          ...state.scene_controls,
          active_board: point_index,
          active_board_changed: true,
        },
      }));
      // ANALYTICS TODO
      await sendAnalyticsData(analyticsData);
    },
    // eslint-disable-next-line
    [point_index, camera, billboard_list, density],
  );

  // If active board and point_index match then call clickBall fn, For outside active board change listener
  useEffect(() => {
    if (!billboard_listener_click) return;
    if (active_board === point_index) clickBall();
  }, [active_board, point_index, clickBall, billboard_listener_click]);

  // Handles position of label-type billboards
  useEffect(() => {
    if (!justOnce && textRef.current?.geometry.boundingBox.max.x > 0) {
      const planeWidth = textRef.current.geometry.boundingBox.max.x * planeMargin;
      const planeHeight = textRef.current.geometry.boundingBox.max.y * planeMargin;

      const buttonWidthHeight = textRef.current.geometry.boundingBox.max.y * 2;

      const strokeOffset = (Math.min(planeHeight, planeWidth) / backgroundStrokeDivisor);

      setBackgroundPlaneHeight(planeHeight);
      setBackgroundPlaneWidth(planeWidth);

      setStrokePlaneHeight(planeHeight + strokeOffset);
      setStrokePlaneWidth(planeWidth + strokeOffset);

      setButtonPlaneWidthHeight(buttonWidthHeight);
      setButtonPlaneStrokeSize(buttonWidthHeight + strokeOffset);

      buttonRef.current.position.x = (-1 * backButtonOffset * buttonWidthHeight) - (planeWidth / 2);

      setJustOnce(true);
    }
  }, [textRef.current?.geometry?.boundingBox?.max?.x, justOnce]);


  useFrame(() => {
    ball.current.lookAt(camera.position.x, camera.position.y, camera.position.z);
  });

  const _handleVisiblePOI = () => {
    if (point_index === active_board) return false; // Hide If currenlty active
    if (point_index === 0) return false; // Defualt Position for POI don't allow POI Click
    if (board.board_attributes.type === 'label' && board.board_attributes.label_hide_pois) return false; // Hide if label BB requested no POIs

    return true;
  };

  const _handleVisibleText = () => {
    if (point_index === 0) return false;
    return true;
  };

  return (
    <group
      name={`poi_${point_index}`}
      dispose={null}
    >
      <mesh
        ref={ball}
        name={'ball_one'}
        position={[board.poi.position.x, board.poi.position.y, board.poi.position.z]}
        visible={_handleVisiblePOI()}
        onClick={(event) => clickBall(event)}
      >
        <planeGeometry args={[board.poi.size * 1.2 * pulse, board.poi.size * 1.2 * pulse]} attach="geometry" />

        <meshStandardMaterial
          transparent={true}
          side={FrontSide}
          attach="material"
          opacity={1}
          roughness={1}
          metalness={0}
          color={'black'}
          emissive={emissiveColor}
          emissiveIntensity={1}
        >
          <POI_OPAC_MAP />
        </meshStandardMaterial>
      </mesh>

      {board.board_attributes.type === 'label' &&
        <Billboard
          position={[
            board.board_attributes.label_position.x,
            board.board_attributes.label_position.y,
            board.board_attributes.label_position.z
          ]}
        >
          <group
            visible={_handleVisibleText()}
          >
            <Text
              fontSize={(model.size.x + model.size.y + model.size.z) / 75 * board.board_attributes.label_text_scale}
              color={board.board_attributes.label_text_color}
              ref={textRef}
              visible={justOnce}
              onClick={(event) => clickBall(event)}
            >
              {board.info.h1.text}
            </Text>

            <mesh
              position={[0, -0.0005, -0.001]}
              onClick={(event) => clickBall(event)}
              name="background_plane"
            >
              <RoundedRectangleGeometry
                width={backgroundPlaneWidth}
                height={backgroundPlaneHeight}
                radius={Math.min(backgroundPlaneWidth, backgroundPlaneHeight) / 5}
                smoothness={10}
              />
              <meshBasicMaterial color={board.board_attributes.label_background_color} />
            </mesh>

            <mesh
              position={[0, -0.0005, -0.003]}
              onClick={(event) => clickBall(event)}
              name="stroke_plane"
            >
              <RoundedRectangleGeometry
                width={strokePlaneWidth}
                height={strokePlaneHeight}
                radius={Math.min(strokePlaneWidth, strokePlaneHeight) / 5}
                smoothness={10}
              />
              <meshBasicMaterial color={board.board_attributes.label_background_stroke_color} />
            </mesh>

            <group
              ref={buttonRef}
              position={[0, 0, 0]}
              visible={point_index === active_board}
              onClick={goHome}
              name="back_button"
            >
              {
                !board.board_attributes.label_hide_home_button && <group name="back_button_interior">
                  <mesh
                    name="button_plane"
                  >
                    <RoundedRectangleGeometry
                      width={buttonPlaneWidthHeight}
                      height={buttonPlaneWidthHeight}
                      radius={buttonPlaneWidthHeight / 5}
                      smoothness={10}
                    />
                    <meshBasicMaterial color={board.board_attributes.label_home_background_color} />
                  </mesh>

                  <mesh
                    name="button_plane_stroke"
                    position={[0, 0, -0.001]}
                  >
                    <RoundedRectangleGeometry
                      width={buttonPlaneStrokeSize}
                      height={buttonPlaneStrokeSize}
                      radius={buttonPlaneStrokeSize / 5}
                      smoothness={10}
                    />
                    <meshBasicMaterial color={board.board_attributes.label_background_stroke_color} />
                  </mesh>

                  <mesh
                    position={[0, 0, 0.001]}
                    name="back_button_icon"
                  >
                    <planeGeometry
                      args={[buttonPlaneWidthHeight, buttonPlaneWidthHeight]}
                      attach="geometry"
                    />

                    <meshBasicMaterial
                      color={board.board_attributes.label_home_icon_color}
                      transparent={true}
                      side={FrontSide}
                      attach="material"
                      opacity={1}
                    >
                      <LABEL_OPAC_MAP />
                    </meshBasicMaterial>
                  </mesh>
                </group>
              }
            </group>
          </group>
        </Billboard>
      }
    </group>
  );
};
