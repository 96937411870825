import React, { useState, useEffect, useCallback } from 'react';
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import QRCode from 'qrcode.react';

import { ErrorIndicator } from 'components/indicators';
import { useStore } from 'store';
import { buildUrlQRcode } from 'utils/helpers';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type QRCodePromptDialogProps = Omit<DialogProps, 'open' | 'onClose'> & {
  open?: boolean;
  onClose?: () => void;
  variant_id?: string;
  model_3d_id?: string;
  ghost_variant_id?: string;
  variant_type?: string;
  fullscreen?: boolean;
  hank_modal?: string;
  model_type?: string;
  ar_activation?: boolean;
  billboards?: boolean;
};

export const QRCodePromptDialog = ({
  open = false,
  onClose = () => { },
  variant_id = '',
  model_3d_id = '',
  ghost_variant_id = '',
  variant_type = '',
  fullscreen = false,
  hank_modal = '',
  model_type = '',
  ar_activation = false,
  billboards = false,
  ...rest
}: QRCodePromptDialogProps) => {
  const [qrUrl, setQrUrl] = useState('');

  // Params
  const ecommerce_session_id = useStore((state) => state.params.ecommerce_session_id);
  const levar_session_id = useStore((state) => state.params.levar_session_id);
  const levar_pixel_id = useStore((state) => state.params.levar_pixel_id);

  const generateLink = useCallback(() => {

    const qrPayload = {
      ecommerce_session_id,
      levar_session_id,
      levar_pixel_id,
      variant_type,
      variant_id,
      fullscreen,
      billboards,
      hank_modal,
      model_type,
      ar_activation,
      model_3d_id,
      ghost_variant_id
    };

    const redirectUrl = buildUrlQRcode(qrPayload)
    // console.log('redirectUrl', redirectUrl)

    setQrUrl(redirectUrl);
  }, [variant_id, model_3d_id, ghost_variant_id, variant_type, fullscreen, hank_modal, model_type, ar_activation, billboards, ecommerce_session_id, levar_session_id, levar_pixel_id]);

  useEffect(() => {
    generateLink();
  }, [generateLink]);

  const borders = [
    {
      top: 0,
      left: 0,
      borderRight: 0,
      borderBottom: 0,
      borderTopLeftRadius: 6,
    },
    {
      top: 0,
      right: 0,
      borderLeft: 0,
      borderBottom: 0,
      borderTopRightRadius: 6,
    },
    {
      bottom: 0,
      right: 0,
      borderLeft: 0,
      borderTop: 0,
      borderBottomRightRadius: 6,
    },
    {
      bottom: 0,
      left: 0,
      borderRight: 0,
      borderTop: 0,
      borderBottomLeftRadius: 6,
    },
  ];

  return (
    <div>
      <Dialog
        {...rest}
        aria-labelledby="dispay-qr-code"
        aria-describedby="dispay-qr-code-modal"
        open={open}
        onClose={() => onClose()}
        hideBackdrop
        TransitionComponent={Transition}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
      >
        <DialogTitle
          component="h6"
          sx={{ fontSize: 16, fontWeight: 500, textAlign: 'center', color: (theme) => theme.palette.text.secondary }}
        >
          You're moments away from Augmented Reality
        </DialogTitle>

        <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>

          {qrUrl.length > 0 && (variant_id || model_3d_id || ghost_variant_id) && variant_type ? (
            <Box component="div" sx={{ position: 'relative', p: 1.25 }}>
              <>
                {borders.map((border, index) => (
                  <Box
                    key={`qr-code-border-${index}`}
                    component="div"
                    sx={{
                      position: 'absolute',
                      width: 40,
                      height: 40,
                      border: (theme) => `3px solid ${theme.palette.primary.main}`,
                      ...border,
                    }}
                  />
                ))}

                <QRCode value={qrUrl} size={250} width={250} level={'H'} includeMargin={true} />
              </>
            </Box>
          ) : (
            <ErrorIndicator
              title="QR Code Error"
              description="Generating QR code has been failed"
              action={{ label: 'Close', onClick: () => onClose() }}
            />
          )}
        </DialogContent>

        <DialogTitle
          component="h6"
          sx={{ fontSize: 16, fontWeight: 500, textAlign: 'center', color: (theme) => theme.palette.text.secondary }}
        >
          Scan this QR code with your phone's camera
        </DialogTitle>
      </Dialog>
    </div>
  );
};
