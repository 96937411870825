import React, { useEffect, useCallback } from 'react';
import { useThree, useLoader } from '@react-three/fiber';
import { useBounds } from '@react-three/drei';
import { Box3, Vector3, MeshStandardMaterial, Color } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useStore } from 'store';
import { CreateMissingSpawn } from 'utils/math';
import { AnimationLoader } from './AnimationLoader';
import { Channels } from './Channels';
import { useDTManagerAnimation } from './useDTManagerAnimation';

export const DTManagerAnimation = () => {
  // console.log('***********DTManagerAnimation GEO_TYPE: 1***********')
  const binder = useBounds();
  const group = React.useRef();
  const camera = useThree((state) => state.camera);

  const {
    bundle,
    camera_position,
    geo,
    materials,
    model_url,
    store_id
  } = useDTManagerAnimation();

  const geoURL = `${model_url}/${store_id}/${bundle}/${geo}.glb`;

  const { nodes, animations } = useLoader(GLTFLoader, geoURL);

  const Maps: any = Channels({
    bundle,
    materials,
    model_url,
    store_id
  });

  // Second Material
  if (materials.length > 1) {

    (nodes.Root_Scene.children[0].children[2] as any).geometry.attributes.uv2 = (
      nodes.Root_Scene.children[0].children[2] as any
    ).geometry.attributes.uv;
    (nodes.Root_Scene.children[0].children[2] as any).material = new MeshStandardMaterial({
      name: 'slot_2',
      color: new Color(materials[1]['channels'].base_color),
      map: Maps.color1,
      normalMap: Maps.normal1,
      roughnessMap: Maps.roughness1,
      metalness: 1,
      metalnessMap: Maps.metalness1,
      aoMap: Maps.ao1,
      emissive: new Color(materials[1]['channels'].emissive),
      emissiveIntensity: materials[1]['channels'].emissive_intensity,
      emissiveMap: materials[1]['channels'].emissive_map < 0 ? Maps.emissive1 : null,
      transparent: materials[1].transparent ? true : false,
      alphaMap: materials[1]['channels'].opacity < 0 ? Maps.opacity1 : null
    });
  }

  // First Material
  (nodes.Root_Scene.children[0].children[1] as any).geometry.attributes.uv2 = (
    nodes.Root_Scene.children[0].children[1] as any
  ).geometry.attributes.uv;
  (nodes.Root_Scene.children[0].children[1] as any).material = new MeshStandardMaterial({
    name: 'slot_1',
    color: new Color(materials[0]['channels'].base_color),
    map: Maps.color0,
    normalMap: Maps.normal0,
    roughnessMap: Maps.roughness0,
    metalness: 1,
    metalnessMap: Maps.metalness0,
    aoMap: Maps.ao0,
    emissive: new Color(materials[0]['channels'].emissive),
    emissiveIntensity: materials[0]['channels'].emissive_intensity,
    emissiveMap: materials[0]['channels'].emissive_map < 0 ? Maps.emissive0 : null,
    transparent: materials[0].transparent ? true : false,
    alphaMap: materials[0]['channels'].opacity < 0 ? Maps.opacity0 : null,
  });

  AnimationLoader({ nodes: nodes.Root_Scene, animations });

  const handleUpdate = useCallback(() => {
    const boundingBox = new Box3().setFromObject(group.current as any);
    const size = boundingBox.getSize(new Vector3());

    if (camera_position) {
      camera.position.set(...camera_position);
    } else {
      const missingSpawn = CreateMissingSpawn({ modelSize: size });
      camera.position.set(missingSpawn.x, missingSpawn.y, missingSpawn.z);
    }

    binder.fit();

    useStore.setState({ model_loaded: true, model: { size, bounds: [], lengthSum: size.x + size.y + size.z } });

    // eslint-disable-next-line
  }, [group, binder, camera]);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  return (
    <group ref={group as any} scale={1}>
      <primitive name={'deliveryTarget'} object={nodes.Root_Scene} dispose={null} />
    </group>
  );
};
