export type AnalyticsData = {
  created_at: string;
  device_type: string;
  domain: string;
  ecommerce_session_id: string;
  event_type: string;
  levar_pixel_id: string;
  levar_session_id: string;
  model_3d_id: string;
  origin: string;
  product_id: number;
  product_title: string;
  referrer: string;
  store_id: string;
  store_domain: string;
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
  variant_id: string;
  variant_title: string;
  viewer_session_id: string;
};

// levar_pixel_id
// model_3d_id
// implementation_type

export const sendAnalyticsData = async (analyticsData: AnalyticsData) => {
  const url = 'https://be9ylitupj.execute-api.us-east-1.amazonaws.com/dev/send/pixel';

  const options = {
    method: 'POST',
    body: JSON.stringify(analyticsData),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`New Fetch Call Cannot Be Made`);
    }

    const data = await response.json();

    return data;
  } catch (e) {
    console.error('Error: ', e);
  }
};
