import { Visibility, Palette, ShoppingCart, Star, Share } from '@mui/icons-material';
// components
import { ViewerBillboard } from 'components/billboard';
import { ViewerCart } from 'components/cart';
import { ViewerSocial } from 'components/social';
import { Feedbacks } from 'components/feedbacks';
import { SwitchVariant } from 'components/switch-variant';
import { Control, ViewerBottomControls } from 'components/viewer-bottom-controls';
import { useStore, useMItem, useVItem } from 'store';
import { useCheckFullScreen } from '../app-layout';
import { DrawerProps } from './Drawer';

export const useGenerateBottomDrawer = (): {
  visibleBottomDrawer?: boolean;
  bottomDrawerProps?: DrawerProps;
} => {
  const fullScreen = useCheckFullScreen();

  const variant_type = useStore((state) => state.params.variant_type);
  const addToCartBtn = useStore((state) => state.params.addToCartBtn);
  const billboards_enabled = useStore((state) => state.scene_controls.billboards_enabled);
  const billboards_drawer_enabled = useStore((state) => state.scene_controls.billboards_drawer_enabled);
  const bottom_drawer_enabled = useStore((state) => state.params.bottom_drawer);
  const social_share = useStore((state) => state.params.social_share);
  const origin = useStore((state) => state.params.origin);

  const {
    viewer_variant_switch = ''
  } = useMItem() || {};

  const {
    referer = ''
  } = useVItem() || {};

  if (!fullScreen || !bottom_drawer_enabled || (!billboards_drawer_enabled && (variant_type === 'ghost' || variant_type === 'model'))) {
    return {};
  }

  const controls: Control[] = [];

  /**
   * Variant switching
   * Note: Will have to update here to allow for Model variant_type to allow for model switching
   */
  if (viewer_variant_switch && (variant_type !== 'model' && variant_type !== 'ghost')) {
    controls.push({
      id: 'palette',
      icon: <Palette />,
      tooltipTitle: 'Customize',
      contentComponent: SwitchVariant,
      contentComponentProps: {
        containerStyle: { pt: 2, pb: 2, pl: 2, pr: 2 },
      },
    });
  }

  /**
   * Billboard
   */
  if (billboards_enabled && billboards_drawer_enabled) {
    controls.push({
      id: 'spotlight',
      icon: <Visibility />,
      tooltipTitle: 'Spotlight',
      contentComponent: ViewerBillboard,
    });
  }

  /**
   * Add to cart
   * add_to_cart === on && origin !== static && referer exists
   */
  if (addToCartBtn && origin !== 'static' && referer) {
    controls.push({
      id: 'cart',
      icon: <ShoppingCart />,
      tooltipTitle: 'Add to Cart',
      contentComponent: ViewerCart,
    });
  }

  /**
   * Social
   */
  if (social_share) {
    controls.push({
      id: 'social',
      icon: <Share />,
      tooltipTitle: 'Social',
      contentComponent: ViewerSocial
    });
  }

  /**
    * Review
    */
  if (false) {
    controls.push({
      id: 'feedbacks',
      icon: <Star />,
      tooltipTitle: 'Reviews',
      contentComponent: Feedbacks,
    });
  }

  /**
   * Video
   */
  // controls.push({
  //   id: 'video',
  //   icon: <Videocam />,
  //   tooltipTitle: 'Video',
  //   onClick: () => {
  //     console.log('click_video');
  //   },
  // });

  return {
    visibleBottomDrawer: controls.length > 0,
    bottomDrawerProps: {
      contentComponent: ViewerBottomControls,
      contentComponentProps: {
        controls,
      },
    },
  };
};
