import React from 'react';

import { ShadeCanvas } from './canvas';
import { Scene } from './scene';

export const VariantViewer = () => {
  return (
    <ShadeCanvas>
      <Scene />
    </ShadeCanvas>
  );
};
