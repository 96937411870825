import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
  const theme = useTheme();

  // down
  const downXs = useMediaQuery(theme.breakpoints.down('xs'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const downXl = useMediaQuery(theme.breakpoints.down('xl'));

  // up
  const upXs = useMediaQuery(theme.breakpoints.up('xs'));
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const upXl = useMediaQuery(theme.breakpoints.up('xl'));

  // between
  const betweenXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const betweenXsMd = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const betweenXsLg = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const betweenXsXl = useMediaQuery(theme.breakpoints.between('xs', 'xl'));
  const betweenSmMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const betweenSmLg = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const betweenSmXl = useMediaQuery(theme.breakpoints.between('sm', 'xl'));
  const betweenMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const betweenMdXl = useMediaQuery(theme.breakpoints.between('md', 'xl'));
  const betweenLgXl = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

  return {
    downXs,
    downSm,
    downMd,
    downLg,
    downXl,
    upXs,
    upSm,
    upMd,
    upLg,
    upXl,
    betweenXsSm,
    betweenXsMd,
    betweenXsLg,
    betweenXsXl,
    betweenSmMd,
    betweenSmLg,
    betweenSmXl,
    betweenMdLg,
    betweenMdXl,
    betweenLgXl,
  };
};
