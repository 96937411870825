export type VItem = {
  ar_experience_type: string; // Frontend Field Implementation (FE)
  asset_theme: string; // Frontend Field Implementation (FE) 
  billboard_list: any[]; // Override
  billboard_status: false, // Frontend Field Implementation (FE)
  billiard_custom_button: false; // ? spelt wrong cant find
  created_at: string; // Don't Use
  custom_button: boolean; // Frontend Field Implementation (FE)
  custom_sku: any; // Don't Use
  custom_text: any; // Believe FE (qr_footer + qr_header)
  direct_qr: boolean; // Frontend Field Implementation (FE)
  handle: string; // Don't Use
  id?: string; // Variant ID
  levar_exit_url: string; // Custom URL for exiting and overriding referer
  levar_private_key: any; // Don't Use
  levar_user_account_id: string; // Don't Use
  model_3d_id: string; // 3D Model Reference ID
  options: any[]; // Options Type for Variant Switching
  pdp_variant_switch: boolean; // Frontend Field Implementation (FE) ? TODO
  platform: string; // Don't Use
  preload_viewer: boolean; // USE in DTManagerStatic TODO
  price?: number; // Don't always have
  product_description: string; // Don't Use
  product_id: number; // Use in Analytics
  product_id_raw: any; // Don't Use
  product_title: string; // Main Title
  referer: string; // Combined Built Exit URL base on the pdp variant
  sku?: any; // Use when building Add To Cart URL
  store_id: string; // Don't Use
  store_title: string; // Don't Use
  store_url: string; // Don't Use
  translation_inactive: boolean; // Don't Use ? Maybe FE for Weglot
  updated_at: string; // Don't Use
  variant_id: string; // 
  variant_id_raw: any; // Don't Use
  variant_title: string; // SubTitle
  vendor: string; // Don't Use
  view_in_space_off: boolean; // Use For Turning Off AR

  // still need to check
  subTitle: any; // TODO Convert to variant_title
  // title: string; // TODO Convert to product_title
};

export type VList = {
  [variant_id: string]: VItem;
};

export type Variants = {
  store_id: string;
  vList?: VList;
  mList?: MList;
  model_3d_id: string;
  ecommerce_id: string; // used for variants deprecated
  statusCode: number;
};

export type VariantsState = Omit<Variants, 'response' | 'statusCode'> & {
  variant_id: string;
  model_3d_id: string;
  vItem?: VItem;
  mItem?: MItem;
};

export type MList = {
  [model_3d_id: string]: MItem;
}

export const variantsState: VariantsState = {
  variant_id: '',
  model_3d_id: '',
  ecommerce_id: '', // used for variants deprecated
  store_id: '',
  vList: undefined, // ecommerce_search_response_hits_source
  vItem: undefined, // specific variant from ecommerce_search_response_hits_source
  mList: undefined, // model_search_response_hits_source
  mItem: undefined, // specific model model_search_response_hits_source
};

// GHOST || MODEL
export type MItem = {
  auto_rotation?: number | undefined;
  ardt: string; // USDZ or GLB DT URL
  asset_background: string; // Viewer BG Color
  asset_environment: any; // Viewer HDR File
  billboard_list?: BillboardList[] | []; // Main Billboard List
  bundle: string; // name
  clipless?: any; // Don't Use TODO?
  created_at: string; // Don't Use
  custom_spawn: [number, number, number];
  ecommerce_variant_id?: string; // Connected Ecommerce Variant ID from Loadout Don't Use
  geo: string; // Name
  geo_type: number; // Loader Type => FBX || Animated GLB || Static GLB
  ghost_client_title?: string; // Don't Use
  ghost_stage?: number; // Don't Use 
  ghost_title?: string; // Don't Use
  ghost_variant_id?: string; // Don't Use
  id?: string; // Maybe Use
  loadout_id: string; // Don't Use
  materials?: [ // Use For Model Build
    {
      channels: Channels;
      depth_write: boolean;
      material_type: string;
      name: string;
      side: string;
      transparent: boolean;
      uuid: string;
    }
  ];
  model_3d_id?: string; // 3D Model ID
  model_status: string; // Model Status (off || on || free)
  model_type?: string; // static_absolute_lighting_0
  orbit_controls_angles: number[]; // Azmith Floor
  ruler_settings?: { // Store Level Ruler
    auto_status_text_override?: any[];
    rulers_enabled?: boolean;
  };
  variant_ruler_settings?: { // Variant Level Ruler
    auto_status_text_override?: any[];
    rulers_enabled?: boolean;
  };
  schema_3d: any; // Future Mode
  store_id: string; // Don't Use ?
  updated_at: string; // Don't Use
  vertical_ar: boolean; // Don't Use ?
  viewer_variant_switch: boolean; // Use for Saying Variant Switch Available
};

export type BillboardList = {
  id: number;
  poi: {
    size: number;
    position: {
      x: number;
      y: number;
      z: number;
    };
    camera_anchor: {
      x: number;
      y: number;
      z: number;
    };
    locator: {
      size: {
        x: number;
        y: number;
        z: number;
      };
      position: {
        x: number;
        y: number;
        z: number;
      };
    };
  };
  board_attributes?: {
    label_position?: {
      x?: number;
      y?: number;
      z?: number;
    };
    type?: string;
    label_includes_p?: boolean;
  };
  info?: {
    p?: {
      fontSize: number;
      text: string;
    };
    image_1: {
      src: string;
    };
    h1: {
      text: string;
    };
  };
};

export type Channels = {
  ambient_occlusion: number;
  base_color: string;
  base_color_name: string;
  base_color_map: number;
  emissive: string;
  emissive_intensity: number;
  emissive_map: number;
  ior: number;
  metalness: number;
  normal: number;
  opacity: number;
  roughness: number;
  thickness: number;
  transmission: number
};