import { Vector3 } from 'three';

/**
 * 2D
 */
export const Distance2D = ({ p0, p1 }: any) => {
  const a = p0.x - p1.x;
  const b = p0.y - p1.y;
  const c = Math.sqrt(a * a + b * b);

  return c;
};

/**
 * 3D
 */
export const Distance3D = ({ p0, p1 }: any) => {
  const dVector = new Vector3(p0.x - p1.x, p0.y - p1.y, p0.z - p1.z);

  return Math.sqrt(dVector.x * dVector.x + dVector.y * dVector.y + dVector.z * dVector.z);
};

export const PreventClipLength3D = ({ lengthSum, aspectNormalized }: any) => {
  return lengthSum * aspectNormalized;
};

export const PointOnALine3D = ({ p0, p1, d }: any) => {
  const length = Distance3D({ p0: p0, p1: p1 });
  const vector = new Vector3(p1.x - p0.x, p1.y - p0.y, p1.z - p0.z);
  const nVector = new Vector3(vector.x / length, vector.y / length, vector.z / length);
  const poal = new Vector3(p0.x + d * nVector.x, p0.y + d * nVector.y, p0.z + d * nVector.z);
  return poal;
};

export const PointsOnALine3D = ({ p0, p1, count }: any) => {
  let p0x = new Vector3();
  let p1x = new Vector3();
  p0x.copy(p0);
  p1x.copy(p1);
  const distance = Distance3D({ p0: p0x, p1: p1x }) / count;
  let points = [];
  let lastPos = p0x;
  for (var i = 0; i < count; i++) {
    points.push(PointOnALine3D({ p0: lastPos, p1: p1x, d: distance }));
    lastPos = new Vector3(points[points.length - 1].x, points[points.length - 1].y, points[points.length - 1].z);
  }

  return points;
};

type CreateMissingSpawnProps = {
  modelSize: Vector3;
};

export const CreateMissingSpawn = ({ modelSize }: CreateMissingSpawnProps) => {
  const max = Math.max(modelSize.x, modelSize.y, modelSize.z);
  const min = Math.min(modelSize.x, modelSize.y, modelSize.z);

  let vals = [modelSize.x, modelSize.y, modelSize.z];
  vals.splice(vals.indexOf(max), 1);
  vals.splice(vals.indexOf(min), 1);

  const mid = vals[0];
  const le = mid / min; //
  const he = max / mid;
  const di = max / min;

  let missingSpawn = {
    value: new Vector3(0, 0, 5),
    make: (x: any, y: any, z: any, type: any) => {
      missingSpawn.value = new Vector3(x, y, z);
    },
  };

  le > he
    ? di > 2.75
      ? modelSize.y === min
        ? di > 99
          ? modelSize.x + modelSize.z > 0.1
            ? missingSpawn.make(
                (modelSize.x + modelSize.z) / 10,
                (modelSize.x + modelSize.z) * 0.7,
                modelSize.x + modelSize.z,
                'Rug',
              )
            : missingSpawn.make(0, 1, 0, 'Wafer')
          : missingSpawn.make(
              (modelSize.x + modelSize.z) / 7.5,
              (modelSize.x + modelSize.z) * 0.4,
              modelSize.x + modelSize.z,
              'Thick Rug',
            )
        : modelSize.z === min
        ? di > 5
          ? missingSpawn.make(0, 0, 1, 'Wall')
          : missingSpawn.make(0, 0, 1, 'Thick Wall')
        : di > 5
        ? missingSpawn.make(modelSize.z + modelSize.y, 0, 1, 'Sail')
        : missingSpawn.make(0, 0, 1, 'Thick Sail')
      : missingSpawn.make((modelSize.x + modelSize.z) / 5, modelSize.y, 1, 'Low Extreme Squarish')
    : di > 2
    ? modelSize.y === max
      ? di > 7.5
        ? modelSize.y > 5
          ? missingSpawn.make(modelSize.x, -0.2 * modelSize.y, 1, 'Building')
          : missingSpawn.make((modelSize.x + modelSize.z) / 5, modelSize.y / 4, 1, 'Candle')
        : missingSpawn.make((modelSize.x + modelSize.z) / 5, modelSize.y / 4, 1, 'Short Candle')
      : modelSize.z === max
      ? di > 5
        ? missingSpawn.make(1, 0, 0, 'Train')
        : missingSpawn.make(modelSize.x * 0.5, modelSize.y / 4, 1, 'Short Train')
      : di > 10
      ? missingSpawn.make((modelSize.x + modelSize.z) / 20, modelSize.y * 0.5, 1, 'Fence')
      : missingSpawn.make((modelSize.x + modelSize.z) / 20, modelSize.y * 0.5, 1, 'Short Fence')
    : missingSpawn.make((modelSize.x + modelSize.z) / 5, modelSize.y * 0.75, 1, 'He Extreme Squarish');

  return missingSpawn.value;
};

export const Midpoint3D = ({ p0, p1 }: any) => {
  return PointOnALine3D({ p0, p1, d: Distance3D({ p0, p1 }) / 2 });
};

export const indexOfSmallest = ({ a }: any) => {
  let lowest = 0;
  for (let i = 1; i < a.length; i++) {
    if (a[i] < a[lowest]) lowest = i;
  }
  return lowest;
};
