import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Image from 'mui-image';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { useStore } from 'store';

export type AppHeaderProps = {
  logoUrl?: string;
  title?: string;
  subtitle?: string;
  price?: string;
};

export const AppHeader = ({ logoUrl = '', title = '', subtitle = '', price = '' }: AppHeaderProps) => {
  const { downSm, downMd } = useBreakpoints();
  const right_header = useStore((state) => state.params.right_header);

  if (!logoUrl && !title && !subtitle && !price) {
    return null;
  }

  return (
    <Grid container spacing={1} sx={{ minHeight: downMd ? 'unset' : 80 }}>
      {logoUrl && (
        <Grid item xs={12}>
          <Box component="div" sx={{ height: downSm ? 32 : 48 }}>
            <Image src={logoUrl} alt="Logo" title="Logo" width="fit-content" duration={1000} />
          </Box>
        </Grid>
      )}

      {title && (right_header ? downMd : true) && (
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mt: logoUrl ? 2 : 0 }}>
            {title}
          </Typography>
        </Grid>
      )}

      {subtitle && (right_header ? downMd : true) && (
        <Grid item xs={12}>
          <Typography>{subtitle}</Typography>
        </Grid>
      )}

      {price && price !== 'undefined' && (right_header ? downMd : true) && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="primary">
            {price}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
