import { useLoader } from '@react-three/fiber';
import { TextureLoader, SRGBColorSpace, NearestMipmapLinearFilter, LinearFilter, NoColorSpace } from 'three';

import { AltLoader } from './AltLoader';

type SingleMatProps = {
  bundle: string;
  materials: any;
  model_url: string;
  store_id: string;
};

export const SingleMat = ({
  bundle,
  materials,
  model_url,
  store_id
}: SingleMatProps) => {

  // Single Material URL
  const colorURL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_color_${materials[0]['channels'].base_color_name}.${materials[0]['channels'].base_color_map === -1 ? 'jpg' : 'png'}`;
  const normalURL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_normal.${materials[0]['channels'].normal === -1 ? 'jpg' : 'png'}`;
  const roughnessURL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_rough.${materials[0]['channels'].roughness === -1 ? 'jpg' : 'png'}`;
  const metalnessURL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_metal.${materials[0]['channels'].metalness === -1 ? 'jpg' : 'png'}`;
  const ambientOcclusionURL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_ao.${materials[0]['channels'].ambient_occlusion === -1 ? 'jpg' : 'png'}`;

  const [color_map, normal_map, roughness_map, metalness_map, ambientOcclusion_map] = useLoader(TextureLoader, [
    colorURL,
    normalURL,
    roughnessURL,
    metalnessURL,
    ambientOcclusionURL,
  ]);

  let emissive_map0, opacity_map0;

  if (materials[0]['channels'].emissive_map < 0) {
    emissive_map0 = AltLoader({ model_url, bundle, store_id, materials, slot: 0, type: 'emiss' });
  }

  if (materials[0]['channels'].opacity < 0) {
    opacity_map0 = AltLoader({ model_url, bundle, store_id, materials, slot: 0, type: 'opac' });
  }

  // colorscience
  color_map.flipY = false;
  color_map.colorSpace = SRGBColorSpace;
  color_map.anisotropy = 16;
  color_map.minFilter = NearestMipmapLinearFilter;
  color_map.magFilter = LinearFilter;
  normal_map.colorSpace = NoColorSpace;
  normal_map.anisotropy = 16;
  normal_map.minFilter = NearestMipmapLinearFilter;
  normal_map.magFilter = LinearFilter;
  normal_map.flipY = false;
  roughness_map.colorSpace = NoColorSpace;
  roughness_map.flipY = false;
  metalness_map.colorSpace = NoColorSpace;
  metalness_map.flipY = false;
  ambientOcclusion_map.colorSpace = NoColorSpace;
  ambientOcclusion_map.flipY = false;

  const Maps = {
    color0: color_map,
    normal0: normal_map,
    roughness0: roughness_map,
    metalness0: metalness_map,
    ao0: ambientOcclusion_map,
    emissive0: emissive_map0,
    opacity0: opacity_map0,
  };

  return Maps;
};
