import { useStore } from 'store';
import { useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import { useSpring } from '@react-spring/three';

const CAMERA_OFFSET_RATIO = 20;

export const useCameraOffset = () => {
  const { camera, size } = useThree();

  const isOffset = useStore((state) => state.isOffset);

  const setCameraOffset = useCallback(
    (value: number) => {
      const { width, height } = size;
      const deltaWidth = (width * value) / 100;
      const deltaHeight = (height * value) / 100;
      camera.setViewOffset(
        width - deltaWidth,
        height - deltaHeight,
        deltaWidth * -0.5,
        deltaHeight * 0.25,
        width,
        height,
      );
    },
    [size, camera],
  );

  useSpring({
    to: { offset: isOffset ? CAMERA_OFFSET_RATIO : 0 },
    from: { offset: isOffset ? 0 : CAMERA_OFFSET_RATIO },
    onChange: ({ value }) => {
      setCameraOffset(value.offset);
    },
  });
};
