import { Group, Texture } from 'three';

type Textures = {
  baseColor?: Texture;
  metalness?: Texture;
  normal?: Texture;
  roughness?: Texture;
  ambientOcclussion?: Texture;
  emissive?: Texture;
  opacity?: Texture;
  transmission?: Texture;

  material_type?: string;
};

export type DT = {
  group?: Group;
  textures: Textures[];
  isOffset: boolean;
};

export const dt: DT = {
  group: undefined,
  textures: [],
  isOffset: false,
};
