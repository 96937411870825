import React from 'react';

import { useStore } from 'store';
import { Clipless } from '../clipless';
import { Billboards } from '../billboards';
import { BillboardCameraControls } from './BillboardCameraControls';
import { StaticControls } from './StaticControls';

export const AfterDT = () => {
  const billboards_enabled = useStore((state) => state.scene_controls.billboards_enabled);

  return (
    <React.Fragment>
      {billboards_enabled ? <BillboardCameraControls /> : <StaticControls />}

      <Billboards />
      <Clipless />
    </React.Fragment>
  );
};
