import React, { useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import { CatmullRomCurve3, TubeGeometry } from 'three';

import { getRulerText } from 'utils/conversion';
import { Midpoint3D, Distance3D, PointOnALine3D } from 'utils/math';

type PipeRulerProps = {
  ruler: any;
  size: any;
  visible: any;
};

export const PipeRuler = ({ ruler, size }: PipeRulerProps) => {
  const camera = useThree((state) => state.camera);

  const [path0, setPath0] = useState<any>({});
  const [path1, setPath1] = useState<any>({});
  const [count, setCount] = useState<number>(0);
  const [once, setOnce] = useState<boolean>(false);

  const textRef = React.useRef<any>(null);

  const style_config = ruler[2];

  const { unit, precision, text_color, text_stroke_color, text_stroke_size } = style_config;

  const midpoint = Midpoint3D({ p0: ruler[0], p1: ruler[1] });

  const lineLength = Distance3D({ p0: ruler[0], p1: ruler[1] });

  const avgLength = (size.x + size.y + size.z) / 3;

  const biggest = Math.max(size.x, size.y, size.z);

  const fontSize = (avgLength / 25) * ((2 + lineLength / biggest) / 3) * 1.5;

  useFrame(() => {
    textRef.current.lookAt(camera.position.x, camera.position.y, camera.position.z);

    if (count < 10) {
      setCount(count + 1);
    } else if (!once) {
      setOnce(true);
      const path0 = [
        ruler[0],
        PointOnALine3D({
          p0: ruler[0],
          p1: midpoint,
          d: lineLength / 2 - textRef.current.geometry.boundingBox.max.x * 1.5,
        }),
      ];

      const path1 = [
        ruler[1],
        PointOnALine3D({
          p0: ruler[1],
          p1: midpoint,
          d: lineLength / 2 - textRef.current.geometry.boundingBox.max.x * 1.5,
        }),
      ];

      const catPath0 = new CatmullRomCurve3(path0);
      const catPath1 = new CatmullRomCurve3(path1);

      const gPath0 = new TubeGeometry(catPath0, 32, 0.05, 18, true);

      const gPath1 = new TubeGeometry(catPath1, 32, 0.05, 18, true);

      setPath0(gPath0);
      setPath1(gPath1);
    }
  });

  return (
    <>
      <Text
        ref={textRef}
        color={text_color}
        strokeWidth={(fontSize / 75) * text_stroke_size}
        strokeOpacity={1}
        strokeColor={text_stroke_color}
        anchorX="center"
        anchorY="middle"
        fontSize={fontSize}
        position={midpoint}
        matrixWorldAutoUpdate={true}
      >
        {getRulerText(lineLength, unit, precision)}
      </Text>
      {once && (
        <>
          <mesh geometry={path0}>
            <meshStandardMaterial attach="material" roughness={1} metalness={0} />
          </mesh>
          <mesh geometry={path1}>
            <meshStandardMaterial attach="material" roughness={1} metalness={0} />
          </mesh>
        </>
      )}
    </>
  );
};
