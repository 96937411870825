import { useStore, useVItem } from 'store';
import { AnalyticsData } from 'utils/handleAnalytics';

export type UseSendAnalyticsData = {
  event_type: string;
};

export const useAnalyticsData = ({ event_type }: UseSendAnalyticsData): AnalyticsData => {
  const model_store_id = useStore((state) => state.store_id);
  const store_domain = useStore((state) => state.store_settings.store_domain);
  const utm_source = useStore((state) => state.params.utm_source);
  const utm_medium = useStore((state) => state.params.utm_medium);
  const utm_campaign = useStore((state) => state.params.utm_campaign);
  const origin = useStore((state) => state.params.origin);
  const viewer_session_id = useStore((state) => state.params.viewer_session_id);
  const ecommerce_session_id = useStore((state) => state.params.ecommerce_session_id);
  const levar_session_id = useStore((state) => state.params.levar_session_id);
  const levar_pixel_id = useStore((state) => state.params.levar_pixel_id);
  const model_3d_id = useStore((state) => state.model_3d_id);

  const {
    id: variant_id = '',
    product_id = 0,
    referer: referrer = '',
    product_title: title = '',
    subTitle = '',
    store_id: ecommStoreID = ''
  } = useVItem() || {};

  // console.log('ecommStoreID', ecommStoreID)
  // console.log('modelStoreID', model_store_id)

  return {
    created_at: new Date().toISOString(),
    device_type: window.navigator.userAgent,
    domain: window.location.href,
    ecommerce_session_id,
    event_type,
    levar_pixel_id,
    levar_session_id,
    model_3d_id,
    origin,
    product_id,
    product_title: title,
    referrer,
    store_id: ecommStoreID ?? model_store_id,
    store_domain,
    utm_campaign,
    utm_medium,
    utm_source,
    variant_id,
    variant_title: subTitle,
    viewer_session_id,
  };
};

// ?ecomm=shp_32935870599_6840986402951_40286804476039
// &levar_pixel_id=1f2e7d6f-6a76-467e-86c7-42aa6846621e
// &levar_session_id=1c91fdc8-e125-4ff8-bb33-50f3dc626610