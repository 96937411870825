import { SingleMat } from './SingleMat';
import { DoubleMat } from './DoubleMat';

type ChannelsProps = {
  bundle: string;
  materials: string;
  model_url: string;
  store_id: string;
};

export const Channels = ({
  bundle,
  materials,
  model_url,
  store_id
}: ChannelsProps) => {
  if (materials.length > 1) {
    return DoubleMat({
      bundle,
      materials,
      model_url,
      store_id
    });
  } else {
    return SingleMat({
      bundle,
      materials,
      model_url,
      store_id
    });
  }
};
