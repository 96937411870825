import React from 'react';
import { Box, Container, Grid } from '@mui/material';

import { getDt } from 'utils/three_helpers';
import { LoadingVariant } from 'components/indicators';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useAppLayoutContext } from './AppLayoutContext';
import { useStore, useMItem } from 'store';
import { useCheckFullScreen } from './useCheckFullScreen';

type AppLayoutProps = {
  children?: any;
};

export const AppLayout = ({ children: _children = null }: AppLayoutProps) => {
  const {
    visibleTopBorder,
    visibleHeaderPanel,
    visibleRightPanel,
    visibleLeftControlsPanel,
    visibleBottomControlsPanel,
    containerProps,
    headerPanelProps: header,
    rightPanelProps: rightPanel,
    leftControlsPanelProps: leftControlsPanel,
    bottomControlsPanelProps: bottomControlsPanel,
  } = useAppLayoutContext();
  const fullScreen = useCheckFullScreen();
  const { downSm, downMd, downLg } = useBreakpoints();

  const variant_type = useStore((state) => state.params.variant_type); // GHOST_NEW

  const status = useStore((state) => state.loading.status);
  const text = useStore((state) => state.loading.text);
  const store_id = useStore((state) => state.store_id);
  const mList = useStore((state) => state.mList) || {};
  const vList = useStore((state) => state.vList) || {};
  const {
    model_type = '',
  } = useMItem() || {};

  const children = (
    <>
      {_children}

      <LoadingVariant loading={status === 'model'} text={text} sx={{ position: 'absolute' }} />
    </>
  );

  //*** EVENT LISTENER FOR POST MESSAGE FOR PDP VARIANT SWITCH ***//
  // TODO: Make into Callback()
  React.useEffect(() => {
    async function handleEvent(event: any) {
      if (event?.data?.type === 'levar_message') {

        const modelKey = (model_type !== '' && model_type) ? model_type : 'model_3d_id';

        const data: any = await getDt({
          mList,
          model_3d_id: vList[event.data.variant_id][modelKey as keyof typeof vList[string]],
          // model_3d_id: vList[event.data.variant_id].model_3d_id,
          store_id,
          variant_type
        });

        // const val = await getDt({ vList, variant_id: event.data.variant_id, store_id, variant_type });

        // useStore.setState(() => ({ vItem: val?.vItem, group: val?.group, textures: val?.textures }));
        if (data) {
          const {
            mItem,
            group,
            textures
          } = data;

          useStore.setState(() => ({
            mItem,
            model_3d_id: vList[event.data.variant_id][modelKey as keyof typeof vList[string]],
            // model_3d_id: vList[event.data.variant_id].model_3d_id,
            group,
            textures,
            vItem: { ...vList[event.data.variant_id], id: event.data.variant_id },
          }));
        }
      }
    }
    window.addEventListener("message", handleEvent);

    return () =>
      window.removeEventListener("message", handleEvent);
    // eslint-disable-next-line
  }, []);


  if (fullScreen) {
    return children;
  }

  /**
  * Fullscreen OFF Viewer
  */
  return (
    <>
      {visibleTopBorder && (
        <Box component="div" sx={{ width: '100vw', height: '2rem', bgcolor: (theme: any) => theme.palette.primary.main }} />
      )}

      <Container {...containerProps}>
        <Grid container spacing={3}>
          {visibleHeaderPanel && header.contentComponent && (
            <Grid item xs={12}>
              <header.contentComponent {...header.contentComponentProps} />
            </Grid>
          )}

          <Grid
            item
            xs={downMd ? 12 : visibleRightPanel && rightPanel.contentComponent ? (downLg ? 8 : 9) : 12}
            container
            spacing={3}
            sx={{ alignItems: 'flex-start', height: '100%' }}
          >
            {visibleLeftControlsPanel && leftControlsPanel.contentComponent && (
              <Grid item {...(downMd ? { xs: 12 } : {})}>
                <leftControlsPanel.contentComponent {...leftControlsPanel.contentComponentProps} />
              </Grid>
            )}

            <Grid item container spacing={3} sx={{ flex: 1 }}>
              {/* VIEWER */}
              <Grid item xs={12}>
                <Box
                  component="div"
                  sx={{
                    height: fullScreen ? '100vh' : downSm ? 300 : downMd ? 400 : 600,
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  {children}
                </Box>
              </Grid>

              {/* VIEW IN SPACE (BTN) CUSTOM VARIANT (BTN) */}
              {!fullScreen && visibleBottomControlsPanel && bottomControlsPanel.contentComponent && (
                <Grid item xs={12}>
                  <bottomControlsPanel.contentComponent {...bottomControlsPanel.contentComponentProps} />
                </Grid>
              )}
            </Grid>

          </Grid>

          {visibleRightPanel && rightPanel.contentComponent && (
            <Grid item xs={downMd ? 12 : downLg ? 4 : 3} sx={{ height: '100%', mt: downLg ? 4 : 0 }}>
              <rightPanel.contentComponent {...rightPanel.contentComponentProps} />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};
