export const ft_m = (ft: number) => ft * 0.3048;

export const m_ft = (m: number) => m / 0.3048;

export const in_cm = (i: number) => i * 2.54;

export const cm_in = (cm: number) => cm / 2.54;

export const cm_m = (cm: number) => cm / 100;

export const m_cm = (m: number) => m * 100;

export const in_m = (i: number) => i * 0.0254;

export const m_in = (m: number) => m / 0.0254;

export const round1 = (i: number) => Math.round(i * 10) / 10;

export const round2 = (i: number) => Math.round(i * 100) / 100;

export const getRulerText = (val: number, unit: string, precision: number, override?: any) => {
  if (override) return String(override);

  switch (unit) {
    case 'in':
      if (precision === 0) {
        return `${Math.round(m_in(val))}in`;
      } else if (precision === 1) {
        return `${round1(m_in(val))}in`;
      } else {
        return `${round2(m_in(val))}in`;
      }
    case 'cm':
      if (precision === 0) {
        return `${Math.round(m_cm(val))}cm`;
      } else if (precision === 1) {
        return `${round1(m_cm(val))}cm`;
      } else {
        return `${round2(m_cm(val))}cm`;
      }
    case 'm':
      if (precision === 0) {
        return `${Math.round(val)}m`;
      } else if (precision === 1) {
        return `${round1(val)}m`;
      } else {
        return `${round2(val)}m`;
      }
    case 'ft':
      if (precision === 0) {
        return `${Math.round(m_ft(val))}ft`;
      } else if (precision === 1) {
        return `${round1(m_ft(val))}ft`;
      } else {
        return `${round2(m_ft(val))}ft`;
      }
    default:
      if (precision === 0) {
        return `${Math.round(val)}m`;
      } else if (precision === 1) {
        return `${round1(val)}m`;
      } else {
        return `${round2(val)}m`;
      }
  }
};
