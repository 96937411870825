import { createContext, useContext, useState } from 'react';
import { BoxProps } from '@mui/material';

import { useGenerateTopDrawer } from './useGenerateTopDrawer';
import { useGenerateBottomDrawer } from './useGenerateBottomDrawer';
import { useGenerateLeftDrawer } from './useGenerateLeftDrawer';
import { useGenerateRightDrawer } from './useGenerateRightDrawer';
import { DrawerProps } from './Drawer';
import { ViewerLayout } from './ViewerLayout';

type ContainerProps = BoxProps & {
  containerId?: string;
  fullScreen?: boolean;
  width?: number | string;
  height?: number | string;
};

type LayoutDrawerProps = Omit<DrawerProps, 'anchor' | 'open'>;

export type ViewerLayoutContextType = {
  visibleTopDrawer: boolean;
  visibleLeftDrawer: boolean;
  visibleRightDrawer: boolean;
  visibleBottomDrawer: boolean;
  visibleHelp: boolean;
  containerProps: ContainerProps;
  topDrawerProps: LayoutDrawerProps;
  leftDrawerProps: LayoutDrawerProps;
  rightDrawerProps: LayoutDrawerProps;
  bottomDrawerProps: LayoutDrawerProps;
  updateViewerLayout: (props: Partial<Omit<ViewerLayoutContextType, 'updateViewerLayout'>>) => void;
};

const defaultViewerLayoutContextValue: ViewerLayoutContextType = {
  visibleTopDrawer: false,
  visibleLeftDrawer: false,
  visibleRightDrawer: false,
  visibleBottomDrawer: false,
  visibleHelp: false,
  containerProps: {},
  topDrawerProps: {},
  leftDrawerProps: {},
  rightDrawerProps: {},
  bottomDrawerProps: {},
  updateViewerLayout: () => {},
};

export const ViewerLayoutContext = createContext<ViewerLayoutContextType>(defaultViewerLayoutContextValue);

export type ViewerLayoutContextProviderProps = {
  children?: any;
};

export const ViewerLayoutContextProvider = ({ children = null }: ViewerLayoutContextProviderProps) => {
  const [contextValue, setContextValue] = useState<ViewerLayoutContextType>(defaultViewerLayoutContextValue);

  const updateViewerLayout = (props: Partial<Omit<ViewerLayoutContextType, 'updateViewerLayout'>>) =>
    setContextValue((prev) => ({ ...prev, ...props }));

  const { visibleTopDrawer = false, topDrawerProps = {} } = useGenerateTopDrawer();
  const { visibleBottomDrawer = false, bottomDrawerProps = {} } = useGenerateBottomDrawer();
  const { visibleLeftDrawer = false, leftDrawerProps = {} } = useGenerateLeftDrawer({
    viewerLayoutContext: {
      ...contextValue,
      updateViewerLayout,
    },
  });
  const { visibleRightDrawer = false, rightDrawerProps = {} } = useGenerateRightDrawer();

  return (
    <ViewerLayoutContext.Provider
      value={{
        ...contextValue,
        visibleTopDrawer,
        visibleBottomDrawer,
        visibleLeftDrawer,
        visibleRightDrawer,
        topDrawerProps,
        bottomDrawerProps,
        leftDrawerProps,
        rightDrawerProps,
        updateViewerLayout,
      }}
    >
      <ViewerLayout>{children}</ViewerLayout>
    </ViewerLayoutContext.Provider>
  );
};

export const useViewerLayoutContext = () => {
  return useContext(ViewerLayoutContext);
};
