import { Canvas } from '@react-three/fiber';
import { LinearToneMapping, SRGBColorSpace } from 'three';

type ShadeCanvasProps = {
  children?: any;
};

export const ShadeCanvas = ({ children = null }: ShadeCanvasProps) => {

  return (
    <Canvas
      camera={{ position: [0, 0, 1], fov: 54, near: 0.001, far: 30 }}
      dpr={window.devicePixelRatio}
      // colorscience
      legacy={true}
      onCreated={({ gl }) => {
        gl.toneMapping = LinearToneMapping;
        gl.outputColorSpace = SRGBColorSpace;
        gl.toneMappingExposure = 1;
      }}
    >
      {children}
    </Canvas >
  );
};
