import React, { useEffect, useState } from 'react';
import { Tab } from '@mui/material';

import { SizedScrollTabs } from 'components/@styled-mui';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { VariantSliderItem } from './VariantSliderItem';

export type VariantSliderProps = {
  images?: string[];
  activeIndex?: number;
  onItem?: (props: { pointIndex: number }) => void;
  orientation?: 'horizontal' | 'vertical';
};

export const VariantSlider = ({ images = [], activeIndex, onItem = () => { }, orientation }: VariantSliderProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { downSm, downMd } = useBreakpoints();

  useEffect(() => {
    if (activeIndex !== undefined) {
      setActiveTab(activeIndex);
    }
  }, [activeIndex]);

  return (
    <SizedScrollTabs
      value={activeTab}
      onChange={(_, newValue) => setActiveTab(newValue)}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="Color picker tabs"
      orientation={orientation ? orientation : downMd ? 'horizontal' : 'vertical'}
      scrolButtonSize={48}
      itemSize={100}
      itemDistance={30}
      displayCount={downSm ? 2 : 3}
      sx={{ margin: 'auto' }}
      selectionFollowsFocus
    >
      {images.map((image, index) => {
        return (
          <Tab
            key={`color-selector-${index}`}
            disableRipple
            disableFocusRipple
            component="div"
            sx={{ cursor: 'default' }}
            label={
              <VariantSliderItem
                image={image}
                active={index === activeTab}
                onClick={() => onItem({ pointIndex: index })}
              />
            }
          />
        );
      })}
    </SizedScrollTabs>
  );
};
