import React from 'react';
import { useTheme } from '@mui/material';
import Image from 'mui-image';

import { SizedButtonBase, SizedButtonBaseProps } from 'components/@styled-mui';

type VariantSliderItemProps = SizedButtonBaseProps & {
  active?: boolean;
  image: string;
};

export const VariantSliderItem = ({ active = false, image, size = 100, ...rest }: VariantSliderItemProps) => {
  const theme = useTheme();

  return (
    <SizedButtonBase
      size={size}
      sx={{
        borderRadius: 1,
        border: `3px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
      }}
      {...rest}
    >
      <Image src={image} duration={1000} />
    </SizedButtonBase>
  );
};
