import { createContext, useCallback, useEffect, useState } from 'react';

import { fetchVariants } from 'apis';
import { useStore, useCheckCanAr } from 'store';

export const VariantsContext = createContext(null);

export type VariantsContextProviderProps = {
  children?: any;
};

export const VariantsContextProvider = ({ children = null }: VariantsContextProviderProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const status = useStore((state) => state.loading.status);
  // PARAMS
  const variant_id_deprecate = useStore((state) => state.params.variant_id); // if Old VARIANT ID DEPRECATE
  const ecommerce_id = useStore((state) => state.params.ecommerce_id); // if ECOMM
  const model_3d_id = useStore((state) => state.params.model_3d_id); // if GHOST || MODEL
  const variant_type = useStore((state) => state.params.variant_type); // ghost || model || shopify || bco || cus (PARAM)
  const auto_ar_launch = useStore((state) => state.params.auto_ar_launch); // AR auto launch
  // HELPERS
  const canAr = useCheckCanAr();

  const initVariants = useCallback(async () => {
    useStore.setState({
      loading: {
        status: 'variant',
        text: (canAr && auto_ar_launch) ? 'Launching...' : 'Loading Variant...'
      }
    });
    setLoading(true);

    // variant_id or model_3d_id is empty string so case passes
    if (!variant_id_deprecate && !model_3d_id && !ecommerce_id) {
      useStore.setState({ loading: { status: 'error', text: 'Missing Variant ID or Model ID or Ecomm ID' } });
      setLoading(false);
      return;
    }

    /*
    * (ghost) && (model) => only needs model_3d_id
    *   model_3d_id set in params
    * (ecomm) => needs model_3d_id && ecommerce_id
    *   Have to set model_3d_id after fetchVariants
    * (variant) => Deprecated Old way Extra API
    *   Have to set ecommerce_id after fetchVariants
    */
    const data = await fetchVariants(variant_id_deprecate, ecommerce_id, model_3d_id, variant_type);

    if (!data) {
      useStore.setState({ loading: { status: 'error', text: 'Asset is not available' } });
      setLoading(false);
      return;
    }

    // _ecommerce_id used for old variants pls deprecated
    const { vList, mList, model_3d_id: _model_3d_id_confirmed, ecommerce_id: _ecommerce_id } = data;

    console.group('INFO');
    console.log('vList:::', vList)
    console.log('mList:::', mList)
    console.groupEnd();

    if (!vList) {
      useStore.setState({ loading: { status: 'error', text: 'Ecommerce List is not available' } });
      setLoading(false);
      return;
    }

    if (!mList) {
      useStore.setState({ loading: { status: 'error', text: 'Model List is not available' } });
      setLoading(false);
      return;
    }

    const vItem = vList[_ecommerce_id]; // Current Variant // breaking here
    const mItem = mList[_model_3d_id_confirmed]; // Current Model (_model_3d_id_confirmed for since dont have before)

    if (!mItem && variant_type === 'model') {
      useStore.setState({ loading: { status: 'error', text: 'Model Asset is not available' } });
      setLoading(false);
      return;
    }

    // variant type shp etc does not have both vItem and mItem
    if ((!vItem || !mItem) && (variant_type !== 'model' && variant_type !== 'ghost')) {
      useStore.setState({ loading: { status: 'error', text: 'Variant is not available' } });
      setLoading(false);
      return;
    }

    if (mItem['model_status'] === 'off') {
      useStore.setState({ loading: { status: 'error', text: 'Model Turned Off' } });
      setLoading(false);
      return;
    }

    // Setting Zustand State for App
    useStore.setState({
      variant_id: _ecommerce_id, // problem here maybe (technically should be ecomm_id throughout whole store but pain to refactor)
      ...data, // setting again model_3d_id && store_id
      vItem: { ...vItem, id: _ecommerce_id },
      mItem: { ...mItem, id: _model_3d_id_confirmed }
    });
    setLoading(false);
    // eslint-disable-next-line
  }, [variant_type, ecommerce_id, model_3d_id]);

  useEffect(() => {
    initVariants();
  }, [initVariants]);

  return (
    <VariantsContext.Provider value={null}>{loading || status === 'error' ? null : children}</VariantsContext.Provider>
  );
};