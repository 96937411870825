import { useStore, useVItem, useMItem } from 'store';
import { BillboardProps } from './Billboard';

interface BillboardInfo {
  image_1?: { src: string };
  h1?: { text: string };
  p?: { text: string };
}

export const useBillboardProps = (): BillboardProps => {
  const { product_title: headerTitle = '', priceStr: price = '' } = useVItem() || {};
  const { billboard_list = [] } = useMItem() || {};
  const visibleAddToCartButton = useStore((state) => state.params.addToCartBtn);
  const active_board = useStore((state) => state.scene_controls.active_board);

  // const billboardInfo = active_board !== undefined ? billboard_list[active_board] || { info: {} } : { info: {} };
  const billboardInfo: { info: BillboardInfo } = active_board !== undefined
    ? { info: { ...billboard_list[active_board]?.info } }
    : { info: {} };

  const {
    image_1: { src: thumbnail = '' } = {},
    h1: { text: footerTitle = '' } = {},
    p: { text: description = '' } = {},
  } = billboardInfo.info || {};

  const handleClickPaginationButton = (active_board: number) => {
    useStore.setState((state) => ({
      scene_controls: {
        ...state.scene_controls,
        active_board,
      },
      billboard_settings: {
        ...state.billboard_settings,
        billboard_listener_click: true,
      },
    }));
  };

  const onPrev = () => {
    if (active_board !== undefined && active_board > 0) {
      handleClickPaginationButton(active_board - 1);
    }
  };

  const onRefresh = () => {
    handleClickPaginationButton(0);
  };

  const onNext = () => {
    if (active_board !== undefined) {
      handleClickPaginationButton(active_board + 1);
    }
  };

  const current = active_board !== undefined ? active_board + 1 : undefined;
  const total = billboard_list.length;

  return {
    headerTitle,
    price,
    thumbnail,
    footerTitle,
    description,
    onPrev,
    onRefresh,
    onNext,
    current,
    total,
    visibleAddToCartButton,
  };
};
