import { Billboard } from 'components/billboard';
import { useBillboardProps } from 'components/billboard/useBillboardProps';
import { useStore } from 'store';
import { PanelProps } from './AppLayoutContext';

export const useGenerateRightPanel = (): {
  visibleRightPanel?: boolean;
  rightPanelProps?: PanelProps;
} => {
  const billboardProps = useBillboardProps();
  const { headerTitle, price } = billboardProps;
  const right_header = useStore((state) => state.params.right_header) && (headerTitle || price);
  const billboards_enabled = useStore((state) => state.scene_controls.billboards_enabled);
  const addToCartBtn = useStore((state) => state.params.addToCartBtn);

  if (!right_header && !billboards_enabled && !addToCartBtn) {
    return {};
  }

  return {
    visibleRightPanel: true,
    rightPanelProps: {
      contentComponent: Billboard,
      contentComponentProps: billboardProps,
    },
  };
};
