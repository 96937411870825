import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  Grid,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  IconButton,
  TextField
} from '@mui/material';

import { ft_m } from 'utils/conversion';
import { useMItem, useStore } from 'store';
import { Close } from '@mui/icons-material';
import { hankUrlAr } from 'configs';
// import { ReactComponent as HankPhoneIcon } from "assets/icons/hanklessPhoneIcon.svg";
// import { ReactComponent as Hank3DIcon } from "assets/icons/hankless3DIcon.svg";
// import firstImage from "assets/icons/lighting_distance_icon.png";

type ArActivationDialogProps = Omit<DialogProps, 'open' | 'onClose'> & {
  open?: boolean;
  onClose?: () => void;
  intent?: string;
};

export const ArActivationDialog = ({
  open = false,
  onClose = () => { },
  intent = '',
  ...rest
}: ArActivationDialogProps) => {
  const [{ offset, measurement }, setValues] = useState<{ offset: number; measurement: string }>({
    offset: 0,
    measurement: 'feet',
  });
  const [blocked, setBlocked] = useState<boolean>(true);
  const [ready, setReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const store_id = useStore((state) => state.store_id);

  const { ardt = '' } = useMItem() || {};


  React.useEffect(() => {
    let isComponentMounted = true;

    if (isComponentMounted) {
      setReady(true)
    }

    return function cleanup() {
      isComponentMounted = false;
    };
  }, []);

  const iconMeasurement = (type: any) => {
    switch (type) {
      case 'feet':
        return 'ft';
      case 'meters':
        return 'm';
      default:
        return '';
    }
  };

  // Check to make sure value is within range
  React.useEffect(() => {
    if (ready) {
      // console.log('run check', offset, measurement)
      if (measurement === "feet") {
        if ((offset >= 5.9) && (offset <= 30)) {
          setBlocked(false)
        } else {
          setBlocked(true)
        }
      } else {
        if ((offset >= 1.8) && (offset <= 9)) {
          setBlocked(false)
        } else {
          setBlocked(true)
        }
      }
    }
  }, [measurement, offset, ready])


  const sendToHank = (e: any) => {
    e.preventDefault();
    setLoading(true);

    let size_offset: number;

    if (measurement === "feet") {
      size_offset = ft_m(offset)
    } else {
      size_offset = offset;
    };

    const size = size_offset.toFixed(1)

    let deURL: string = "";

    if (intent === "willQL") {
      deURL = `${hankUrlAr}/${store_id}/${ardt}/${ardt}_${size}.usdz#allowsContentScaling=0`;
    } else if (intent === "willSV") {
      const levar_intent: string = "ar_preferred" // Intent
      const fallback_url: string = "https://developers.google.com/ar;" // if Google Play Services for AR isn't present, Scene Viewer launches the URL you set in the
      const dt: string = `${hankUrlAr}/${store_id}/${ardt}/${ardt}_${size}.glb`;
      const sceneViewerUrl: string = `#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${fallback_url}`
      deURL = `intent://arvr.google.com/scene-viewer/1.1?file=${dt}&mode=${levar_intent}&resizable=false${sceneViewerUrl}end;`;
    }

    let anchor: any = document.getElementById("hank-link");

    if (!anchor) {
      anchor = document.createElement('a');
      anchor.setAttribute('id', 'hank-link')
      anchor.setAttribute('rel', 'ar');
      anchor.appendChild(document.createElement('img'));
      document.body.appendChild(anchor)
    }

    anchor.setAttribute('href', deURL);

    anchor.click();

    setLoading(false);
  };


  return (
    <div>
      <Dialog
        {...rest}
        aria-labelledby="dispay-prompt"
        aria-describedby="dispay-prompt-modal"
        open={open}
        onClose={() => onClose()}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
      >
        <DialogTitle component="h6" sx={{ fontSize: 14, color: (theme) => theme.palette.text.secondary }}>
          Placement Helper
          <IconButton aria-label="close" onClick={() => onClose()} sx={{ position: 'absolute', top: 8, right: 12 }}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ py: 2 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>

            {/* <Grid item xs={3}>
              <img src={firstImage} alt="off the ground" style={{ width: '100%' }} />
            </Grid> */}

            {/* <Grid item xs={12}>
              <Typography color="textSecondary" variant="body2">Choose the distance off the ground that you'd like the bottom of the fixture to hang</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid> */}

            {/* <Grid item xs={9}>
              <Typography color="textSecondary" variant="body2">Scan your device's camera across the ground until the object is placed.</Typography>
            </Grid>

            <Grid item xs={3}>
              <HankPhoneIcon style={{ height: 64, width: 64 }} />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={3}>
              <Hank3DIcon style={{ height: 64, width: 64 }} />
            </Grid>

            <Grid item xs={9}>
              <Typography color="textSecondary" variant="body2">Point your camera up towards the ceiling to see the fixture. Touch the floor under the fixture to move and rotate</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid> */}

            {/* <Grid item xs={12}>
              <Typography color="textSecondary" variant="subtitle2" align="center">
                {measurement === "feet" && 'Choose between 5.9 and 30 Feet'}
                {measurement === "meters" && 'Choose between 1.8 and 9 Meters'}
              </Typography>
            </Grid> */}

            <Grid item xs={12}>
              <Typography color="textSecondary" variant="subtitle2" align="left">
                {measurement === "feet" && 'Select the distance the light should be from the ground 6-30 Feet'}
                {measurement === "meters" && 'Choose between 1.8 and 9 Meters'}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" sx={{ width: '100%' }}>

                <Grid container spacing={2} flexDirection="column">

                  <Grid item xs={12}>
                    <OutlinedInput
                      id="offset"
                      fullWidth
                      value={`${offset}`}
                      onChange={(e) => {
                        const str = e.target.value;
                        setValues((prev) => ({ ...prev, offset: str ? parseFloat(str) : 0 }));
                      }}
                      endAdornment={<InputAdornment position="end">{iconMeasurement(measurement)}</InputAdornment>}
                      aria-describedby="outlined-height-helper-text"
                      type="number"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="mesurement-select-label"
                      fullWidth
                      select
                      label="Choose Measurement"
                      value={measurement}
                      onChange={(e) => {
                        setValues((prev) => ({ ...prev, measurement: e.target.value }))
                      }}
                      variant="outlined"
                    >
                      <MenuItem value="meters">Meters</MenuItem>
                      <MenuItem value="feet">Feet</MenuItem>
                    </TextField>
                  </Grid>

                </Grid>
              </FormControl>
            </Grid>

          </Grid>
        </DialogContent>

        {/* BUTTON */}
        <DialogTitle>
          <Button fullWidth variant="contained" onClick={(e) => sendToHank(e)} disabled={blocked}>
            {!loading ? 'Begin' : <div>Loading...</div>}
          </Button>
        </DialogTitle>

      </Dialog>
    </div>
  );
};
