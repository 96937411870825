import React, { useState } from 'react';
import { SpeedDialIcon } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import {
  SizedSpeedDial,
  SizedSpeedDialAction,
  SizedSpeedDialActionProps,
  SizedSpeedDialProps,
} from 'components/@styled-mui';

export type Action = Omit<SizedSpeedDialActionProps, 'onClick'> & {
  onClick?: () => void;
};

export type HelpControlsProps = {
  speedDialProps?: Omit<SizedSpeedDialProps, 'ariaLabel'>;
  actions?: Action[];
};

export const HelpControls = ({ speedDialProps, actions = [] }: HelpControlsProps) => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <SizedSpeedDial
      ariaLabel="Helper controls"
      icon={<SpeedDialIcon icon={<Add />} openIcon={<Remove />} />}
      direction="down"
      open={open}
      onClick={(e) => {
        if (e.target !== document.querySelector("#Helpercontrols-actions")) {
          setOpen(!open)
        }
      }}
      {...speedDialProps}
    >
      {actions.map(({ onClick = () => { }, size, ...rest }, index) => (
        <SizedSpeedDialAction
          key={`helper-control-${index}`}
          // tooltipPlacement="right"
          // tooltipTitle="here"
          // tooltipOpen
          // arrow
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          size={size ? size : speedDialProps?.size}
          direction="down"
          {...rest}
        />
      ))}
    </SizedSpeedDial>
  );
};
