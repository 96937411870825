import React from 'react';
import { Box, BoxProps, Grid } from '@mui/material';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { SwitchVariantContextProvider } from './SwitchVariantContext';
import { VariantOptionSelector } from './VariantOptionSelector';
import { VariantSelector } from './VariantSelector';

type SwitchVariantProps = {
  containerStyle?: BoxProps['sx'];
};

export const SwitchVariant = ({ containerStyle = {} }: SwitchVariantProps) => {
  const { downMd } = useBreakpoints();

  return (
    <Box component="div" sx={containerStyle}>
      <SwitchVariantContextProvider>
        <Grid container spacing={downMd ? 2 : 0}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: downMd ? 'center' : 'flex-start' }}>
            {/* Drop Down */}
            <VariantOptionSelector />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {/* Selection Tiles */}
            <VariantSelector />
          </Grid>
        </Grid>
      </SwitchVariantContextProvider>
    </Box>
  );
};
