export type Params = {
  cLogo: string;
  home_url: string;
  origin: string;
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
  param_type: string;
  variant_id: string;
  model_3d_id: string;
  ecommerce_id: string;
  variant_type: string;

  /**
  * Analytics
  */
  levar_pixel_id: string;
  viewer_session_id: string;
  ecommerce_session_id: string;
  levar_session_id: string;
  qr_mobile_launch: boolean;

  /**
   * Layout
   */
  addToCartBtn: boolean;
  billboards: boolean;
  fullscreen: boolean;
  header: boolean;
  left_slider: boolean;
  right_header: boolean;
  top_border: boolean;
  bottom_drawer: boolean;
  view_in_space_off: boolean;
  social_share: boolean;
  // see_ar_btn: boolean;
  autorotation: string;

  /**
   * Activation config
   */
  hank_modal: string,
  auto_ar_launch: boolean,

  /**
   * PDP config
   */
  viewer_type: string,

  /**
   * TODO: Confirm to remove
   */
  ar_activation: boolean;
};

export const params: Params = {
  cLogo: '',
  home_url: '',
  origin: '',
  utm_campaign: '',
  utm_medium: '',
  utm_source: '',
  model_3d_id: '',
  param_type: '',
  variant_id: '',
  variant_type: '',
  ecommerce_id: '',

  /**
   * Analytics
   */
  levar_pixel_id: '',
  viewer_session_id: '',
  ecommerce_session_id: '',
  levar_session_id: '',
  qr_mobile_launch: false,

  /**
   * Layout
   */
  addToCartBtn: false,
  billboards: false,
  fullscreen: false,
  header: false,
  left_slider: false,
  right_header: false,
  top_border: false,
  bottom_drawer: false,
  view_in_space_off: false,
  social_share: false,
  // see_ar_btn: false,
  autorotation: '0',

  /**
   * Activation config
   */
  hank_modal: '',
  auto_ar_launch: false,

  /**
   * PDP config
   */
  viewer_type: '',

  /**
   * TODO: Confirm to remove
   */
  ar_activation: false
};
