import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material';

import { useThemeOptions } from './useThemeOptions';

type ThemeContextType = {
  toggleColorMode: () => void;
  updateTheme: (value: Partial<ThemeOptions>) => void;
};

const defaultThemeContextValue = {
  toggleColorMode: () => { },
  updateTheme: () => { },
};

const ThemeContext = createContext<ThemeContextType>(defaultThemeContextValue);

interface ThemeContextProviderProps {
  children?: any;
}

export const ThemeContextProvider = ({ children = null }: ThemeContextProviderProps) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const [customOptions, setCustomOptions] = useState<Partial<ThemeOptions>>({});
  const options = useThemeOptions(mode);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      updateTheme: (value: Partial<ThemeOptions>) => {
        if (mode === 'light') {
          setCustomOptions(value);
        }
      },
    }),
    [mode],
  );

  const theme = createTheme({ ...options, ...customOptions });

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
