import React, { useEffect, useCallback } from 'react';
import { useThree, useLoader } from '@react-three/fiber';
import { useBounds } from '@react-three/drei';
import { Box3, Vector3 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useStore } from 'store';
import { CreateMissingSpawn } from 'utils/math';
import { useDTManagerPacked } from './useDTManagerPacked';
// import { useDTManagerAnimation } from '../animation/useDTManagerAnimation';

function isCenteredOnYAxis(boundingBox: any, marginOfError = 0.05) {
  const centerY = (boundingBox.max.y + boundingBox.min.y) / 2;
  const height = boundingBox.max.y - boundingBox.min.y;
  const errorRange = height * marginOfError;

  if (centerY >= -errorRange && centerY <= errorRange) {
    return "centered";
  } else if (centerY > errorRange) {
    return "above";
  } else {
    return "below";
  }
}

export const DTManagerPackedGLB = () => {
  // console.log('***********DTManagerPackedGLB GEO_TYPE: 2***********')
  const binder = useBounds();
  const group = React.useRef<any>();
  const camera = useThree((state) => state.camera);

  const {
    bundle,
    camera_position,
    geo,
    model_url,
    store_id
  } = useDTManagerPacked();

  const geoURL = `${model_url}/${store_id}/${bundle}/${geo}.glb`;

  const gltf = useLoader(GLTFLoader, geoURL);

  const handleUpdate = useCallback(() => {
    const boundingBox = new Box3().setFromObject(group.current as any);
    const size = boundingBox.getSize(new Vector3());

    // Custom Spawn
    if (camera_position && !camera_position.includes(0.0001)) {
      camera.position.set(...camera_position);
    } else {
      const missingSpawn = CreateMissingSpawn({ modelSize: size });
      camera.position.set(missingSpawn.x, missingSpawn.y, missingSpawn.z);
    }

    const position = isCenteredOnYAxis(boundingBox);

    if (position === "above" || position === "below") {
      if (group.current) group.current.position.y = -1 * (boundingBox.max.y + boundingBox.min.y) / 2;
    }

    binder.fit();

    useStore.setState({ model_loaded: true, model: { size, bounds: [], lengthSum: size.x + size.y + size.z } });

    // eslint-disable-next-line
  }, [group, binder, camera]);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  return (
    <group ref={group as any} scale={1}>
      <primitive name={'deliveryTarget'} object={gltf.scene} dispose={null} />
    </group>
  );
};