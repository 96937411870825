import React from 'react';

import { useStore } from 'store';
import { buildRulers } from 'utils/three_helpers';
import { LineRuler } from './LineRuler';
import { PipeRuler } from './PipeRuler';

export const Ruler = () => {
  const { rulers_enabled, rulers_visible } = useStore((state) => state.scene_controls);
  const model = useStore.getState().model;
  const rulers_data_sv = useStore.getState().scene_controls.rulers_data_sv; // Ruler data store and variant

  if (!rulers_enabled) {
    return null;
  }

  const rulers = buildRulers(rulers_data_sv, model);

  if (!rulers) return null;

  return (
    <group name="levar_fbx_ruler">
      {rulers.map((ruler: any, key: any) => (
        <React.Fragment key={key}>
          {ruler[2].type === 'line' && <LineRuler ruler={ruler} size={model.size} visible={rulers_visible} />}
          {ruler[2].type === 'pipe' && <PipeRuler ruler={ruler} size={model.size} visible={rulers_visible} />}
        </React.Fragment>
      ))}
    </group>
  );
};
