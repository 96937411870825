import React, { useState } from 'react';
import { Grid, Popover, MenuList, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { ArrowDropDown } from '@mui/icons-material';
import { DefaultIndicator } from 'components/indicators';

type SubOptions = {
  id: number;
  label: string;
};

type VariantItemDropdownProps = {
  currentSubOptions?: SubOptions[];
  activeSubOption?: number;
  handleClickItem?: (id: number) => void;
};

export const VariantItemDropdown = ({
  currentSubOptions = [],
  activeSubOption = -1,
  handleClickItem = () => { },
}: VariantItemDropdownProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { downMd } = useBreakpoints();

  let currLabel = currentSubOptions.find(({ id }) => id === activeSubOption)?.label || '';

  return (
    <Grid item xs={12} alignContent="center" justifyContent="center" sx={{ display: 'flex' }}>
      <LoadingButton loading={loading} variant="contained" onClick={(event) => setAnchorEl(event.currentTarget)} endIcon={<ArrowDropDown />}>
        {currLabel}
      </LoadingButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: downMd ? 'center' : 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: downMd ? 'center' : 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 120,
          },
        }}
      >
        {currentSubOptions.length > 0 ? (
          <MenuList autoFocusItem>
            {currentSubOptions.map(({ id, label }, index) => (
              <MenuItem
                key={`option-${index}`}
                selected={id === activeSubOption}
                onClick={async () => {
                  setLoading(true);

                  await handleClickItem(id);
                  setAnchorEl(null);

                  setLoading(false);
                }}
              >
                {label}
              </MenuItem>
            ))}
          </MenuList>
        ) : (
          <DefaultIndicator description="Sorry, no available options..." containerStyle={{ pb: 3 }} />
        )}
      </Popover>
    </Grid>
  );
};
