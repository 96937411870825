import { createContext, useCallback, useEffect, useState } from 'react';

import { Params } from 'App';
import { extractVariantId, setSessionID, parseBool } from 'utils/helpers';
import { useStore } from 'store';

export const ParamsContext = createContext(null);

type ParamsContextProviderProps = Partial<Params> & {
  children?: any;
};

export const ParamsContextProvider = ({
  origin = 'static',
  utm_campaign = '',
  utm_medium = '',
  utm_source = '',

  /**
  * Model Location
  */
  variant = '',
  ghost = '',
  model = '',
  ecomm = '',

  /**
  * Analytics
  */
  levar_pixel_id = '',
  viewer_session_id = '',
  ecommerce_session_id = '',
  levar_session_id = '',
  qr_mobile_launch = 'off',

  /**
   * Iniital layout: (Set Default Param Here)
   */
  add_to_cart = 'off',
  billboards = 'on',
  fullscreen = 'on',
  header = 'on',
  left_slider = 'on',
  right_header = 'on',
  top_border = 'on',
  bottom_drawer = 'on',
  view_in_space_off = 'off',
  social_share = 'off',
  // see_ar_btn = 'on',
  autorotation = '0',

  /**
  * Activation config
  */
  hank_modal = '',
  auto_ar_launch = 'off',

  /**
   * PDP config
   */
  viewer_type = '',

  /**
   * TODO: Confirm to remove
   */
  ar_activation = '',

  children = null,
}: ParamsContextProviderProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  const initSettings = useCallback(async () => {
    useStore.setState({
      loading: {
        status: 'params',
        text: 'Loading Params...'
      }
    });
    setLoading(true);

    // Determine VARIANT || GHOST || MODEL || ECOMM
    const {
      variant_id,
      ecommerce_id,
      variant_type,
      model_3d_id,
      param_type
    } = extractVariantId(variant, ghost, model, ecomm);

    /**
     * NOTE: This is where we set the values of the query params are stored in state
     */
    useStore.setState((state) => ({
      params: {
        ...state.params,
        origin,
        utm_campaign,
        utm_medium,
        utm_source,
        variant_id, // if VARIANT (Deprecated Backwards Compatible)
        ecommerce_id, // if Ecommerce ID
        model_3d_id, // if GHOST || MODEL
        variant_type, // VARIANT || GHOST || MODEL
        param_type, // ecomm || variant || model || ghost (first part ?=)

        levar_pixel_id: levar_pixel_id || setSessionID(),
        viewer_session_id: viewer_session_id || setSessionID(),
        ecommerce_session_id: ecommerce_session_id,
        levar_session_id: levar_session_id || setSessionID(),
        qr_mobile_launch: qr_mobile_launch === 'on',
        // qr_code_scanned: viewer_session_id ? true : false,

        /**
         * Iniital layout
         */
        addToCartBtn: add_to_cart === 'on',
        billboards: billboards === 'on',
        fullscreen: fullscreen === 'on',
        header: header === 'on',
        left_slider: left_slider === 'on',
        right_header: right_header === 'on',
        top_border: top_border === 'on',
        bottom_drawer: bottom_drawer === 'on',
        view_in_space_off: view_in_space_off === 'on',
        social_share: social_share === 'on',
        // see_ar_btn: see_ar_btn === 'on',
        autorotation,

        /**
         * Activation layout
         */
        hank_modal,
        auto_ar_launch: auto_ar_launch === 'on',

        /**
         * PDP config
         */
        viewer_type,

        /**
         * TODO: Confirm to remove
         */
        ar_activation: parseBool(ar_activation),
        // variant_switch_ui: Boolean(variant_switch_ui),
      },
    }));

    setLoading(false);
  }, [
    origin,
    utm_campaign,
    utm_medium,
    utm_source,
    /**
    * Model Location
    */
    variant,
    ghost,
    model,
    ecomm,

    /**
    * Analytics
    */
    levar_pixel_id,
    viewer_session_id,
    ecommerce_session_id,
    levar_session_id,
    qr_mobile_launch,

    /**
     * Config layout
     */
    add_to_cart,
    billboards,
    fullscreen,
    header,
    left_slider,
    right_header,
    top_border,
    bottom_drawer,
    view_in_space_off,
    social_share,
    // see_ar_btn,
    autorotation,

    /**
     * Activation config
     */
    hank_modal,
    auto_ar_launch,

    /**
     * PDP config (levar_debut)
     */
    viewer_type,

    /**
     * TODO: Deprecated need to remove from LSA
     */
    ar_activation
  ]);

  useEffect(() => {
    initSettings();
  }, [initSettings]);

  return <ParamsContext.Provider value={null}>{loading ? null : children}</ParamsContext.Provider>;
};
