import React, { useRef } from 'react';
import { OrbitControls } from '@react-three/drei';
import { OrbitControls as OrbitControlsType } from 'three/examples/jsm/controls/OrbitControls';

import { useStore } from 'store';
import { useOrbitControlsAutoRotate } from 'hooks/useOrbitControlsAutoRotate';

export const StaticControls = () => {
  const x = useStore((state) => state.model.size.x);
  const y = useStore((state) => state.model.size.y);
  const z = useStore((state) => state.model.size.z);
  const orbit = useStore.getState().scene_controls.orbit_controls_angles;

  const controls = useRef<OrbitControlsType>(null);
  useOrbitControlsAutoRotate({ controls: controls.current });

  return (
    <OrbitControls
      ref={controls as any}
      enablePan={false}
      enableDamping={true}
      dampingFactor={0.1}
      minDistance={x}
      maxDistance={(x + y + z) * 2}
      zoomSpeed={0.5}
      minPolarAngle={orbit[0]}
      maxPolarAngle={orbit[1]}
      minAzimuthAngle={orbit[2]}
      maxAzimuthAngle={orbit[3]}
    />
  );
};
