import React from 'react';
import { Button, ButtonProps, Grid, Typography } from '@mui/material';

import { BaseIndicator, BaseIndicatorProps } from './BaseIndicator';

export type DefaultIndicatorProps = Pick<BaseIndicatorProps, 'icon' | 'containerStyle'> & {
  title?: string;
  subtitle?: string;
  description?: string;
  actions?: ButtonProps &
    {
      label: string;
    }[];
};

export const DefaultIndicator = ({
  icon,
  title,
  subtitle,
  description,
  actions = [],
  ...rest
}: DefaultIndicatorProps) => {
  return (
    <BaseIndicator
      icon={icon}
      title={title ? <Typography variant="h6">{title}</Typography> : title}
      subtitle={subtitle ? <Typography variant="subtitle1">{subtitle}</Typography> : subtitle}
      description={
        description ? (
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        ) : (
          description
        )
      }
      actions={
        actions.length > 0 ? (
          <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', mt: 2 }}>
            {actions?.map(({ label, ...buttonProps }, index) => (
              <Grid key={`indicator-action-item-${index}`} item>
                <Button variant="contained" {...buttonProps}>
                  {label}
                </Button>
              </Grid>
            ))}
          </Grid>
        ) : undefined
      }
      {...rest}
    />
  );
};
