import React from 'react';
import { styled, SpeedDialAction, SpeedDialActionProps, svgIconClasses, SpeedDialProps } from '@mui/material';

const defaultSize = 36;

export type SizedSpeedDialActionProps = SpeedDialActionProps & {
  size?: number;
  active?: boolean;
  direction?: SpeedDialProps['direction'];
};

export const SizedSpeedDialAction = styled(
  ({ size, active, direction, ...rest }: SizedSpeedDialActionProps) => <SpeedDialAction {...rest} />,
  {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'active' && prop !== 'direction',
  },
)(({ theme, size = defaultSize, active = false, direction }) => {
  return {
    marginTop: direction === 'up' || direction === 'down' ? 6 : 0,
    marginBottom: direction === 'up' || direction === 'down' ? 6 : 0,
    marginLeft: direction === 'left' || direction === 'right' ? 4 : 0,
    marginRight: direction === 'left' || direction === 'right' ? 4 : 0,
    backgroundColor: 'transparent',
    color: active ? theme.palette.primary.light : theme.palette.grey[500],
    '&:hover': {
      backgroundColor: active ? `${theme.palette.primary.light}40` : `${theme.palette.grey[200]}`,
    },
    [`& .${svgIconClasses.root}`]: {
      fontSize: (size * 2) / 3,
    },
  };
});
