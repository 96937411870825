import { Hrx } from 'store/states/hrx';

const getHrxDetails = (data: any = {}) => {
  const {
    browser_version = -1,
    browser = -1,
    intent = '',
    offline = true,
    preferred_renderer = 0,
    target = 'fallback',
    version = -1,
  } = data;

  const result: Hrx = {
    ...data,
    browser_version,
    browser,
    intent,
    offline,
    preferred_renderer,
    target,
    version,
  };

  return result;
};

export const fetchHRX = async () => {
  const hrxPayload = {
    has_ua: Boolean((navigator as any)?.userAgent),
    has_WebGPU: Boolean((navigator as any)?.gpu),
    has_hints: Boolean((navigator as any)?.userAgentData),
    can_screen: Boolean(window?.screen),
    max_tp: Boolean((navigator as any)?.maxTouchPoints) ? (navigator as any).maxTouchPoints : 0,
    ua: Boolean((navigator as any)?.userAgent) ? (navigator as any).userAgent : 'hrx',
    low_mobile: Boolean((navigator as any)?.userAgentData?.mobile) ? (navigator as any).userAgentData.mobile : 'hrx',
    low_brands: Boolean((navigator as any)?.userAgentData?.brands)
      ? (navigator as any).userAgentData.brands
      : { hrx: 'hrx' },
    low_platform: Boolean((navigator as any)?.userAgentData?.platform)
      ? (navigator as any).userAgentData.platform
      : 'hrx',
    height: Boolean(window?.screen) ? window.screen.height : 0,
    width: Boolean(window?.screen) ? window.screen.width : 0,
    avail_height: Boolean(window?.screen) ? window.screen.availHeight : 0,
    avail_width: Boolean(window?.screen) ? window.screen.availWidth : 0,
    dpr: Boolean(window?.devicePixelRatio) ? window.devicePixelRatio : 1,
    href: window.location.href,
  };

  try {
    const url = `https://74urwkijvd.execute-api.us-east-1.amazonaws.com/dev/HRXlite`;

    const response = await fetch(url, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(hrxPayload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Push Call Cannot Be Made`);
    }

    const data = await response.json();

    const result: Hrx = getHrxDetails(data);

    return result;
  } catch (error) {
    console.error('fetchHRX error', error);

    const result: Hrx = {
      browser_version: -1,
      browser: -1,
      intent: 'cant',
      offline: true,
      preferred_renderer: 0,
      target: 'fallback',
      version: -1,
    };

    return result;
  }
};
