import { createContext, useContext, useState } from 'react';
import { ContainerProps } from '@mui/material';

import { AppLayout } from './AppLayout';
import { useGenerateBottomControlsPanel } from './useGenerateBottomControlsPanel';
import { useGenerateHeaderPanel } from './useGenerateHeaderPanel';
import { useGenerateLeftControlsPanel } from './useGenerateLeftControlsPanel';
import { useGenerateRightPanel } from './useGenerateRightPanel';
import { useGenerateContainerProps } from './useGenerateContainerProps';
import { useGenerateTopBorder } from './useGenerateTopBorder';

export type PanelProps = {
  contentComponent?: any;
  contentComponentProps?: any;
};

type AppLayoutContextType = {
  visibleTopBorder: boolean;
  visibleHeaderPanel: boolean;
  visibleRightPanel: boolean;
  visibleLeftControlsPanel: boolean;
  visibleBottomControlsPanel: boolean;
  containerProps: ContainerProps;
  headerPanelProps: PanelProps;
  rightPanelProps: PanelProps;
  leftControlsPanelProps: PanelProps;
  bottomControlsPanelProps: PanelProps;
  updateAppLayout: (props: Partial<Omit<AppLayoutContextType, 'updateViewerLayout'>>) => void;
};

const defaultAppLayoutContextValue = {
  visibleTopBorder: false,
  visibleHeaderPanel: false,
  visibleRightPanel: false,
  visibleLeftControlsPanel: false,
  visibleBottomControlsPanel: false,
  containerProps: {},
  headerPanelProps: {},
  rightPanelProps: {},
  leftControlsPanelProps: {},
  bottomControlsPanelProps: {},
  updateAppLayout: () => {},
};

export const AppLayoutContext = createContext<AppLayoutContextType>(defaultAppLayoutContextValue);

type AppLayoutContextProviderProps = {
  children?: any;
};

export const AppLayoutContextProvider = ({ children = null }: AppLayoutContextProviderProps) => {
  const [contextValue, setContextValue] = useState<AppLayoutContextType>(defaultAppLayoutContextValue);

  const updateAppLayout = (props: Partial<Omit<AppLayoutContextType, 'updateAppLayout'>>) =>
    setContextValue((prev) => ({ ...prev, ...props }));

  const { visibleTopBorder = false } = useGenerateTopBorder();
  const { containerProps } = useGenerateContainerProps();
  const { visibleHeaderPanel = false, headerPanelProps = {} } = useGenerateHeaderPanel();
  const { visibleRightPanel = false, rightPanelProps = {} } = useGenerateRightPanel();
  const { visibleLeftControlsPanel = false, leftControlsPanelProps = {} } = useGenerateLeftControlsPanel();
  const { visibleBottomControlsPanel = false, bottomControlsPanelProps = {} } = useGenerateBottomControlsPanel();

  return (
    <AppLayoutContext.Provider
      value={{
        ...contextValue,
        visibleTopBorder,
        visibleHeaderPanel,
        visibleRightPanel,
        visibleLeftControlsPanel,
        visibleBottomControlsPanel,
        containerProps,
        headerPanelProps,
        rightPanelProps,
        leftControlsPanelProps,
        bottomControlsPanelProps,
        updateAppLayout,
      }}
    >
      <AppLayout>{children}</AppLayout>
    </AppLayoutContext.Provider>
  );
};

export const useAppLayoutContext = () => {
  return useContext(AppLayoutContext);
};
