import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Image from 'mui-image';

import { useBreakpoints } from 'hooks/useBreakpoints';

type HelpItemProps = {
  icon: string;
  text: string;
};

export const HelpItem = ({ icon, text }: HelpItemProps) => {
  const theme = useTheme();
  const { downSm } = useBreakpoints();

  return (
    <Box component="div" sx={{ p: 2 }}>
      <Grid container spacing={downSm ? 1 : 2} sx={{ alignItems: 'flex-start' }}>
        <Grid item>
          <Image src={icon} style={{ width: downSm ? 32 : 40 }} />
        </Grid>

        <Grid item sx={{ flex: 1 }}>
          <Typography variant={downSm ? 'body2' : 'body1'} sx={{ maxWidth: 120, color: theme.palette.common.white }}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
