import React from 'react';
import { Color } from 'three';
import { Bounds, ContactShadows } from '@react-three/drei';

import { useStore } from 'store';
import { AfterDT } from 'variant-viewer/after-dt';
import { /* Background, */ Environment } from 'variant-viewer/environment';
import {
  DTManagerAnimation,
  DTManagerPackedGLB,
  DTManagerStatic,
} from 'variant-viewer/dt-manager';
import { Ruler } from 'variant-viewer/rulers';
import { useScene } from './useScene';
import { useCameraOffset } from "../dt-manager/useCameraOffset";
import PreDT from './PreDT';

export const Scene = () => {
  const { model_loaded, bgcolor, dt_type } = useScene();
  const model = useStore((state) => state.model);
  const sceneControls = useStore((state) => state.scene_controls);

  const color2 = new Color(bgcolor);
  color2.convertLinearToSRGB();

  useCameraOffset()

  return (
    <>
      <Environment />

      <PreDT />

      {<color attach="background" args={[color2]} />}

      {/* TEEHEE either change to Carlton or take out observe */}
      <Bounds
        observe={sceneControls.bounds_should_observe}
        fit
        clip
        margin={1.4}
        maxDuration={0.0001}
      >
        {dt_type === 'packed' && <DTManagerPackedGLB />}

        {dt_type === 'static' && <DTManagerStatic />}

        {dt_type === 'animation' && <DTManagerAnimation />}

        {dt_type !== 'animation' && (
          <group name="levar_fbx_shadow">
            <ContactShadows
              scale={1}
              name="levar_shadow"
              rotation={[Math.PI / 2, 0, 0]}
              position={[0, (model.size.y / 2 + model.size.y / 1000) * -1, 0]}
              opacity={0.05}
              width={model.size.x * 1.1}
              height={model.size.z * 1.1}
              blur={0.2}
              far={30}
              resolution={1028}
              matrixWorldAutoUpdate={false} // add this line
              getObjectsByProperty={() => []}
            />
          </group>
        )}

        {model_loaded && <AfterDT />}

        {model_loaded && <Ruler />}
      </Bounds>
    </>
  );
};
