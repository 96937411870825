import { useLoader } from '@react-three/fiber';
import { TextureLoader, SRGBColorSpace, NearestMipmapLinearFilter, LinearFilter, NoColorSpace } from 'three';

import { AltLoader } from './AltLoader';

type DoubleMatProps = {
  bundle: string;
  materials: any;
  model_url: string;
  store_id: string;
};

export const DoubleMat = ({
  bundle,
  materials,
  model_url,
  store_id
}: DoubleMatProps) => {

  // First Material URL
  // const color0URL = `${model_url}/${store_id}/${bundle}/material_${materials[0]}_color_${color_array[0]}.jpg`;
  // const normal0URL = `${model_url}/${store_id}/${bundle}/material_${materials[0].name}_normal.jpg`;
  // const roughness0URL = `${model_url}/${store_id}/${bundle}/material_${materials[0].name}_rough.jpg`;
  // const metalness0URL = `${model_url}/${store_id}/${bundle}/material_${materials[0].name}_metal.jpg`;
  // const ambientOcclusion0URL = `${model_url}/${store_id}/${bundle}/material_${materials[0].name}_ao.jpg`;
  const color0URL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_color_${materials[0]['channels'].base_color_name}.${materials[0]['channels'].base_color_map === -1 ? 'jpg' : 'png'}`;
  const normal0URL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_normal.${materials[0]['channels'].normal === -1 ? 'jpg' : 'png'}`;
  const roughness0URL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_rough.${materials[0]['channels'].roughness === -1 ? 'jpg' : 'png'}`;
  const metalness0URL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_metal.${materials[0]['channels'].metalness === -1 ? 'jpg' : 'png'}`;
  const ambientOcclusion0URL = `${model_url}/${store_id}/${bundle}/${materials[0].name}_ao.${materials[0]['channels'].ambient_occlusion === -1 ? 'jpg' : 'png'}`;

  // Second Material URL
  // const color1URL = `${model_url}/${store_id}/${bundle}/material_${materials[1]}_color_${color_array[1]}.jpg`;
  // const normal1URL = `${model_url}/${store_id}/${bundle}/material_${materials[1].name}_normal.jpg`;
  // const roughness1URL = `${model_url}/${store_id}/${bundle}/material_${materials[1].name}_rough.jpg`;
  // const metalness1URL = `${model_url}/${store_id}/${bundle}/material_${materials[1].name}_metal.jpg`;
  // const ambientOcclusion1URL = `${model_url}/${store_id}/${bundle}/material_${materials[1].name}_ao.jpg`;
  const color1URL = `${model_url}/${store_id}/${bundle}/${materials[1].name}_color_${materials[1]['channels'].base_color_name}.${materials[1]['channels'].base_color_map === -1 ? 'jpg' : 'png'}`;
  const normal1URL = `${model_url}/${store_id}/${bundle}/${materials[1].name}_normal.${materials[1]['channels'].normal === -1 ? 'jpg' : 'png'}`;
  const roughness1URL = `${model_url}/${store_id}/${bundle}/${materials[1].name}_rough.${materials[1]['channels'].roughness === -1 ? 'jpg' : 'png'}`;
  const metalness1URL = `${model_url}/${store_id}/${bundle}/${materials[1].name}_metal.${materials[1]['channels'].metalness === -1 ? 'jpg' : 'png'}`;
  const ambientOcclusion1URL = `${model_url}/${store_id}/${bundle}/${materials[1].name}_ao.${materials[1]['channels'].ambient_occlusion === -1 ? 'jpg' : 'png'}`;

  const [
    color_map0,
    normal_map0,
    roughness_map0,
    metalness_map0,
    ambientOcclusion_map0,
    color_map1,
    normal_map1,
    roughness_map1,
    metalness_map1,
    ambientOcclusion_map1,
  ] = useLoader(TextureLoader, [
    color0URL,
    normal0URL,
    roughness0URL,
    metalness0URL,
    ambientOcclusion0URL,
    color1URL,
    normal1URL,
    roughness1URL,
    metalness1URL,
    ambientOcclusion1URL,
  ]);

  let emissive_map0, opacity_map0, emissive_map1, opacity_map1;

  if (materials[0]['channels'].emissive_map < 0) {
    emissive_map0 = AltLoader({ model_url, store_id, bundle, materials, slot: 0, type: 'emiss' });
  }

  if (materials[0]['channels'].opacity < 0) {
    opacity_map0 = AltLoader({ model_url, store_id, bundle, materials, slot: 0, type: 'opac' });
  }

  if (materials[1]['channels'].emissive_map < 0) {
    emissive_map1 = AltLoader({ model_url, store_id, bundle, materials, slot: 1, type: 'emiss' });
  }

  if (materials[1]['channels'].opacity < 0) {
    opacity_map1 = AltLoader({ model_url, store_id, bundle, materials, slot: 1, type: 'opac' });
  }

  // colorscience
  color_map0.flipY = false;
  color_map0.colorSpace = SRGBColorSpace;
  color_map0.anisotropy = 16;
  color_map0.minFilter = NearestMipmapLinearFilter;
  color_map0.magFilter = LinearFilter;
  normal_map0.colorSpace = NoColorSpace;
  normal_map0.anisotropy = 16;
  normal_map0.minFilter = NearestMipmapLinearFilter;
  normal_map0.magFilter = LinearFilter;
  normal_map0.flipY = false;
  roughness_map0.colorSpace = NoColorSpace;
  roughness_map0.flipY = false;
  metalness_map0.colorSpace = NoColorSpace;
  metalness_map0.flipY = false;
  ambientOcclusion_map0.colorSpace = NoColorSpace;
  ambientOcclusion_map0.flipY = false;

  color_map1.flipY = false;
  color_map1.colorSpace = SRGBColorSpace;
  color_map1.anisotropy = 16;
  color_map1.minFilter = NearestMipmapLinearFilter;
  color_map1.magFilter = LinearFilter;
  normal_map1.colorSpace = NoColorSpace;
  normal_map1.anisotropy = 16;
  normal_map1.minFilter = NearestMipmapLinearFilter;
  normal_map1.magFilter = LinearFilter;
  normal_map1.flipY = false;
  roughness_map1.colorSpace = NoColorSpace;
  roughness_map1.flipY = false;
  metalness_map1.colorSpace = NoColorSpace;
  metalness_map1.flipY = false;
  ambientOcclusion_map1.colorSpace = NoColorSpace;
  ambientOcclusion_map1.flipY = false;

  const Maps = {
    color0: color_map0,
    normal0: normal_map0,
    roughness0: roughness_map0,
    metalness0: metalness_map0,
    ao0: ambientOcclusion_map0,
    emissive0: emissive_map0,
    opacity0: opacity_map0,
    color1: color_map1,
    normal1: normal_map1,
    roughness1: roughness_map1,
    metalness1: metalness_map1,
    ao1: ambientOcclusion_map1,
    emissive1: emissive_map1,
    opacity1: opacity_map1,
  };

  return Maps;
};
