import React from 'react';
import { Grid, Typography } from '@mui/material';

export type BillboardFooterProps = {
  title?: string;
  description?: string;
};

export const BillboardFooter = ({ title = '', description = '' }: BillboardFooterProps) => {
  if (!title && !description) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
      )}

      {description && (
        <Grid item xs={12}>
          <Typography>{description}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
