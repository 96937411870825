import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogProps, DialogTitle, Grid, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import tipsIcon from 'assets/icons/tipsIcon.png';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useStore } from 'store';
import { buildRedirectURL } from 'utils/helpers';

type RedirectPromptDialogProps = Omit<DialogProps, 'open' | 'onClose'> & {
  open?: boolean;
  onClose?: () => void;
  model_3d_id?: string;
  ghost_variant_id?: string;
  variant_id?: string;
  variant_type?: string;
  fullscreen?: boolean;
  hank_modal?: string;
  model_type?: string;
  ar_activation?: boolean;
  billboards?: boolean;
};

export const RedirectPromptDialog = ({
  open = false,
  onClose = () => { },
  model_3d_id = '',
  ghost_variant_id = '',
  variant_id = '',
  variant_type = '',
  fullscreen = false,
  hank_modal = '',
  model_type = '',
  ar_activation = false,
  billboards = false,
  ...rest
}: RedirectPromptDialogProps) => {
  const { downSm } = useBreakpoints();
  const [clicked, setClicked] = useState<boolean>(false);
  // Params
  const ecommerce_session_id = useStore((state) => state.params.ecommerce_session_id);
  const levar_session_id = useStore((state) => state.params.levar_session_id);
  const levar_pixel_id = useStore((state) => state.params.levar_pixel_id);

  const generateLink = () => {

    const redirectPayload = {
      ecommerce_session_id,
      levar_session_id,
      levar_pixel_id,
      variant_type,
      variant_id,
      model_3d_id,
      ghost_variant_id,
      fullscreen,
      billboards,
      hank_modal,
      model_type,
      ar_activation
    };

    const redirectUrl = buildRedirectURL(redirectPayload);

    return redirectUrl;
  };

  return (
    <div>
      <Dialog
        {...rest}
        aria-labelledby="web-view-code"
        aria-describedby="web-view-modal"
        open={open}
        onClose={() => onClose()}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
      >
        <DialogTitle
          component="h6"
          sx={{ fontSize: 16, textAlign: 'center', color: (theme: any) => theme.palette.text.secondary }}
        >
          View in Augmented Reality
        </DialogTitle>

        <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <Grid container spacing={downSm ? 2 : 3} sx={downSm ? { textAlign: 'center' } : {}}>
            <Grid item {...(downSm ? { xs: 12 } : {})}>
              <img src={tipsIcon} alt="Double Tap Device" />
            </Grid>

            <Grid item sx={downSm ? {} : { flex: 1 }}>
              <Typography variant="h5">No AR Browser Support</Typography>

              <Typography sx={{ mt: 1 }}>
                Please copy this link into Safari or Chrome to continue to your curated experience.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogTitle sx={{ textAlign: 'center' }}>
          <CopyToClipboard text={generateLink()}>
            <Button fullWidth variant="contained" onClick={() => setClicked(!clicked)}>
              {!clicked ? 'Copy Link' : 'Copied!'}
            </Button>
          </CopyToClipboard>
        </DialogTitle>
      </Dialog>
    </div>
  );
};
