import { useStore } from 'store';
import { SwitchSpace } from 'components/switch-space';
import { useCheckFullScreen } from '../app-layout';
import { DrawerProps } from './Drawer';

export const useGenerateTopDrawer = (): {
  visibleTopDrawer?: boolean;
  topDrawerProps?: DrawerProps;
} => {
  const fullScreen = useCheckFullScreen();
  // const origin = useStore((state) => state.params.origin);
  const viewer_type = useStore((state) => state.params.viewer_type);
  // const state = useStore((state) => state);

  /**
   * TODO: Confirm to remove
   */
  // const view_in_space_off = useStore((state) => state.params.view_in_space_off);
  // console.log('view_in_space_off', view_in_space_off)
  // This is how we don't display certain elements based on params
  if (!fullScreen) {
    return {};
  }

  // console.log('state', state)

  // Check if imbedded viewer on PDP 
  const imbedded_pdp_viewer = viewer_type === 'imbedded' ? true : false
  // const imbedded_pdp_viewer = (origin === 'shopify' || origin === 'bigc') ? true : false

  const visibleTopDrawer = true;
  const topDrawerProps: DrawerProps = {
    containerProps: {
      sx: !imbedded_pdp_viewer ?
        { display: 'flex', justifyContent: 'flex-end', pt: 2, pr: 2 } :
        { display: 'flex', justifyContent: 'center', pt: 2, pr: 2, pl: 2 }
    },
    contentComponent: SwitchSpace,
  };

  return { visibleTopDrawer, topDrawerProps };
};
