import React from 'react';
import { Box, Fade } from '@mui/material';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { useViewerLayoutContext } from './ViewerLayoutContext';

type HelpContainerProps = {
  children?: any;
};

export const HelpContainer = ({ children = null }: HelpContainerProps) => {
  const { visibleHelp } = useViewerLayoutContext();
  const { downSm } = useBreakpoints();

  return (
    <Fade in={visibleHelp}>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          left: '50%',
          bottom: downSm ? 0 : 60,
          transform: 'translate(-50%, 0%)',
          width: downSm ? '100%' : '80%',
          maxWidth: downSm ? 'unset' : 640,
          zIndex: (theme: any) => theme.zIndex.drawer + 20,
        }}
      >
        {children}
      </Box>
    </Fade>
  );
};
