import React from 'react';
import { Button, Grid } from '@mui/material';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { useClickAddToCart } from 'hooks/useClickAddToCart';
import { useStore } from 'store';
import { BillboardHeader, BillboardHeaderProps } from './BillboardHeader';
import { BillboardThumbnail, BillboardThumbnailProps } from './BillboardThumbnail';
import { BillboardFooter, BillboardFooterProps } from './BillboardFooter';
import { BillboardPagination, BillboardPaginationProps } from './BillboardPagination';

export type BillboardProps = Omit<BillboardHeaderProps, 'title'> &
  Omit<BillboardFooterProps, 'title'> &
  BillboardThumbnailProps &
  BillboardPaginationProps & {
    headerTitle?: BillboardHeaderProps['title'];
    footerTitle?: BillboardFooterProps['title'];
    visibleAddToCartButton?: boolean;
  };

export const Billboard = ({
  headerTitle,
  price,
  thumbnail,
  footerTitle,
  description,
  onPrev,
  onRefresh,
  onNext,
  current,
  total = 0,
  visibleAddToCartButton = false,
}: BillboardProps) => {
  const { downSm, downMd } = useBreakpoints();
  const { handleClickAddToCart } = useClickAddToCart();
  const right_header = useStore((state) => state.params.right_header);
  const billboards_enabled = useStore((state) => state.scene_controls.billboards_enabled);

  return (
    <Grid container spacing={2} sx={{ textAlign: downMd ? 'center' : 'left' }}>
      {right_header && (headerTitle || price) && (
        <Grid item xs={12}>
          <BillboardHeader title={headerTitle} price={price} />
        </Grid>
      )}

      {billboards_enabled && (
        <>
          {thumbnail && (
            <Grid item xs={12}>
              <BillboardThumbnail thumbnail={thumbnail} />
            </Grid>
          )}

          {(footerTitle || description) && (
            <Grid item xs={12}>
              <BillboardFooter title={footerTitle} description={description} />
            </Grid>
          )}

          {total > 0 && (
            <Grid item xs={12}>
              <BillboardPagination
                onPrev={onPrev}
                onRefresh={onRefresh}
                onNext={onNext}
                current={current}
                total={total}
              />
            </Grid>
          )}
        </>
      )}

      {visibleAddToCartButton && (
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            sx={{ maxWidth: downSm ? 'undefined' : 320 }}
            onClick={() => handleClickAddToCart()}
          >
            Add to Cart
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
