import { createContext, useCallback, useEffect, useState } from 'react';

import { fetchHRX } from 'apis';
import { useStore } from 'store';
import { Hrx } from 'store/states/hrx';

export const HrxContext = createContext(null);

type HrxContextProviderProps = {
  children?: any;
};

export const HrxContextProvider = ({ children = null }: HrxContextProviderProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  const initHrx = useCallback(async () => {
    useStore.setState({ loading: { status: 'hrx', text: 'Loading HRX Info...' } });
    setLoading(true);

    const hrx: Hrx = await fetchHRX();

    useStore.setState({ hrx });
    setLoading(false);
  }, []);

  useEffect(() => {
    initHrx();
  }, [initHrx]);

  return <HrxContext.Provider value={null}>{loading ? null : children}</HrxContext.Provider>;
};
