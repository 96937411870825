import { createContext, useCallback, useEffect, useState } from 'react';
import WebFont from 'webfontloader';

import { fetchStoreSettings } from 'apis';
import { useThemeContext } from 'contexts';
import {
  buildOptions,
  default_levar_text_settings,
} from 'utils/helpers';
import { useStore, useCheckCanAr, useMItem } from 'store';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { baseTheme } from './theme/base-theme';
import { sendAnalyticsData } from 'utils/handleAnalytics';

export const StoreSettingsContext = createContext(null);

type StoreSettingsContextProviderProps = {
  children?: any;
};

export const StoreSettingsContextProvider = ({ children = null }: StoreSettingsContextProviderProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const variant_type = useStore((state) => state.params.variant_type);
  const billboards_param = useStore((state) => state.params.billboards);
  const qr_mobile_launch = useStore((state) => state.params.qr_mobile_launch); // determined if viewer is launched from modal QR
  const auto_ar_launch = useStore((state) => state.params.auto_ar_launch); // AR auto launch
  const param_type = useStore((state) => state.params.param_type); // first part of url ?=
  const store_id = useStore((state) => state.store_id);
  const variant_id = useStore((state) => state.variant_id);
  const intent = useStore((state) => state.hrx.intent); // check for willSV

  const canAr = useCheckCanAr();

  // MItem Hook
  const {
    geo_type = '',
    billboard_list = [],
    orbit_controls_angles = [],
    variant_ruler_settings = {},
    variant_ruler_settings: { rulers_enabled = false } = {},
    viewer_variant_switch = false // use to decide if need to build options list
  } = useMItem() || {};

  const vList = useStore((state) => state.vList) || {};

  const { updateTheme } = useThemeContext();

  const { store_domain, ...restAnalyticsDataPageLoad } = useAnalyticsData({
    event_type: 'viewer_page_load',
  });

  const analyticsQrModalLaunch = useAnalyticsData({
    event_type: 'qr_mobile_launch',
  });

  const initStoreSettings = useCallback(async () => {
    useStore.setState({
      loading: {
        status: 'store-settings',
        text: (canAr && auto_ar_launch && intent !== 'willSV') ? 'Launching AR...' : 'Loading Store Settings...'
      }
    });
    setLoading(true);

    const initial_store_settings: any = await fetchStoreSettings(variant_type, store_id);
    // console.log('initial_store_settings', initial_store_settings)

    const { store_domain, ar_experience, dynamic_text_settings, store_ruler_settings } = initial_store_settings;
    // const { ar_experience, dynamic_text_settings, ruler_settings, store_ruler_settings } = initial_store_settings;
    // console.log('store_ruler_settings', store_ruler_settings)

    const {
      custom_text_status = false,
      default_text_font = '',
      viewer_button: { button_color = '', button_text_color = '' } = {},
    } = dynamic_text_settings || {};
    const { ar_cta_status = false } = ar_experience || {};

    if (custom_text_status && default_text_font) {
      WebFont.load({
        google: {
          api: 'https://fonts.googleapis.com/css2',
          families: [`${default_text_font}:wght@100;200;300;400;500;600;700;800;900&display=swap`],
        },
      });
    }

    let customTheme = { ...baseTheme };

    if (button_color) {
      (customTheme.palette as any).primary.main = button_color;
    }

    if (button_text_color) {
      (customTheme.palette as any).primary.contrastText = button_text_color;
    }

    if (default_text_font) {
      (customTheme.typography as any).fontFamily = [default_text_font].join(',');
    }

    if (button_color || default_text_font) {
      updateTheme(customTheme);
    }

    const store_settings = {
      ...initial_store_settings,
      dynamic_text_settings: custom_text_status ? dynamic_text_settings : { ...default_levar_text_settings },
      custom_banner_url: ar_cta_status ? `&custom=https://actar.levar.io/index.html/?${param_type}=${variant_id}&customHeight=small` : '',
    };

    /**
     * When Building Options vList need to filter our non stage 5 asset status true
     * Also Don't run unless Mitem has viewer_variant_switch to true
     */
    const stage_5_true_vList = !viewer_variant_switch ? {} : Object.keys(vList).reduce((acc, key) => {
      if ((vList[key].hasOwnProperty('model_3d_id') || vList[key].hasOwnProperty('interactive_viewer_0')) && vList[key].hasOwnProperty('options')) {
        return Object.assign(acc, {
          [key]: vList[key]
        });
      }
      return acc;
    }, {});

    // console.log('************************', stage_5_true_vList)

    const {
      options: model_options,
      subOptions: model_sub_options,
      values: model_option_values
    } = buildOptions(stage_5_true_vList, viewer_variant_switch);

    // model billboard list needs to be greater than 1 && billboards_param === on
    // console.log('billboard_list::', billboard_list)
    // console.log('billboards_param::', billboards_param)
    const billboards_enabled = billboard_list && billboard_list.length > 1 && billboards_param;
    const billboards_drawer_enabled = billboards_enabled && !billboard_list.some(billboard => billboard.board_attributes?.type === 'label');
    // console.log("billboards_drawer_enabled:::", billboards_drawer_enabled)

    const scene_controls = {
      active_board: billboards_enabled ? 0 : undefined,
      billboards_enabled, // if model has billboard_list and billboards_param === on
      billboards_drawer_enabled, // new field 2024 (if board_attributes?.type === 'label' then drawer is false)
      controls_ui: geo_type === 0,
      orbit_controls_enabled: true,
      rulers_enabled: rulers_enabled, // based off variant level ruler bool
      // (PROBLEM ruler_settings only from store) Ruler data Store and Variant (spread operate ...auto_status_text_override)
      rulers_data_sv: rulers_enabled ? { store: store_ruler_settings, variant: variant_ruler_settings } : undefined,
      // rulers_data_sv: rulers_enabled ? { store: store_ruler_settings, variant: { ...ruler_settings, auto_status_text_override } } : undefined,
      active_board_changed: false,
      orbit_controls_angles,

      // Enable bounds resize-clipping when billboards aren't enabled
      bounds_should_observe: !billboards_enabled
    };

    useStore.setState({
      store_settings,
      model_options,
      model_sub_options,
      model_option_values,
      scene_controls
    });
    setLoading(false);

    /**
     * Analytics: Send viewer_page_load
     */
    await sendAnalyticsData({ store_domain, ...restAnalyticsDataPageLoad });
    // await sendAnalyticsData(analyticsModelLoaded)

    /**
     * NOTE: If qr_mobile_launch parameter exists in query params then launched from QR Modal Desktop
     */
    if (qr_mobile_launch) {
      await sendAnalyticsData(analyticsQrModalLaunch);
    }

    // eslint-disable-next-line
  }, [store_id, variant_type, geo_type, origin]);

  useEffect(() => {
    initStoreSettings();
  }, [initStoreSettings]);

  return <StoreSettingsContext.Provider value={null}>{loading ? null : children}</StoreSettingsContext.Provider>;
};
