import React from 'react';
import { styled, SpeedDial, SpeedDialProps, fabClasses, speedDialIconClasses, svgIconClasses } from '@mui/material';

const defaultSize = 36;

export type SizedSpeedDialProps = SpeedDialProps & {
  size?: number;
};

export const SizedSpeedDial = styled(({ size, ...rest }: SizedSpeedDialProps) => <SpeedDial {...rest} />, {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size = defaultSize }) => ({
  [`& .${fabClasses.root}`]: {
    width: size,
    height: size,
    minHeight: size,
  },
  [`& .${speedDialIconClasses.root}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${svgIconClasses.root}`]: {
    fontSize: (size * 2) / 3,
  },
}));
