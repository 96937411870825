import { useEffect, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { AnimationClip, AnimationMixer, Object3D } from 'three';

type AnimationLoaderProps = {
  nodes: Object3D;
  animations: AnimationClip[];
};

export const AnimationLoader = ({ nodes, animations }: AnimationLoaderProps) => {
  const [mixer] = useState(() => new AnimationMixer(null as any) as any);

  useEffect(() => void mixer.clipAction(animations[0], nodes.children[0]).play(), [nodes, animations, mixer]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });
};
