import { useFrame } from '@react-three/fiber';
import { Object3D } from 'three';

type HoverProps = {
  clipless: any;
  deliveryTarget: Object3D;
};

export const Hover = ({ clipless, deliveryTarget }: HoverProps) => {
  const MoveUp = () => {
    clipless.iterator--;
    deliveryTarget.position.y += clipless.distance;
  };

  const MoveDown = () => {
    clipless.iterator--;
    deliveryTarget.position.y -= clipless.distance;
  };

  const ResetUp = () => {
    clipless.iterator = 60 / clipless.speed;
    clipless.up = true;
  };

  const ResetDown = () => {
    clipless.iterator = 60 / clipless.speed;
    clipless.up = false;
  };

  useFrame(() => {
    clipless.up ? (clipless.iterator ? MoveUp() : ResetDown()) : clipless.iterator ? MoveDown() : ResetUp();
  });

  return null;
};

/**
 * every frame the iterator isn't zero the deliveryTarget moves by amount clipless.distance in the up or down direction
 * when the iterator reaches zero it reset and the direction switches
 */
