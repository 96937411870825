import { levar_api_2023 } from 'configs';
import { Variants } from 'store/states/variants';

const variantsApi = (ecommerce_id: string, model_3d_id: string, variant_type: string) => {
  switch (variant_type) {
    case 'ghost': // ghost_variants
      return `${levar_api_2023}/ghost/?ghost_variant_id=${model_3d_id}`;
    case 'model': // models_3d
      return `${levar_api_2023}/public/model/viewer?model_3d_id=${model_3d_id}`;
    default: // levar_ecommerce_variants
      return `${levar_api_2023}/public/ecommerce/viewer/v6/?ecommerce_variant_id=${ecommerce_id}`;
  }
};

// used for old variants pls deprecate
const variantsApiDeprecate = async (variant_id_deprecate: string, variant_type: string) => {

  // custom variant ids are the same dont need to api call
  if (variant_type === 'cus') {
    return variant_id_deprecate;
  }
  // console.log('variant_type', variant_type)

  const url = `${levar_api_2023}/public/variant_id/swap?legacy_variant_id=${variant_type !== 'bco' ? variant_id_deprecate : variant_id_deprecate.slice(4)}`;

  const response = await fetch(url);

  if (!response.ok) throw new Error(`Variants Deprecated API cannot be called`);

  const { ecommerce_variant_id: _new_ecommerce_variant_id } = await response.json();

  return _new_ecommerce_variant_id
};

export const fetchVariants = async (
  variant_id_deprecate: string,
  ecommerce_id: string,
  model_3d_id: string,
  variant_type: string
) => {

  try {
    // used for old variants pls deprecate
    if (variant_id_deprecate) {
      ecommerce_id = await variantsApiDeprecate(variant_id_deprecate, variant_type)
    }

    const url = variantsApi(ecommerce_id, model_3d_id, variant_type);

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Fetch Call Cannot Be Made`);
    }

    const data = await response.json();

    let result;

    if (variant_type === 'ghost') {
      result = getGhostDetails(data, model_3d_id);
    } else if (variant_type === 'model') {
      result = getModelDetails(data, model_3d_id);
    } else {
      result = getEcommDetails(data, ecommerce_id);
    }

    return result;
  } catch (error) {
    console.error('fetchVariants error', error);

    const result: Variants = {
      statusCode: -1,
      store_id: '',
      vList: undefined,
      mList: undefined,
      model_3d_id: '',
      ecommerce_id: '' // used for old variants pls deprecate
    };

    return result;
  }
};

// GHOST (ghost_variants)
const getGhostDetails = (data: any = {}, model_3d_id: string) => {
  const {
    statusCode = -1,
    ghost_search_response_hits_source = '',
  } = data;

  const result: Variants = {
    statusCode,
    store_id: ghost_search_response_hits_source[model_3d_id].store_id,
    mList: ghost_search_response_hits_source,
    vList: {},
    model_3d_id,
    ecommerce_id: '' // used for old variants pls deprecate
  };

  return result;
};

// MODEL (models_3d)
const getModelDetails = (data: any = {}, model_3d_id: string) => {
  const {
    statusCode = -1,
    model_search_response_hits_source = '',
  } = data;

  const result: Variants = {
    statusCode,
    store_id: model_search_response_hits_source[model_3d_id].store_id,
    mList: model_search_response_hits_source,
    vList: {},
    model_3d_id,
    ecommerce_id: '' // used for old variants pls deprecate
  };

  return result;
};

// ECOMMERCE_ID (levar_ecommerce_variants)
const getEcommDetails = (data: any = {}, ecommerce_id: string) => {
  const {
    statusCode = -1,
    ecommerce_search_response_hits_source = '',
    model_search_response_hits_source = '',
  } = data;

  const vList = ecommerce_search_response_hits_source;

  const mList = model_search_response_hits_source;

  const model_3d_id = ecommerce_search_response_hits_source[ecommerce_id].model_3d_id || ecommerce_search_response_hits_source[ecommerce_id].interactive_viewer_0;
  // console.log('model_3d_id:::', model_3d_id)
  // const model_3d_id = ecommerce_search_response_hits_source[ecommerce_id].model_3d_id;
  // const store_id = ecommerce_search_response_hits_source[ecommerce_id].store_id;
  const store_id = model_search_response_hits_source[model_3d_id]?.store_id;

  // store_id: model_search_response_hits_source[model_3d_id]?.store_id,
  // const store_id = ecommerce_search_response_hits_source[ecommerce_id].store_id;
  // const mList = model_search_response_hits_source;
  // const model_3d_id = ecommerce_search_response_hits_source[ecommerce_id].static_viewer_0;

  const result: Variants = {
    statusCode,
    store_id,
    vList,
    mList,
    model_3d_id,
    ecommerce_id // used for old variants pls deprecated
  };

  return result;
};
