import { Vector3 } from 'three';

export type BillboardSettings = {
  billboard_listener_click: boolean;
  camera_density: number; // new // could change to variable (in)
  camera_move_positions: Vector3[]; // new
  autorotation_speed: number; // used for camera autorotation angle per frame
  clipAnchors: Vector3[]; // new
  current_camera_keyframe: number; // new
  is_camera_keyframe: boolean; // new // is camera moving
  lattice_graph: (string | number)[][];
  lattice: Vector3[]; // new
  orbit_controls_target: Vector3; // new
  target_positions: Vector3[]; // new
  current_locator_target: Vector3;
};

export const billboard_settings: BillboardSettings = {
  billboard_listener_click: false,
  camera_density: 120,
  camera_move_positions: [],
  autorotation_speed: 0.01,
  clipAnchors: [],
  current_camera_keyframe: 0,
  is_camera_keyframe: false,
  lattice_graph: [],
  lattice: [],
  orbit_controls_target: new Vector3(0, 0, 0),
  target_positions: [],
  current_locator_target: new Vector3(0, 0, 0),
};
