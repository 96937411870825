import React, { useState } from 'react';
import { Backdrop, useTheme } from '@mui/material';
import { BrokenImage } from '@mui/icons-material';
import Image from 'mui-image';
import { BounceLoader } from 'react-spinners';

import { SizedButtonBase, SizedButtonBaseProps } from 'components/@styled-mui';

type VariantSliderItemProps = SizedButtonBaseProps & {
  active?: boolean;
  image?: string;
  onItem?: () => void;
};

export const VariantItem = ({
  active = false,
  image = '',
  size = 50,
  onItem = () => { },
  ...rest
}: VariantSliderItemProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = async () => {
    setLoading(true);

    await onItem();

    setLoading(false);
  };

  return (
    <SizedButtonBase
      size={size}
      sx={{
        borderRadius: 1,
        border: `3px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
        position: 'relative',
      }}
      onClick={onClick}
      {...rest}
    >
      {!image && (
        <Image
          src={''}
          duration={1000}
          errorIcon={<BrokenImage sx={{ fontSize: 20, color: theme.palette.grey[300] }} />}
        />

      )}

      {image?.startsWith('#') && (
        <svg viewBox="0 0 100 100" width="100%" height="100%">
          <rect width="100%" height="100%" x="0" y="0" fill={image} />
        </svg>
      )}

      {image?.startsWith('https') && (
        <Image
          src={image}
          duration={1000}
          errorIcon={<BrokenImage sx={{ fontSize: 20, color: theme.palette.grey[300] }} />}
        />
      )}

      <Backdrop open={loading} transitionDuration={{ appear: 0, enter: 0, exit: 800 }} sx={{ position: 'absolute' }}>
        <BounceLoader color={theme.palette.common.white} size={44} />
      </Backdrop>
    </SizedButtonBase>
  );
};
