import { useLoader } from '@react-three/fiber';
import { TextureLoader, SRGBColorSpace, NoColorSpace } from 'three';

type AltLoaderProps = {
  bundle: string;
  materials: any;
  model_url: string;
  slot: number;
  store_id: string;
  type: string;
};

export const AltLoader = ({ model_url, bundle, materials, slot, store_id, type }: AltLoaderProps) => {
  let altURL = '';

  switch (type) {
    case 'emiss':
      altURL = `${model_url}/${store_id}/${bundle}/${materials[slot].name}_emissive.${materials[slot]['channels'].emissive_map === -1 ? 'jpg' : 'png'}`;
      // altURL = `${model_url}/${store_id}/${bundle}/material_${materials[slot]}_emissive.jpg`;
      break;
    case 'opac':
      altURL = `${model_url}/${store_id}/${bundle}/${materials[slot].name}_opacity.${materials[slot]['channels'].opacity === -1 ? 'jpg' : 'png'}`;
      // altURL = `${model_url}/${store_id}/${bundle}/material_${materials[slot]}_opacity.jpg`;
      break;
    default:
      break;
  }

  const [altMap] = useLoader(TextureLoader, [altURL]);

  switch (type) {
    case 'emiss':
      altMap.colorSpace = SRGBColorSpace;
      break;
    case 'opac':
      altMap.colorSpace = NoColorSpace;
      break;
    default:
      break;
  }

  return altMap;
};
