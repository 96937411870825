import { useStore, useMItem } from 'store';

type DTType = 'static' | 'animation' | 'packed' | 'draco';

export const useScene = (): {
  bgcolor: string;
  dt_type: DTType;
  model_loaded: boolean;
} => {
  const { geo_type = 0, asset_background = '' } = useMItem() || {};
  // const { animation_solo = false /* asset_background : bgcolor  = ''*/ } = useVItem() || {};
  const model_loaded = useStore((state) => state.model_loaded);
  const isOffset = useStore((state) => state.isOffset);

  // 0: static gray FBX
  // 1: single animation looping gray GLB (animation solo)
  // 2: static packed GLB
  // 3: static gray GLB

  let dt_type: DTType;

  switch (geo_type) {
    case 1:
      dt_type = 'animation';
      break;
    case 2:
      dt_type = 'packed';
      break;
    case 5:
      dt_type = 'static';
      break;
    default:
      dt_type = 'static';
  }

  let bgcolor;

  // is bottom drawer open
  if (isOffset) {
    // does asset have custom hex code or is any sort of white ffffff FFFFFF
    if (asset_background?.length > 0 && asset_background !== '#ffffff' && asset_background !== '#FFFFFF' && asset_background !== 'white') {
      bgcolor = asset_background;
    } else {
      bgcolor = '#f0f0f0';
    }
  } else {
    bgcolor = asset_background?.length > 0 ? asset_background : '#ffffff';
  }

  return { bgcolor, dt_type, model_loaded };
};
