import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Image } from 'mui-image';

import { useBreakpoints } from 'hooks/useBreakpoints';

export type BillboardThumbnailProps = {
  thumbnail?: string;
};

export const BillboardThumbnail = ({ thumbnail }: BillboardThumbnailProps) => {
  const { downSm, downMd } = useBreakpoints();
  const theme = useTheme();

  if (!thumbnail) {
    return null;
  }

  // console.log('thumbnail', thumbnail)

  return (
    <Box
      component="div"
      sx={{
        maxWidth: downSm ? 'unset' : 320,
        borderRadius: 1,
        overflow: 'hidden',
        margin: downMd ? 'auto' : 0,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Image src={thumbnail} height={200} duration={1000} />
    </Box>
  );
};
