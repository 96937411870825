import { MItem } from './states/variants';
import { useStore } from './useStore';
import { setCustomOrbitAngles } from 'utils/helpers'

const getMItemDetails = (mItem: MItem) => {
  const {
    ruler_settings: _ruler_settings,
    orbit_controls_angles: _orbit_controls_angles,
  } = mItem;

  const ruler_settings = _ruler_settings ?? undefined;
  const orbit_controls_angles = _orbit_controls_angles ? setCustomOrbitAngles(_orbit_controls_angles) : [0, 3.14, Infinity, Infinity]

  return {
    ...mItem,
    ruler_settings,
    variant_ruler_settings: ruler_settings,
    orbit_controls_angles
  };
};

export const useMItem = () => {
  const mItem = useStore((state) => state.mItem);

  if (!mItem) {
    return undefined;
  }

  return getMItemDetails(mItem);
};
