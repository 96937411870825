import { useStore } from 'store';
import { useAppLayoutContext } from './AppLayoutContext';

export const useCheckFullScreen = () => {
  const {
    visibleTopBorder,
    visibleHeaderPanel,
    visibleRightPanel,
    visibleLeftControlsPanel,
    headerPanelProps,
    rightPanelProps,
    leftControlsPanelProps,
    bottomControlsPanelProps,
  } = useAppLayoutContext();
  const fullscreen = useStore((state) => state.params.fullscreen);

  if (fullscreen) {
    return true;
  }

  if (!visibleTopBorder && !visibleHeaderPanel && !visibleRightPanel && !visibleLeftControlsPanel) {
    return true;
  }

  if (
    !headerPanelProps.contentComponent &&
    !rightPanelProps.contentComponent &&
    !leftControlsPanelProps.contentComponent &&
    !bottomControlsPanelProps.contentComponent
  ) {
    return true;
  }

  return false;
};
