// import React from 'react';

import { useStore, useMItem } from 'store';
import { POI } from './POI';
import { useLoader } from '@react-three/fiber';
import { TextureLoader, NoColorSpace } from 'three';

import poiOpacityMap from 'assets/icons/poiOpacityMap.jpg';
import backButtonOpacityMap from 'assets/icons/backButtonAlpha.jpg';

export const Billboards = () => {
  const billboards_enabled = useStore((state) => state.scene_controls.billboards_enabled);
  const lattice_graph = useStore((state) => state.billboard_settings.lattice_graph);
  const { billboard_list = [] } = useMItem() || {};

  const LoadPOITexture = () => {
    const texture = useLoader(TextureLoader, poiOpacityMap);

    return <texture attach="alphaMap" {...texture} colorSpace={NoColorSpace} name="levar_poi_opacity" />;
  };

  const LoadLabelTexture = () => {
    const texture = useLoader(TextureLoader, backButtonOpacityMap);

    return <texture attach="alphaMap" {...texture} colorSpace={NoColorSpace} name="levar_label_opacity" />;
  };

  // Block if billboards aren't ready / enabled
  if (!(billboards_enabled && lattice_graph.length)) {
    return null;
  };

  return (
    <group name="levar_fbx_poi">
      {billboard_list.map((board: any, key: number) => (
        <POI key={key} board={board} point_index={key} POI_OPAC_MAP={LoadPOITexture} LABEL_OPAC_MAP={LoadLabelTexture} />
      ))}
    </group>
  );
};
